<div class="row w-100">
	<div *ngFor="let midia of midias"
		class="col-lg-3 col-md-4 mt-2">
		<card-midia [file]="midia"
			[content_type]="content_type"
			[obj_referencia]="obj_referencia"
            (changeMidia)="onChangeMidia()"
		></card-midia>
	</div>

	<!-- CARD ADICIONAL PARA ADICIONAR NOVA MIDIA -->
	<div class="col-lg-3 col-md-4 mt-2">
		<card-midia [content_type]="content_type"
			[obj_referencia]="obj_referencia"
			(changeMidia)="onChangeMidia()"
		></card-midia>
	</div>
</div>
