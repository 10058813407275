import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutocadastroRoutingModule } from './autocadastro-routing.module';
import { AutocadastroComponent } from './autocadastro.component';
import { AutocadastroService } from 'src/app/shared/api/autocadastro.service';
import { TabelaListagemModule } from '../../shared/components/tabela-listagem/tabela-listagem.module';
import { CardModule } from '../../shared/components/card/card.module';
import { ButtonModule } from '../../shared/components/button/button.module';
import { ModalModule } from '../../shared/components/modal/modal.module';
import { FormularioDinamicoModule } from '../../shared/components/formulario-dinamico/formulario-dinamico.module';
import { TestesClienteService } from 'src/app/shared/api/testes/testes-cliente.service';
import { ContainerPadraoModule } from "../../shared/components/container-padrao/container-padrao.module";

@NgModule({
    declarations: [AutocadastroComponent],
    imports: [
    CommonModule,
    AutocadastroRoutingModule,
    TabelaListagemModule,
    CardModule,
    ButtonModule,
    ModalModule,
    FormularioDinamicoModule,
    ContainerPadraoModule
],
    providers: [AutocadastroService, TestesClienteService],
    exports: [AutocadastroComponent]
})
export class AutocadastroModule {}
