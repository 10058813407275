import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoleEnum } from 'src/app/app-navigation';
import {
    AcessosService,
    IAcesso,
    IAutenticarResponse
} from 'src/app/shared/api/acessos.service';
import { TermosDeUsoService } from 'src/app/shared/api/termos-de-uso.service';
import { dateToString } from 'src/app/shared/functions/data-transform';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationService } from 'src/app/shared/services/notificationService';

@Component({
    selector: 'app-acessos',
    templateUrl: './acessos.component.html',
    styleUrls: ['./acessos.component.css']
})
export class AcessosComponent implements OnInit, OnDestroy {
    private _acessosSubscription: any;
    private _selecionarAcessoSubscription: any;

    showLoaderAcessos = true;
    listaAcessos: IAcesso[] = [];

    constructor(
        private acessoService: AcessosService,
        private notify: NotificationService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.listarAcessosUsuario();
    }

    ngOnDestroy(): void {
        if (this._acessosSubscription) this._acessosSubscription.unsubscribe();
        if (this._selecionarAcessoSubscription)
            this._selecionarAcessoSubscription.unsubscribe();
    }

    private listarAcessosUsuario() {
        this._acessosSubscription = this.acessoService.get().subscribe({
            next: (response) => {
                this.showLoaderAcessos = false;
                this.listaAcessos = response.resultados;
                if(this.listaAcessos.length == 1) this.selecionarCard(this.listaAcessos[0]);
            }
        });
    }

    selecionarCard(acesso: IAcesso) {
        this.showLoaderAcessos = true;
        this._selecionarAcessoSubscription = this.acessoService
            .selecionarAcesso(acesso.uuid)
            .subscribe({
                next: (response) => {
                    this.authService.selecionarAcesso(acesso, response.token);
                },
                error: (e: any) => {
                    this.notify.error(e.error.erro);
                }
            });
    }

    convertData(data_string: string) {
        const data = new Date(data_string);
        return dateToString(data);
    }
}
