import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAlternativaJogada } from 'src/app/shared/api/testes/jogada.service';

@Component({
    selector: 'alternativas-list',
    templateUrl: './alternativas-list.component.html',
    styleUrls: ['./alternativas-list.component.scss']
})
export class AlternativasListComponent implements OnInit {

    @Input() value!: any;
    @Input() alternativas: IAlternativaJogada[] = [];
    @Output() valueChange = new EventEmitter<any>();

    idAlternativaSelecionada: string = '';

    constructor() { }

    ngOnInit(): void {
    }

    selectAlternativa(alternativa: any){
        this.idAlternativaSelecionada = alternativa.uuid;

        this.value = alternativa;
        this.valueChange.emit(this.value);
    }
}
