<div class="container-fluid mt-2 pb-5">

    <toolbar [actions]="actionsToolbar" [titulo]="titulo" [mode]="'dark'"></toolbar>

    <loader-page [show]="loading"></loader-page>

    <ng-container *ngFor="let grupo of grupos; let isLast = last; let i = index">
        <div class="carousel-wrapper">
            <button class="arrow left d-none d-sm-block" (click)="scrollLeft(i)">&#10094;</button>

            <div class="grupo mt-5" #grupoContainer>
                <ng-container *ngFor="let licencaMaterial of grupo.licencasMateriais">
                    <div [ngStyle]="{
                        'min-width': constantsWidthHeightMidia.sm.width + 'px',
                        'max-width': constantsWidthHeightMidia.sm.width + 'px'
                    }">
                        <card-licenca (onIniciarClick)="iniciarJogadaClick($event)"
                            (onRelatorioClick)="visualizarRelatorio($event)"
                            [licenca]="licencaMaterial.licenca"></card-licenca>
                    </div>
                    <div *ngFor="let material of licencaMaterial.materiais" [ngStyle]="{
                        'min-width': constantsWidthHeightMidia.sm.width + 'px',
                        'max-width': constantsWidthHeightMidia.sm.width + 'px'
                    }">
                        <card-material *ngIf="urlCapaMaterial" 
                            [material]="material" 
                            [licenca]="licencaMaterial.licenca"
                            [urlCapa]="urlCapaMaterial"></card-material>
                    </div>
                </ng-container>
                <ng-container *ngIf="grupo.licencasMateriais.length > 1">
                    <div [ngStyle]="{
                        'min-width': constantsWidthHeightMidia.sm.width + 'px',
                        'max-width': constantsWidthHeightMidia.sm.width + 'px'
                    }">
                        <card-comparativo [grupo]="grupo"></card-comparativo>
                    </div>
                </ng-container>
                <br>
            </div>

            <button class="arrow right d-none d-sm-block" (click)="scrollRight(i)">&#10095;</button>
        </div>

    </ng-container>
</div>