import { Injectable } from '@angular/core';
import { IResponse } from '../response.model';
import { ISkin } from '../skins.service';
import { TemplateApiService } from '../template-api.service';

export enum ProximoEnum {
    Pergunta = 'pergunta',
    Rodada = 'rodada',
    Modulo = 'modulo',
    Finalizado = 'finalizado'
}

export interface IPerguntaJogada {
    descricao: string;
    posicao: number;
    duracao: number;
    iniciado: boolean;
    inicio: string;
}

export interface IAlternativaJogada {
    descricao: string;
    uuid: string;
}

export interface IJogada extends IResponse {
    licenca: string;
    skin?: ISkin,
    acompanhamento: {
        progresso: number;
        teste: string;
        proximo: ProximoEnum;
        jogada_atual: {
            modulo: string;
            rodada: string;
            pergunta: string;
        };
        detalhes: {
            teste: {
                descricao: string;
                nome: string;
            };
            rodada: {
                descricao: string;
                nome: string;
                posicao: number;
            };
            modulo: {
                descricao: string;
                nome: string;
                posicao: number;
            };
            feedback?: string;
            pergunta?: IPerguntaJogada;
            alternativas: IAlternativaJogada[];
        };
    };
}

@Injectable()
export class JogadaService extends TemplateApiService<IJogada> {
    public override setBaseUrl() {
        this.baseUrl = `jogada`;
    }

    public getRodadaAtual(idLicenca: string) {
        return this.detail(idLicenca);
    }

    public iniciarJogada(idLicenca: string) {
        return this.create({ licenca: idLicenca });
    }

    public iniciarPerguntas(idJogada: string, idPergunta: string) {
        return this.update(idJogada, { pergunta: idPergunta });
    }

    public responderPergunta(idjogada: string, idAlternativa: string) {
        return this.update(idjogada, { alternativa: idAlternativa });
    }
}
