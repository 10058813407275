import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";


export interface ITeste extends IResponse {
    nome: string,
    descricao: string
}

@Injectable()
export class TestesClienteService extends TemplateApiService<ITeste> {

    public override setBaseUrl(...props: any) {
        this.baseUrl = 'testes';
    }
}
