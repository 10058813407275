import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderPageComponent } from './loader-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
    declarations: [LoaderPageComponent],
    exports: [LoaderPageComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ]
})
export class LoaderPageModule { }
