<edit-component
    [rota]="'clientes'"
    [(entidadeOriginal)]="entidadeOriginal"
    [resource]="clienteResource"
    [service]="clienteService"
    (entidadeOriginalChange)="setarIdEquipe($event)"
></edit-component>

<table-list
    *ngIf="entidadeOriginal && entidadeOriginal[clienteResource.fieldPk]"
    [resource]="equipeResource"
    [service]="adminEquipeService"
    rotaNovo="equipes/novo"
    rotaEdit="equipes/"
></table-list>