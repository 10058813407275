import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RelatoriosComponent } from './relatorios.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { ResultadoComponent } from './resultado/resultado.component';

const routes: Routes = [
    {
        path: 'relatorios',
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: RelatoriosComponent,
            },
            {
                path: ':id',
                component: ResultadoComponent
            }
        ]
        
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatoriosRoutingModule { }
