import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";


export interface IPergunta extends IResponse {
    descricao: string,
    posicao: number,
}

@Injectable()
export class PerguntaService extends TemplateApiService<IPergunta> {

    public override setBaseUrl(idTeste: string, idModulo: string, idRodada: string){
        this.baseUrl = `admin/testes/${idTeste}/modulos/${idModulo}/rodadas/${idRodada}/perguntas`;
    }
}
