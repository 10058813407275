import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '../button/button.module';


@NgModule({
    declarations: [
        DialogComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        ButtonModule
    ],
    exports: [
        DialogComponent
    ]
})
export class DialogModule { }
