import { Injectable } from '@angular/core';
import { IResponse } from '../response.model';
import { TemplateApiService } from '../template-api.service';
import { ITeste } from './testes.service';


export interface ITrilha extends IResponse {
    teste: ITeste
}


@Injectable()
export class TrilhasService extends TemplateApiService<ITrilha> {
    public override setBaseUrl() {
        this.baseUrl = `admin/trilhas`;
    }
}
