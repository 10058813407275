<table-list
    [service]="testesAdmService"
    [resource]="testesResource"
    [actionsIncrement]="actionsTabela"
></table-list>

<modal
    #modal
    [data]="importarCursoComIaModalData"
    [onSave]="onSaveModalImportarCurso"
>
    <input-file-area
        [accept]="'.doc,.docx,.pdf'"
        [multiple]="false"
        [(file)]="arquivoImportar"
    ></input-file-area>
</modal>