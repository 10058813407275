import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IMidia } from 'src/app/shared/api/testes/adm-midias.service';

@Component({
    selector: 'slider-midia',
    templateUrl: './slider-midia.component.html',
    styleUrls: ['./slider-midia.component.css']
})
export class SliderMidiaComponent implements OnInit, OnChanges {

    @Input() midias!: IMidia[];

    midias_listar: {url: string, formato: string}[] = [];

    constructor() { }

    ngOnInit(): void {
    }
 
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['midias']){
            this.configurarMidias();
        }
    }

    configurarMidias(){
        this.midias_listar = this.midias.map((midia: IMidia) => {
            return {url: midia.url!, formato: midia.formato!}
        });
    }
}
