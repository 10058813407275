import { Observable } from "rxjs";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";
import { PaginatedResponse } from "../pagination.model";
import { ILicenca } from "./licencas.service";
import { Injectable } from "@angular/core";


@Injectable()
export class LicencasDisponiveisService extends TemplateApiService<ILicenca> {

    private params:any = {};

    public override setBaseUrl(params: {}) {
        this.baseUrl = `licencas-disponiveis`;
        this.params = params;
    }

    override get(params?: any): Observable<PaginatedResponse<ILicenca>> {
        this.params.pagina = params?.pagina;
        this.params.tamanho = params?.tamanho;
        return this.apiService.get(this.getBaseUrl(), this.params) as Observable<PaginatedResponse<ILicenca>>;
    }

    public alocarLicencaEquipe(equipe: string, quantidade: number, teste: string){
        return this.apiService.post('licencas-equipe', {equipe, quantidade, teste});
    }
}
