import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'btn-continuar',
    templateUrl: './btn-continuar.component.html',
    styleUrls: ['./btn-continuar.component.scss']
})
export class BtnContinuarComponent implements OnInit {

    @Input() texto: string = 'Continuar';


    constructor() { }

    ngOnInit(): void {
    }
}
