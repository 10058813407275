
/**
 * 
 * @param data new Date(24/04/2024)
 * @returns 24/04/2024
 */
export const dateToString = (data: Date) => {
    if(!data) return;
    // Obter o dia, mês e ano da data atual
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // Os meses começam do zero, então é necessário adicionar 1
    const ano = data.getFullYear();

    // Formatar os valores para o formato DD/MM/AAAA
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano}`;
}

/**
 * 
 * @param data new Date(24/04/2024)
 * @returns 2024-04-24
 */
export const dateToEnglishDateString = (data: Date) => {
    if(!data) return;
    // Obter o dia, mês e ano da data atual
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // Os meses começam do zero, então é necessário adicionar 1
    const ano = data.getFullYear();

    // Formatar os valores para o formato DD/MM/AAAA
    return `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
}

/**
 * 
 * @param data new Date(24/04/2024)
 * @returns 24/04/2024 - 12:15
 */
export const dateToStringDateAndHour = (date: Date) => {
    const dateString = dateToString(date);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Formatar os valores para o formato DD/MM/AAAA
    return `${dateString} - ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

/**
 * 
 * @param data 2024-04-24
 * @returns new Date(24/04/2024)
 */
export const englishDateStringToDate = (data: string) => {
    const date = new Date();

    const [ano, mes, dia] = data.split('-');

    date.setFullYear(Number(ano));
    date.setMonth(Number(mes));
    date.setDate(Number(dia));
    return date;
}

/**
 * 
 * @param data 2024-04-24
 * @returns 24/04/2024
 */
export const englishDateStringToPortugueseDateString = (data: string) => {
    const dataObj = englishDateStringToDate(data);
    return dateToString(dataObj);
}

/**
 * 
 * @param data 2024-04-24T16:52:10
 * @returns 24/04/2024
 */
export const unformatedStringDateToPortugueseDateString = (data: string) => {
    if(!data) return '';
    return dateToString(new Date(data));
}

/**
 * 
 * @param data 2024-04-24T16:52:10
 * @returns 24/04/2024 - 12:15
 */
export const unformatedStringDateHourToPortugueseDateHourString = (data: string) => {
    if(!data) return '';
    return dateToStringDateAndHour(new Date(data));
}