import { Location } from "@angular/common";
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable, tap } from 'rxjs';


export interface ActionToolbar {
    text: string,
    icon: string,
    type: 'basic' | 'primary' | 'accent' | 'warn',
    onClick: Observable<any>,
    disabled?: boolean
}

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

    @Input() disableBefore = false;
    @Input() titulo!: string;
    @Input() back: Observable<any> | null = null;
    @Input() actions: ActionToolbar[] = [];

    constructor(private location: Location) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    backFunction = () => {
        if (this.back) this.back.subscribe();
        else {
            this.location.back();
        }
    }

    executeAction(action: ActionToolbar) {

        const icon = action.icon;
        action.icon = 'more_horiz';
        action.disabled = true;

        action.onClick.pipe(tap({
            next: () => {
                action.icon = icon;
                action.disabled = false;
            },
            error: () => {
                action.icon = icon;
                action.disabled = false;
            }
        })).subscribe();
    }
}
