import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '../../services/api.service';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [SelectComponent],
    imports: [CommonModule, MatSelectModule],
    providers: [ApiService],
    exports: [SelectComponent]
})
export class SelectModule {}
