import { Component, OnInit } from '@angular/core';
import { acessoPessoalResource } from './acesso-pessoal.resource';
import { AcessoPessoalService } from 'src/app/shared/api/acesso-pessoal.service';

@Component({
    selector: 'app-acesso-pessoal',
    templateUrl: './acesso-pessoal.component.html',
    styleUrls: ['./acesso-pessoal.component.css']
})
export class AcessoPessoalComponent implements OnInit {
    acessoPessoalResource = acessoPessoalResource;

    constructor(public acessoPessoalService: AcessoPessoalService) { }

    ngOnInit(): void {
    }

}
