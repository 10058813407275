export enum EscolaridadeAcessoPessoalEnum {
    FUNDAMENTAL_INCOMPLETO = 'fundamental_incompleto',
    FUNDAMENTAL_COMPLETO = 'fundamental_completo',
    MEDIO_INCOMPLETO = 'medio_incompleto',
    MEDIO_COMPLETO = 'medio_completo',
    TECNICO_INCOMPLETO = 'tecnico_incompleto',
    TECNICO_COMPLETO = 'tecnico_completo',
    SUPERIOR_INCOMPLETO = 'superior_incompleto',
    SUPERIOR_COMPLETO = 'superior_completo',
    POS_INCOMPLETA = 'pos_incompleta',
    POS_COMPLETA = 'pos_completa'
}

export enum NomeEscolaridadeAcessoPessoalEnum {
    FUNDAMENTAL_INCOMPLETO = 'Fundamental incompleto',
    FUNDAMENTAL_COMPLETO = 'Fundamental completo',
    MEDIO_INCOMPLETO = 'Medio incompleto',
    MEDIO_COMPLETO = 'Medio completo',
    TECNICO_INCOMPLETO = 'Tecnico incompleto',
    TECNICO_COMPLETO = 'Tecnico completo',
    SUPERIOR_INCOMPLETO = 'Superior incompleto',
    SUPERIOR_COMPLETO = 'Superior completo',
    POS_INCOMPLETA = 'Pos incompleta',
    POS_COMPLETA = 'Pos completa'
}

export const getListaEscolaridadesDisponiveis = () => {
    const listaEscolaridades: { id: string, nome: string }[] = [];

    Object.keys(EscolaridadeAcessoPessoalEnum).forEach((chave) => {
        const id = EscolaridadeAcessoPessoalEnum[chave as keyof typeof EscolaridadeAcessoPessoalEnum];
        const nome = NomeEscolaridadeAcessoPessoalEnum[chave as keyof typeof NomeEscolaridadeAcessoPessoalEnum];

        listaEscolaridades.push({id: id, nome: nome});
    })

    return listaEscolaridades;
}

export const getNomeEscolaridadeFromValue = (value: string) =>{
    let escolaridadeFind: any;
    Object.keys(EscolaridadeAcessoPessoalEnum).forEach((chave) => {
        const id = EscolaridadeAcessoPessoalEnum[chave as keyof typeof EscolaridadeAcessoPessoalEnum];
        const nome = NomeEscolaridadeAcessoPessoalEnum[chave as keyof typeof NomeEscolaridadeAcessoPessoalEnum];

        if(id === value) escolaridadeFind = nome;
    })

    return escolaridadeFind;
}
