import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";
import { Observable } from "rxjs";
import { PaginatedResponse } from "../pagination.model";


export interface IMaterial extends IResponse {
    nome: string,
    descricao: string,
    /** uuid do teste */
    teste: string
}

@Injectable()
export class AdmMateriaisService extends TemplateApiService<IMaterial> {

    public teste: string = '';

    public override setBaseUrl(idTeste: string){
        this.teste = idTeste;
        this.baseUrl = `admin/materiais`;
    }

    override get(params: any = {}): Observable<PaginatedResponse<IMaterial>> {
        params['teste'] = this.teste;
        return super.get(params);
    }
}
