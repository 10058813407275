<container-padrao>
    <card title="Autocadastros">

        <div class="mb-3">
            <primary-button (click)="onClickCriarAutocadastro()">Criar autocadastro</primary-button>
        </div>
        
        <tabela-listagem
            #listaAutocadastro
            [colunas]="colunasListar"
            [service]="autocadastroService"
            [actions]="actionsTabelaAutocadastro"
        ></tabela-listagem>
    </card>
</container-padrao>

<modal #modalAutocadastro [data]="modalAutocadastroData" [onSave]="onSalvarAutocadastro">
    <formulario-dinamico
        *ngIf="exibirModal"
        #formularioAutocadastro
        [colunas]="colunasModal"
        [entidade]="autocadastro"
    ></formulario-dinamico>

    <div class="mt-3 d-flex" *ngIf="urlautocadastroClipboard">
        <div style="width: 80%;">
            <code>{{urlautocadastroClipboard}}</code>
        </div>
        <div style="width: 20%">
            <button-component (click)="copiarParaClipboard(urlautocadastroClipboard)" icon="content_copy"></button-component>
        </div>
    </div>
</modal>