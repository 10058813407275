<div class="small-shadow border container-tabela cdkScrollable">
    <table
        mat-table
        [dataSource]="entidades"
        class="tabela-listagem"
        matSort
        matSortActive="created"
        matSortDisableClear
        matSortDirection="desc"
    >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select" *ngIf="ativarSelecao">
            <th mat-header-cell *matHeaderCellDef class="celula-checkbox">
                <mat-checkbox
                    *ngIf="selectionMode == 'multiple'"
                    [color]="'primary'"
                    (change)="toggleAllRows()"
                    [checked]="selectedKeys.hasValue() && isAllSelected()"
                    [indeterminate]="selectedKeys.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="celula-checkbox">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selecionarLinha(row)"
                    [color]="'primary'"
                    [checked]="isSelected(row)"
                    [aria-label]="checkboxLabel(row)"
                >
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container *ngFor="let column of colunasListadas">
            <ng-container [matColumnDef]="column.field">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="celula-cabecalho"
                    [ngStyle]="{
                        width: column.sizeCellTable ? column.sizeCellTable + '%' : 'auto',
                        textAlign: column.textAlignCellTable || 'left'
                    }"
                >
                    {{ column.label || column.field }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    matTooltipClass="tooltip-celula-body-tabela"
                    [ngStyle]="{
                        textAlign: column.textAlignCellTable || 'left'
                    }"
                    [matTooltip]="calculateDisplayTooltip(element, column)"
                    [matTooltipShowDelay]="1000"
                >
                    <div [innerHTML]="calculateDisplayValue(element, column) | safeHtml"></div>
                </td>
            </ng-container>
        </ng-container>

        <ng-container [matColumnDef]="'actions'">
            <th mat-header-cell *matHeaderCellDef class="celula-cabecalho text-center">Ações</th>

            <td mat-cell *matCellDef="let row" class="text-center">
                <div class="d-flex align-items-center justify-content-center">
                    <ng-container *ngFor="let action of actions">
                        <button
                            *ngIf="isVisibleAction(action, row)"
                            (click)="executeAction(action, row)"
                            mat-mini-fab
                            [color]="action.type"
                            [matTooltip]="action.hint"
                            [matTooltipShowDelay]="1000"
                            class="mr-1 btn-listagem-tabela"
                        >
                            <mat-icon>{{ action.icon }}</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="listaColunasExibir"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: listaColunasExibir"
            class="linha-tabela"
            (click)="selecionarLinha(row)"
        ></tr>
    </table>

    <mat-progress-bar *ngIf="loader" mode="indeterminate"></mat-progress-bar>

    <mat-paginator
        [pageSizeOptions]="[10]"
        [length]="paginacao.total || 0"
        showFirstLastButtons
        (page)="alterarPagina($event)"
    ></mat-paginator>
</div>
