import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestesComponent } from './testes.component';
import { TableListModule } from '../../shared/components/table-list/table-list.module';
import { TestesRoutingModule } from './testes-routing.module';
import { TestesAdmService } from 'src/app/shared/api/testes/testes.service';
import { TesteFormComponent } from './teste-form/teste-form.component';
import { EditComponentModule } from '../../shared/components/edit-component/edit-component.module';
import { CardModule } from '../../shared/components/card/card.module';
import { ModulosModule } from '../modulos/modulos.module';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ModalModule } from '../../shared/components/modal/modal.module';
import { VersoesTestesAdmService } from 'src/app/shared/api/testes/versoes-testes.service';
import { TabelaListagemModule } from '../../shared/components/tabela-listagem/tabela-listagem.module';
import { ToolbarModule } from '../../shared/components/toolbar/toolbar.module';
import { ContainerPadraoModule } from '../../shared/components/container-padrao/container-padrao.module';
import { MateriaisCursoComponent } from './materiais-curso/materiais-curso.component';
import { ButtonModule } from '../../shared/components/button/button.module';
import { LoaderPageModule } from '../../shared/components/loader-page/loader-page.module';
import { AdmMateriaisService } from 'src/app/shared/api/testes/adm-materiais.service';
import { FormularioDinamicoModule } from '../../shared/components/formulario-dinamico/formulario-dinamico.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { CardMidiaMaterialModule } from './card-midia-material/card-midia-material.module';
import { SelectModule } from '../../shared/components/select/select.module';

@NgModule({
    declarations: [TestesComponent, TesteFormComponent, MateriaisCursoComponent],
    providers: [
        TestesAdmService,
        DialogService,
        VersoesTestesAdmService,
        AdmMateriaisService,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true }
        }
    ],
    imports: [
        CommonModule,
        TestesRoutingModule,
        TableListModule,
        EditComponentModule,
        CardModule,
        ModulosModule,
        ModalModule,
        TabelaListagemModule,
        ToolbarModule,
        ContainerPadraoModule,
        ButtonModule,
        LoaderPageModule,
        FormularioDinamicoModule,
        MatStepperModule,
        CardMidiaMaterialModule,
        SelectModule,
    ]
})
export class TestesModule {}
