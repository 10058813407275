<container-padrao>
    <loader-page [show]="loading"></loader-page>

    <div [innerHTML]="htmlRelatorio | safeHtmlRelatorio"></div>

    <div class="error-container" *ngIf="error">
        <div class="error-icon">📄</div>
        <h1>Oops! Página não encontrada</h1>
        <p>A página que você está procurando pode ter sido removida, renomeada ou está temporariamente indisponível.</p>
        <primary-button icon="home" routerLink="/">Voltar para a Página Inicial</primary-button>
    </div>
</container-padrao>