import { Observable } from "rxjs";
import { IResponse } from "./response.model";
import { TemplateApiService } from "./template-api.service";
import { Injectable } from "@angular/core";
import { ApiService } from "../services/api.service";
import { PaginatedResponse } from "./pagination.model";


export interface ITermo extends IResponse {
    texto: string,
    data_criacao: string,
    usuario: {
        nome: string,
        email: string,
    },
    acesso: string,
    ativo: boolean
}

@Injectable()
export class TermosDeUsoService{

    constructor(
        private apiService: ApiService
    ){}

    public aceitarTermo(termo: string): Observable<ITermo>{
        return this.apiService.post('termos-usuario', {termo: termo});
    }

    public get(params = {}) {
        return this.apiService.get('termos', params) as Observable<PaginatedResponse<ITermo>>;
    }
}
