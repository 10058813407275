import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectFormularioComponent } from './select-formulario.component';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '../../services/api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [SelectFormularioComponent],
    imports: [CommonModule, MatSelectModule, ReactiveFormsModule, FormsModule],
    providers: [ApiService],
    exports: [SelectFormularioComponent]
})
export class SelectFormularioModule {}
