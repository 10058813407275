<div class="small-shadow border {{class}}">

    <div class="card-title" *ngIf="title">
        <p>{{title}}</p>

        <button mat-mini-fab color="basic" class="btn-expand" (click)="toggleCard()">
            <mat-icon *ngIf="cardOpened">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!cardOpened">keyboard_arrow_down</mat-icon>
        </button>

    </div>

    <div [@cardAnimation]="cardOpened ? 'expanded' : 'collapsed'"
        class="card-content"
        [ngClass]="{'card-content-opened': cardOpened, 'card-content-closed': !cardOpened}"
        #cardContent>
        <ng-content></ng-content>
    </div>
</div>
