import { Injectable } from "@angular/core";
import { IResponse } from "./response.model";
import { TemplateApiService } from "./template-api.service";


export interface ICliente extends IResponse {
    ativo: boolean,
    data_criacao: string,
    tipo: string
}


@Injectable()
export class EquipeService extends TemplateApiService<ICliente> {

    public override setBaseUrl(...props: any) {
        this.baseUrl = 'equipes';
    }
}
