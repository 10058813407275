<div class="midia small-shadow border rounded" *ngIf="tipoMidia == 1">
    <mat-card class="example-card">
        <ng-container *ngIf="midia.url">
            <img
                *ngIf="imageFormats.includes(midia.formato!.toLocaleLowerCase())"
                [src]="midia.url"
                mat-card-image
                class="content-card"
            />
            <video
                class="content-card"
                controls
                *ngIf="videoFormats.includes(midia.formato!.toLocaleLowerCase())"
            >
                <source [src]="midia.url" />
            </video>
            <div
                *ngIf="
                    !videoFormats.includes(midia.formato!.toLocaleLowerCase()) &&
                    !imageFormats.includes(midia.formato!.toLocaleLowerCase())
                "
            >
                <span>{{ midia.descricao }}</span>
            </div>
        </ng-container>
        <mat-card-content *ngIf="!midia.url" class="content-card">
            <div class="content-text">Selecionar arquivo</div>
            <input (change)="changeFile($event)" type="file" class="input-file" />
        </mat-card-content>

        <mat-card-actions>
            <div class="d-flex justify-content-between">
                <button *ngIf="midia.url" (click)="excluirMidia()" mat-button color="warn">
                    <mat-icon>delete</mat-icon>
                    Excluir arquivo
                </button>
                <button
                    *ngIf="midia.uuid && midia.url"
                    class="ms-2"
                    mat-button
                    color="primary"
                    (click)="baixarArquivo()"
                >
                    <mat-icon>download</mat-icon>
                    Baixar arquivo
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>

<div class="form-group" *ngIf="tipoMidia == 2">
    <input
        [value]="linkMidia"
        (keyup)="changeInputLink($event)"
        class="form-control"
        placeholder="Url"
        type="text"
        [disabled]="midia.uuid"
    />
    <button *ngIf="midia.uuid" (click)="excluirMidia()" mat-button color="warn">
        <mat-icon>delete</mat-icon>
        Excluir link
    </button>
</div>
