import { AdmSkinService } from "src/app/shared/api/adm-skin.service";
import { ColumnDataType, Resource } from "src/app/shared/model/resource";
import { skinResource } from "../skins/skin.resource";

export const equipeClienteResource: Resource = {
    nomeEntidade: {
        singular: 'Equipe',
        plural: 'Equipes'
    },
    fieldPk: 'uuid',
    fieldName: 'nome',
    route: {
        url: 'equipes',
    },
    colunas: [
        {
            field: 'nome',
            label: 'Nome',
            required: true,
        },
        {
            field: 'skin',
            label: 'Skin',
            type: ColumnDataType.Lookup,
            lookupConfiguration: {
                colunas: skinResource.colunas,
                service: AdmSkinService,
                fieldName: 'nome',
                fieldPk: 'uuid'
            },
            getValueBeforSave: (value: any) => {
                return value?.uuid || null;
            },
            calculateDisplayValue(entity) {
                return entity?.nome || '';
            },
        },
        {
            field: 'exibir_media_relatorio',
            label: 'Exibir média no relatório',
            type: ColumnDataType.Checkbox,
            sizeColumn: 'col-12',
            calculateDisplayValue: (value) => value ? 'Sim' : 'Não'
        }
    ],
    actions: [],
    availableReferences: []
}
