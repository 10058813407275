import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AdmMidiasService, IMidia } from 'src/app/shared/api/testes/adm-midias.service';
import { TrilhasService } from 'src/app/shared/api/testes/trilhas.service';

@Component({
    selector: 'midias-trilha',
    templateUrl: './midias-trilha.component.html',
    styleUrls: ['./midias-trilha.component.scss']
})
export class MidiasTrilhaComponent implements OnInit {

    @Input() idTeste!: string;
    @Input() idTrilha: string | null = null;

    cardCapaTrilhaConfiguration?: IMidia;
    cardCapaMaterialConfiguration?: IMidia;
    content_type = 'trilha';

    midiasTrilha: IMidia[] = [];

    constructor(
        private trilhasService: TrilhasService,
        private midiaService: AdmMidiasService
    ) {}

    ngOnInit(): void {
        if(this.idTrilha) this.listarMidias();
    }

    obterTrilha = () => {
        const sub = this.trilhasService.create({ teste: this.idTeste }).subscribe({
            next: (response) => {
                sub.unsubscribe();
                this.idTrilha = response.uuid;
                this.listarMidias();
            }
        });
    }

    listarMidias = () => {
        const sub = this.midiaService
            .get({ content_type: this.content_type, obj_referencia: this.idTrilha })
            .subscribe(response => {
                sub.unsubscribe();
                this.midiasTrilha = response.resultados;
            });
    }

    changeMidia(){
        this.listarMidias();
    }

    criarNovaTrilha = () => {
        return new Observable(observer => {
            const sub = this.trilhasService.create({ teste: this.idTeste }).subscribe({
                next: (response) => {
                    sub.unsubscribe();
                    this.idTrilha = response.uuid;
                }
            });
        })
    };


}
