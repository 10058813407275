<container-padrao>
    <mat-toolbar class="toolbar small-shadow">

        <div class="before">

            <ng-container *ngIf="!disableBefore">
                <button (click)="backFunction()" mat-mini-fab color="basic" class="btn-back mr-3">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
            </ng-container>

            <span>{{titulo}}</span>
        </div>


        <div class="after">

            <button
                *ngFor="let action of actions"
                mat-stroked-button
                [color]="action.type"
                [disabled]="action.disabled"
                (click)="executeAction(action)"
                class="ms-1"
            >
                <mat-icon>{{action.icon}}</mat-icon>
                {{action.text}}
            </button>

        </div>

    </mat-toolbar>
</container-padrao>