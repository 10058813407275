import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMidia } from 'src/app/shared/api/testes/adm-midias.service';
import { IAlternativaJogada, IPerguntaJogada } from 'src/app/shared/api/testes/jogada.service';

@Component({
    selector: 'perguntas-alternativas',
    templateUrl: './perguntas-alternativas.component.html',
    styleUrls: ['./perguntas-alternativas.component.css', '../play.component.scss']
})
export class PerguntasAlternativasComponent implements OnInit {
    @Input() midias: IMidia[] = [];
    @Input() pergunta!: IPerguntaJogada;
    @Input() alternativas: IAlternativaJogada[] = [];
    @Input() progresso!: number;
    @Input() showLoaderPerguntas = true;

    @Output() onConfirmarAlternativa = new EventEmitter();
    @Output() onBtnBack = new EventEmitter();

    alternativaSelecionada: any;

    constructor() {}

    ngOnInit(): void {}

    confirmAlternativa() {
        this.onConfirmarAlternativa.emit(this.alternativaSelecionada);
        this.alternativaSelecionada = null;
    }

    backFunction(){
        this.onBtnBack.emit();
    }
}
