import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelatoriosRoutingModule } from './relatorios-routing.module';
import { RelatoriosComponent } from './relatorios.component';
import { ResultadoComponent } from './resultado/resultado.component';
import { RelatorioService } from 'src/app/shared/api/testes/relatorios.service';
import { SafeHtmlRelatorioPipe } from './safeHtmlPipeRelatorio';
import { LoaderPageModule } from '../../shared/components/loader-page/loader-page.module';
import { ContainerPadraoModule } from '../../shared/components/container-padrao/container-padrao.module';
import { PessoalMateriaisService } from 'src/app/shared/api/testes/pessoal-materiais.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { TabelaListagemModule } from '../../shared/components/tabela-listagem/tabela-listagem.module';

@NgModule({
    declarations: [
        RelatoriosComponent,
        ResultadoComponent,
        SafeHtmlRelatorioPipe,
    ],
    imports: [
        CommonModule,
        RelatoriosRoutingModule,
        LoaderPageModule,
        ContainerPadraoModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        TabelaListagemModule
    ],
    providers: [RelatorioService, SafeHtmlRelatorioPipe, PessoalMateriaisService, MidiasService]
})
export class RelatoriosModule {}
