import { Injectable } from "@angular/core";
import { IAcessoPessoal } from "./acesso-pessoal.service";
import { TemplateApiService } from "./template-api.service";


@Injectable()
export class AdmAcessosService extends TemplateApiService<IAcessoPessoal> {

    public override setBaseUrl(...props: any): void {
        this.baseUrl = 'admin/acessos';
    }
}
