import { Component, OnInit } from '@angular/core';
import { ConfigurationColumn } from 'src/app/shared/model/resource';
import { clienteResource } from '../cliente.resource';
import { ClientesService } from 'src/app/shared/api/clientes.service';
import { AdmEquipeService } from 'src/app/shared/api/adm-equipe.service';
import { equipeClienteResource } from '../equipe-cliente.resource';

@Component({
    selector: 'app-cliente-form',
    templateUrl: './cliente-form.component.html',
    styleUrls: ['./cliente-form.component.css']
})
export class ClienteFormComponent implements OnInit {

    clienteColumns: ConfigurationColumn[] = clienteResource.colunas;
    clienteResource = clienteResource;
    equipeResource = equipeClienteResource;
    entidadeOriginal: any;

    constructor(public clienteService: ClientesService, public adminEquipeService: AdmEquipeService) { }

    ngOnInit(): void {
    }

    setarIdEquipe(entidade: any){
        this.adminEquipeService.setBaseUrl(entidade[clienteResource.fieldPk] as string);
    }
}
