import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";


export interface IAlternativa extends IResponse {
    descricao: string,
    pontuacao: number
}


@Injectable()
export class AlternativaService extends TemplateApiService<IAlternativa> {

    public override setBaseUrl(idTeste: string, idModulo: string, idRodada: string, idPergunta: string){
        this.baseUrl = `admin/testes/${idTeste}/modulos/${idModulo}/rodadas/${idRodada}/perguntas/${idPergunta}/alternativas`;
    }
}
