import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMidia } from 'src/app/shared/api/testes/adm-midias.service';
import { IModulo } from 'src/app/shared/api/testes/modulos.service';
import { IRodada } from 'src/app/shared/api/testes/rodada.service';

@Component({
    selector: 'modulo-rodada',
    templateUrl: './modulo-rodada.component.html',
    styleUrls: ['./modulo-rodada.component.scss', '../play.component.scss']
})
export class ModuloRodadaComponent implements OnInit {
    @Input() entity!: IModulo | IRodada;
    @Input() midias: IMidia[] = [];
    @Input() showLoader!: boolean;
    @Input() disableBefore = false;

    @Output() onClick = new EventEmitter();
    @Output() onBtnBack = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    onClickContinuar(){
        this.onClick.emit();
    }

    backFunction(){
        this.onBtnBack.emit();
    }
}
