import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationColumn } from '../../model/resource';
import { TemplateApiService } from '../../api/template-api.service';
import { IResponse } from '../../api/response.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginatedResponse } from '../../api/pagination.model';

export type EventClickTabela = (
    entity: any,
    service: TemplateApiService<IResponse>,
    router: Router,
    route: ActivatedRoute
) => void;

export interface ActionsTabela {
    text: string;
    icon: string;
    mode?: 'single' | 'batch';
    type: 'primary' | 'warn' | 'accent' | 'basic';
    hint: string;
    onClick: EventClickTabela;
    isVisible?: (entity: any) => boolean;
}

@Component({
    selector: 'tabela-listagem',
    templateUrl: './tabela-listagem.component.html',
    styleUrls: ['./tabela-listagem.component.css']
})
export class TabelaListagemComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    @Input() url!: string;
    @Input() colunas!: ConfigurationColumn[];
    @Input() valueExpr!: string;
    @Input() actions: ActionsTabela[] = [];
    @Input() selectionMode: 'single' | 'multiple' = 'single';
    @Input() ativarSelecao = false;
    @Input() service!: TemplateApiService<IResponse>;
    @Input() filterResultData: Function | null = null;
    @Input() entidadePai: any;

    @Output() finalizouListagem = new EventEmitter();

    colunasListadas: ConfigurationColumn[] = [];
    actionsSingle: ActionsTabela[] = [];
    actionsBatch: ActionsTabela[] = [];
    apiSubscription: any;
    entidades: any[] = [];
    listaColunasExibir: string[] = [];
    selectedKeys!: SelectionModel<any>;
    paginacao: PaginatedResponse<any> = { 'total-paginas': 0, pagina: 0, resultados: [], total: 0 };
    loader = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.customizarMatPaginator();
        const multiple = this.selectionMode == 'multiple';
        this.selectedKeys = new SelectionModel<any>(multiple, []);
    }

    ngOnDestroy(): void {
        if (this.apiSubscription) this.apiSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['colunas']) {
            this.setup();
        }
    }

    private setup() {
        this.entidades = [];
        this.configurarColunas();
        this.configurarActions();
        this.listarEntidades();
    }

    private configurarColunas() {
        this.colunasListadas = this.colunas
            .filter((coluna) => !coluna.unAvailableOnList)
            .map((coluna) => coluna);
        this.listaColunasExibir = this.colunasListadas.map((col) => col.field);

        if (this.ativarSelecao) this.listaColunasExibir.unshift('select');
        if (this.actions.length > 0) this.listaColunasExibir.push('actions');
    }

    private configurarActions() {
        this.actionsSingle = this.actions.filter((action) => action.mode === 'single');
        this.actionsBatch = this.actions.filter((action) => !(action.mode === 'single'));
    }

    public listarEntidades(params: any = { pagina: 1, tamanho: 10 }) {
        this.loader = true;
        this.entidades = [];
        this.apiSubscription = this.service?.get(params).subscribe({
            next: (response) => {
                this.loader = false;
                if(this.filterResultData != null){
                    this.entidades = this.filterResultData(response.resultados);
                } else {
                    this.entidades = response.resultados;
                }
                
                this.paginacao = response;
                this.finalizouListagem.emit();
            }
        });
    }

    public getLinhasSelecionadas() {
        return this.selectedKeys.selected;
    }

    public setLinhasSelecionadas = (selected: any) => {
        selected.forEach((element: any) => {
            const entidadeFind = this.findObjetoEmEntidades(element);
            if (entidadeFind) this.selectedKeys.select(entidadeFind);
        });
    };

    private findObjetoEmEntidades(objetoEncontrar: any) {
        return this.entidades.find((e) => this.entidadeEquals(e, objetoEncontrar));
    }

    private entidadeEquals(objetoEntidade: any, objetoComparar: any) {
        if (!this.valueExpr) throw new Error('Defina o valueExpr da tabela listagem');

        const idEntidade = objetoEntidade[this.valueExpr];
        const idComparar = objetoComparar[this.valueExpr];

        if (!idEntidade || !idComparar) return false;

        return idEntidade == idComparar;
    }

    public alterarPagina(pageEvent: PageEvent) {
        const tamanho = pageEvent.pageSize;
        const pagina = pageEvent.pageIndex + 1;
        this.listarEntidades({ tamanho, pagina });
    }

    executeAction(action: ActionsTabela, row: any) {
        action.onClick(row, this.service, this.router, this.route);
    }

    isVisibleAction(action: ActionsTabela, row: any) {
        if (action.isVisible) {
            return action.isVisible(row);
        }
        return true;
    }

    calculateDisplayValue(element: any, column: ConfigurationColumn) {
        const objeto = this.getValueFromObjectPath(element, column.field);

        if (column.calculateDisplayValue) {
            return column.calculateDisplayValue(objeto) || '';
        }
        return objeto || '';
    }

    calculateDisplayTooltip(element: any, column: ConfigurationColumn) {
        const objeto = this.getValueFromObjectPath(element, column.field);

        if (column.calculateDisplayTooltip) {
            return column.calculateDisplayTooltip(objeto) || '';
        }
        return objeto || '';
    }

    getValueFromObjectPath(object: any, path: string) {
        let value = object;
        const props = path.split('.');
        for (const prop of props) {
            if (!value[prop]) return null;
            value = value[prop];
        }
        return value;
    }

    selecionarLinha(row: any) {
        if (!this.ativarSelecao) return;

        this.selectedKeys.toggle(row);
    }

    isSelected(row: any){
        return this.selectedKeys.selected.some(selected => this.entidadeEquals(selected, row));
    }

    isAllSelected() {
        const numSelected = this.selectedKeys.selected.length;
        const numRows = this.entidades.length;
        return numSelected === numRows;
    }

    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selectedKeys.clear();
            return;
        }

        this.selectedKeys.select(...this.entidades);
    }

    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selectedKeys.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    private customizarMatPaginator() {
        this.paginator._intl.itemsPerPageLabel = 'Itens por página';

        this.paginator._intl.nextPageLabel = 'Próxima';

        this.paginator._intl.previousPageLabel = 'Anterior';

        this.paginator._intl.firstPageLabel = 'Primeira página';
        this.paginator._intl.lastPageLabel = 'Última página';

        this.paginator._intl.getRangeLabel = this.getRangeLabel;
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 de ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        const label = `${startIndex + 1} - ${endIndex} de ${length}`;
        return label;
    };
}
