<div class="body-container">
    <app-single-card class="app-card" [title]="'Autocadastro'" [description]="''">
        <formulario-dinamico #formularioAutocadastro [colunas]="autocadastroFormColumns"></formulario-dinamico>

        <!-- BOTAO LOGIN -->
        <button
            (click)="onClickBotao()"
            color="primary"
            mat-raised-button
            style="width: 100%; display: grid"
        >
            <ng-container *ngIf="!loading">
                <mat-icon>login</mat-icon>
                Receber Licença
            </ng-container>

            <div
                *ngIf="loading"
                style="width: 100%; display: flex; justify-content: center; padding: 0.3em"
            >
                <mat-icon>login</mat-icon>
                <mat-spinner [diameter]="30" color="accent"></mat-spinner>
            </div>
        </button>
    </app-single-card>
</div>
