import {ColumnDataType, Resource} from "../../shared/model/resource";

export const filtroPessoalLicencasResource: Resource = {
    disableDelete: true,
    nomeEntidade: {
        singular: 'licença',
        plural: 'licenças'
    },
    fieldPk: 'uuid',
    route: {
        url: 'licencas',
    },
    colunas: [
        {
            field: 'concluido',
            label: 'Estado',
            type: ColumnDataType.Select,
            sizeColumn: 'col-xl-3 col-lg-4 col-md-6',
            selectConfiguration: {
                displayExpr: 'nome',
                valueExpr: 'id',
                defaultData: [
                    {id: true, nome: 'Concluido'},
                    {id: false, nome: 'Não concluido'},
                    {id: 3, nome: 'Todas'},
                ],
                defaultValue: {id: 3, nome: 'Todas'}
            }
        },
    ],
    actions: [],
    availableReferences: []
}
