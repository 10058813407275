import { Component, OnInit } from '@angular/core';
import { AdmEquipeService } from 'src/app/shared/api/adm-equipe.service';
import { ActivatedRoute } from '@angular/router';
import { equipeClienteResource } from '../equipe-cliente.resource';
import { AdmAutocadastroService } from 'src/app/shared/api/adm-autocadastro.service';
import { TestesAtivosService } from 'src/app/shared/api/testes/testes.service';

@Component({
    selector: 'app-forms-equipes-cliente',
    templateUrl: './forms-equipes-cliente.component.html',
    styleUrls: ['./forms-equipes-cliente.component.css']
})
export class FormsEquipesClienteComponent implements OnInit {
    equipeResource = equipeClienteResource;
    entidade!: any;
    showAutocadastro = false;

    private idCliente: any;

    constructor(
        public equipeService: AdmEquipeService,
        private route: ActivatedRoute,
        public autocadastroService: AdmAutocadastroService,
        public testesAtivosService: TestesAtivosService
    ) {}

    ngOnInit(): void {
        this.idCliente = this.route.snapshot.paramMap.get('id')!;
        this.equipeService.setBaseUrl(this.idCliente);
    }

    changeEntidadeOriginal(equipe: any) {
        this.testesAtivosService.setBaseUrl({ ativo: true });
        this.autocadastroService.setBaseUrl(equipe[this.equipeResource.fieldPk]);
        this.showAutocadastro = true;
    }
}
