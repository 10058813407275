import { displayColorHexadeciamlToHTMLCircle } from "src/app/shared/functions/color-functions";
import {ColumnDataType, Resource} from "../../shared/model/resource";

export const skinResource: Resource = {
    nomeEntidade: {
        singular: 'Skin',
        plural: 'Skins'
    },
    fieldPk: 'uuid',
    route: {
        url: 'skins',
    },
    colunas: [
        {
            field: 'nome',
            required: true,
        },
        {
            field: 'cor_primaria',
            label: 'Cor primária',
            required: true,
            type: ColumnDataType.Color,
            calculateDisplayValue: displayColorHexadeciamlToHTMLCircle
        },
        {
            field: 'cor_secundaria',
            label: 'Cor secundária',
            required: true,
            type: ColumnDataType.Color,
            calculateDisplayValue: displayColorHexadeciamlToHTMLCircle
        },
    ],
    actions: [],
    availableReferences: []
}
