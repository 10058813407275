import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AcessoPessoalRoutingModule } from './acesso-pessoal-routing.module';
import { AcessoPessoalComponent } from './acesso-pessoal.component';
import { TableListModule } from "../../shared/components/table-list/table-list.module";
import { FormAcessoPessoalComponent } from './form-acesso-pessoal/form-acesso-pessoal.component';
import { EditComponentModule } from "../../shared/components/edit-component/edit-component.module";
import { AcessoPessoalService } from 'src/app/shared/api/acesso-pessoal.service';


@NgModule({
    declarations: [
        AcessoPessoalComponent,
        FormAcessoPessoalComponent
    ],
    imports: [
        CommonModule,
        AcessoPessoalRoutingModule,
        TableListModule,
        EditComponentModule
    ],
    providers: [
        AcessoPessoalService
    ]
})
export class AcessoPessoalModule { }
