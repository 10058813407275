import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookupComponent } from './lookup.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ModalModule } from "../modal/modal.module";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TabelaListagemModule } from "../tabela-listagem/tabela-listagem.module";



@NgModule({
    declarations: [
        LookupComponent
    ],
    exports: [
        LookupComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        ModalModule,
        MatIconModule,
        MatButtonModule,
        TabelaListagemModule
    ]
})
export class LookupModule { }
