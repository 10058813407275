import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdmTermosDeUsoService } from 'src/app/shared/api/adm-termos-de-uso.service';
import { termoDeUsoResource } from '../termo.resource';
import { ActionToolbar } from 'src/app/shared/components/toolbar/toolbar.component';
import { ITermo } from 'src/app/shared/api/termos-de-uso.service';
import { Observable, Subscription } from 'rxjs';
import { EditComponentComponent } from 'src/app/shared/components/edit-component/edit-component.component';

@Component({
    selector: 'app-form-termo',
    templateUrl: './form-termo.component.html',
    styleUrls: ['./form-termo.component.css']
})
export class FormTermoComponent implements OnInit, OnDestroy {
    @ViewChild('termoEditComponent') termoEditComponent!: EditComponentComponent;

    termoDeUsoResource = termoDeUsoResource;
    actions: ActionToolbar[] = [];
    termo: any = {};

    private _subscriberChangeAtivoTermo: Subscription | null = null;

    constructor(public termosDeUsoService: AdmTermosDeUsoService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._subscriberChangeAtivoTermo?.unsubscribe();
    }

    changeEntidadeOriginal(entidade: ITermo) {
        this.actions = [];

        if (!entidade.ativo) {
            this.actions.push({
                icon: 'flash_on',
                text: 'Ativar',
                type: 'accent',
                onClick: this.changeTermo(true)
            });
        } else {
            this.actions.push({
                icon: 'flash_off',
                text: 'Desativar',
                type: 'accent',
                onClick: this.changeTermo(false)
            });
        }
    }

    changeTermo = (ativo: boolean) => {
        return new Observable((observer) => {
            let request = this.termosDeUsoService.ativarTermo(this.termo.uuid);

            if (!ativo) {
                request = this.termosDeUsoService.desativarTermo(this.termo.uuid);
            }

            this._subscriberChangeAtivoTermo = request.subscribe({
                next: (response: any) => {
                    this.termoEditComponent.reload();
                }
            });
        });
    };
}
