import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerPadraoComponent } from './container-padrao.component';



@NgModule({
  declarations: [
    ContainerPadraoComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ContainerPadraoComponent
  ]
})
export class ContainerPadraoModule { }
