import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationService } from 'src/app/shared/services/notificationService';

@Component({
    selector: 'app-change-passsword-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: ['../login-form/login-form.css']
})
export class ChangePasswordFormComponent implements OnInit {
    loading = false;
    hide = true;
    formData: any = {};
    recoveryEmail?: string | null;
    token: any = null;

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private notify: NotificationService
    ) {}

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
    }

    async onSubmit(e: Event) {
        e.preventDefault();
        const { senha } = this.formData;
        this.loading = true;

        this.authService.changePassword(senha, this.token).subscribe(
            (response) => {
                this.loading = false;
                localStorage.clear();
                this.redirectLogin();
            },
            (error) => {
                this.loading = false;
                this.notify.error(error.error.erro);
            }
        );
    }

    confirmPassword = (e: any) => {
        return e.value === this.formData.password;
    };

    private redirectLogin() {
        this.router.navigate(['/login']);
    }
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        MatIconModule,
        MatProgressSpinnerModule
    ],
    declarations: [ChangePasswordFormComponent],
    exports: [ChangePasswordFormComponent]
})
export class ChangePasswordFormModule {}
