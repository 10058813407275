
export enum RoleEnum {
  ADMINISTRADOR = 'administrador',
  CLIENTE = 'cliente',
  PESSOAL = 'pessoal'
}

export interface NavigationMenu {
    text: string,
    icon?: string,
    path?: string,
    items?: NavigationMenu[],
    permissoes?: RoleEnum[]
}


export const navigation: NavigationMenu[] = [
    {
        text: 'Clientes',
        icon: 'group',
        path: 'clientes',
        permissoes: [RoleEnum.ADMINISTRADOR],
    },
    {
        text: 'Cursos',
        icon: 'quiz',
        path: 'testes',
        permissoes: [RoleEnum.ADMINISTRADOR],
    },
    {
        text: 'Licenças',
        icon: 'workspace_premium',
        path: 'adm/licencas',
        permissoes: [RoleEnum.ADMINISTRADOR],
    },
    {
        text: 'Termos de uso',
        icon: 'edit_document',
        path: 'termos-de-uso',
        permissoes: [RoleEnum.ADMINISTRADOR],
    },
    {
        text: 'Meus Cursos',
        icon: 'play_circle',
        path: 'licencas',
        permissoes: [RoleEnum.PESSOAL],
    },
    {
        text: 'Acesso Pessoal',
        icon: 'group',
        path: 'acesso-pessoal',
        permissoes: [RoleEnum.CLIENTE],
    },
    {
        text: 'Skins',
        icon: 'format_paint',
        path: 'skins',
        permissoes: [RoleEnum.CLIENTE],
    },
    {
        text: 'Equipes',
        icon: 'groups',
        path: 'equipes',
        permissoes: [RoleEnum.CLIENTE],
    },
];
