import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnChanges, OnDestroy {
    @Input() inicio!: string;
    @Input() duracao!: number;

    // tempoRestante: number = 0;
    segundos!: number;
    interval: any;

    corPrimaria!: string;
    corDark!: string;

    constructor() {}

    ngOnInit(): void {
        this.inicio = new Date().toISOString();


        this.corPrimaria = document.documentElement.style.getPropertyValue('--cor-primaria-jogo');
        this.corDark = document.documentElement.style.getPropertyValue('--cor-dark-jogo')
        this.start();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['inicio'] && changes['duracao']) {
            this.start();
        }
    }

    start() {
        this.segundos = this.getTempoRestante();
        clearInterval(this.interval);
        this.startTimer(this.segundos);
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    get strokeDashoffset(): number {
        const radius = 45;
        const circumference = 2 * Math.PI * radius;
        return circumference * (1 - this.segundos / this.duracao);
    }

    private getTempoRestante() {
        const horaAtual = new Date();
        const horaInicio = new Date(this.inicio);

        const tempoRestante = this.calcularSegundosRestantes(horaInicio, horaAtual, this.duracao);

        return tempoRestante > 0 ? tempoRestante : 0;
    }

    private startTimer(seconds: number) {
        this.segundos = seconds;
        this.interval = setInterval(() => {
            if (this.segundos > 0) {
                this.segundos--;
            } else {
                clearInterval(this.interval);
            }
        }, 1000);
    }

    segundosParaMinutos(segundos: number) {
        let minutos = Math.floor(segundos / 60);
        let segundosRestantes: number = segundos % 60;

        // Adicionando um zero à esquerda se os segundos restantes forem menores que 10
        let minutosFormatados = String(minutos).padStart(2, '0');
        let segundosFormatados = String(segundosRestantes).padStart(2, '0');

        return `${minutosFormatados}:${segundosFormatados}`;
    }

    stopTimer() {
        clearInterval(this.interval);
    }

    private calcularSegundosRestantes(
        horaInicio: Date,
        horaAtual: Date,
        duracaoEmSegundos: number
    ) {
        // Convertendo as datas para milissegundos
        const inicioMilissegundos = horaInicio.getTime();
        const atualMilissegundos = horaAtual.getTime();

        // Calculando o tempo decorrido em milissegundos
        const tempoDecorrido = atualMilissegundos - inicioMilissegundos;

        // Convertendo o tempo decorrido para segundos
        const segundosDecorridos = Math.floor(tempoDecorrido / 1000);

        // Calculando os segundos restantes
        const segundosRestantes = duracaoEmSegundos - segundosDecorridos;

        return segundosRestantes;
    }
}
