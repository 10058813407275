import { Injectable } from "@angular/core";
import { TemplateApiService } from "../template-api.service";
import { IResponse } from "../response.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";


export interface IModeloCSV extends IResponse{

}

@Injectable()
export class AdmCsvService extends TemplateApiService<IModeloCSV> {

    private readonly API = environment.API;

    public override setBaseUrl(){
        this.baseUrl = `licencas`;
    }

    obterModeloCSV(): any {
        const url = this.API + '/' + this.getBaseUrl() + '/modelo-csv';
        window.open(url, '_blank');
    }

    importarCSV(arquivo: File){
        const formData = new FormData();
        formData.append('arquivo', arquivo);

        const url = this.getBaseUrl() + '/importar-csv';

        return this.apiService.postFile(url, formData);
    }
}
