import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdmLicencasComponent } from './adm-licencas.component';

import { AdmLicencasFormComponent } from './adm-licencas-form/adm-licencas-form.component';
import { ImportarCsvLicencasComponent } from './importar-csv-licencas/importar-csv-licencas.component';
import { ConstantsRotasDoSistema } from '../../shared/constants/constants-rotas-do-sistema';
import { AuthGuardService } from '../../shared/services/auth.service';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.ADM_LICENCAS,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: AdmLicencasComponent
            },
            {
                path: 'novo',
                component: AdmLicencasFormComponent
            },
            {
                path: 'importar-licencas',
                component: ImportarCsvLicencasComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdmLicencasRoutingModule {}
