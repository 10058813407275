import {ColumnDataType, Resource} from "../../shared/model/resource";

export const rodadaResource: Resource = {
    nomeEntidade: {
        singular: 'Rodada (Tema)',
        plural: 'Rodadas (Temas)'
    },
    fieldPk: 'uuid',
    route: {
        url: 'rodadas',
    },
    colunas: [
        {
            field: 'posicao',
            required: true,
            type: ColumnDataType.Number,
            numberConfiguration: {
                minValue: 0
            },
            sizeColumn: 'col-12',
            unAvailableOnCreate: true
        },
        {
            field: 'nome',
            required: true,
            sizeColumn: 'col-12'
        },
        {
            field: 'descricao',
            label: 'Descrição',
            required: true,
            sizeColumn: 'col-12',
            type: ColumnDataType.TextArea,
            textareaConfiguration: {
                rows: 6
            }
        },
    ],
    actions: [],
    availableReferences: []
}
