import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.css', '../play.component.scss']
})
export class FeedbackComponent implements OnInit {

    @Input() texto!: string;

    @Output() onContinuarProximaPergunta = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    onClickContinuar(){
        this.onContinuarProximaPergunta.emit();
    }    
}
