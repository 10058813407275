import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RelatorioService } from 'src/app/shared/api/testes/relatorios.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'app-comparacao-relatorio',
    templateUrl: './comparacao-relatorio.component.html',
    styleUrls: ['./comparacao-relatorio.component.css']
})
export class ComparacaoRelatorioComponent implements OnInit {
    private id!: string;
    htmlRelatorio: any = '';
    loading = true;
    error = false;

    constructor(
        private route: ActivatedRoute,
        private relatorioService: RelatorioService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.authService.setTheme('dark');
        this.id = this.route.snapshot.paramMap.get('id')!;
        this.obterRelatorio();
    }

    obterRelatorio() {
        this.loading = true;
        const sub = this.relatorioService.getRelatorioComparativo(this.id).subscribe({
            next: (response) => {
                this.authService.setTheme('light');
                sub.unsubscribe();
                this.loading = false;
                this.htmlRelatorio = response;
            },
            error: () => {
                sub.unsubscribe();
                this.loading = false;
                this.error = true;
            }
        })
    }
}
