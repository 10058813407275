import { Injectable } from "@angular/core";
import { IResponse } from "./response.model";
import { TemplateApiService } from "./template-api.service";


export interface IAcessoPessoal extends IResponse {
    username: string,
    senha: string,
    nome: string,
    cpf: string,
    sexo: string,
    data_de_nascimento: string,
    empregabilidade: boolean,
    telefone: string,
    escolaridade: string,
}

export interface IAutenticarResponse {
    token: string
}

@Injectable()
export class AcessoPessoalService extends TemplateApiService<IAcessoPessoal> {

    public override setBaseUrl(...props: any): void {
        this.baseUrl = 'cliente-acessos-pessoais';
    }
}
