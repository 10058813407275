import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsWidthHeightMidia } from 'src/app/shared/components/card-midia/card-midia-constants';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';
import { IGrupoLicenca } from '../grupo-testes/grupo-testes.component';

@Component({
    selector: 'card-comparativo',
    templateUrl: './card-comparativo.component.html',
    styleUrls: ['./card-comparativo.component.scss']
})
export class CardComparativoComponent implements OnInit {
    constantsWidthHeightMidia = ConstantsWidthHeightMidia;

    @Input() grupo!: IGrupoLicenca;

    disponivel = false;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.setarDisponibilidade();
    }

    private setarDisponibilidade(){
        let todasLicencasConcluidas = true;
        this.grupo.licencasMateriais.forEach(licenca => {
            if(licenca.licenca.data_fim == null) {
                todasLicencasConcluidas = false;
            }
        })

        this.disponivel = todasLicencasConcluidas;
    }

    iniciar() {
        const idLicenca = this.grupo.licencasMateriais[0].licenca.uuid;
        this.router.navigate([
            ConstantsRotasDoSistema.DEFAULT_PATH + ConstantsRotasDoSistema.COMPARACAO_RELATORIOS + '/' + idLicenca
        ]);
    }
}
