import { Component, OnInit } from '@angular/core';
import { acessoPessoalResource } from '../acesso-pessoal.resource';
import { AcessoPessoalService } from 'src/app/shared/api/acesso-pessoal.service';

@Component({
    selector: 'app-form-acesso-pessoal',
    templateUrl: './form-acesso-pessoal.component.html',
    styleUrls: ['./form-acesso-pessoal.component.css']
})
export class FormAcessoPessoalComponent implements OnInit {

    acessoPessoalResource = acessoPessoalResource;

    constructor(public acessoPessoalService: AcessoPessoalService) { }

    ngOnInit(): void {
    }

}
