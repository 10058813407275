import { Component, OnInit } from '@angular/core';
import { skinResource } from './skin.resource';
import { SkinService } from 'src/app/shared/api/skins.service';

@Component({
    selector: 'app-skins',
    templateUrl: './skins.component.html',
    styleUrls: ['./skins.component.css']
})
export class SkinsComponent implements OnInit {

    skinResource = skinResource;

    constructor(public skinService: SkinService) { }

    ngOnInit(): void {
    }

}
