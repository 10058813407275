<div class="container-alternativas">
    <div *ngFor="let alternativa of alternativas">

        <div (click)="selectAlternativa(alternativa)"
            [ngClass]="{'alternativa-selected': idAlternativaSelecionada == alternativa.uuid}"
            class="btn-alternativa small-shadow">
            {{alternativa.descricao}}
        </div>
    </div>
</div>
