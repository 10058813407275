import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";


export interface IPergunta extends IResponse {
    descricao: string,
    posicao: number,
}

export interface IAcessoPessoalBodyLicenca {
    username: string,
    cpf: string,
    nome: string
}

export interface IBodyLiberarLicencaCliente {
    quantidade: number,
    dono: string,
    equipe?: string,
    teste: string
}

export interface IBodyAssociarLicenca {
    teste: string,
    acessos_pessoais: IAcessoPessoalBodyLicenca[],
    dono: string, /*** uuid do acesso cliente*/
    equipe?: string,
    disponivel_de?: string | null,
    disponivel_ate?: string | null
}


@Injectable()
export class AdmLicencasService extends TemplateApiService<IPergunta> {

    public override setBaseUrl(){
        this.baseUrl = `admin/licencas`;
    }

    public vincularLicencasAcessoPessoal(body: IBodyAssociarLicenca){
        const route = this.baseUrl + `/vincular-acessos-pessoais`;
        return this.apiService.post(route, body);
    }

    public vincularLicencasAcessoClientes(body: IBodyLiberarLicencaCliente){
        const route = 'admin/liberacao-licenca';
        return this.apiService.post(route, body);
    }
}
