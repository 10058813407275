import { truncateText } from 'src/app/shared/functions/format-functions';
import { ColumnDataType, Resource } from '../../shared/model/resource';
import { displayColorHexadeciamlToHTMLCircle } from 'src/app/shared/functions/color-functions';
import { TestesAdmService } from 'src/app/shared/api/testes/testes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateApiService } from 'src/app/shared/api/template-api.service';

export const testeResource: Resource = {
    disableDelete: true,
    nomeEntidade: {
        singular: 'Curso',
        plural: 'Cursos'
    },
    fieldPk: 'uuid',
    fieldName: 'nome',
    route: {
        url: 'testes'
    },
    linhas: [
        {
            sizeColumn: 'col-md-6',
            colunas: [
                {
                    field: 'nome',
                    required: true,
                    sizeColumn: 'col-md-12 col-lg-12',
                    sizeCellTable: 30
                },
                {
                    field: 'descricao',
                    label: 'Descrição',
                    type: ColumnDataType.TextArea,
                    textareaConfiguration: {
                        rows: 10
                    },
                    sizeColumn: 'col-md-12 col-lg-12',
                    sizeCellTable: 40,
                    calculateDisplayValue: (text: string) => truncateText(text, 200)
                },
                {
                    field: 'ativo',
                    label: 'Ativo',
                    unAvailableOnCreate: true,
                    unAvailableOnEdit: true,
                    calculateDisplayValue: (ativo: boolean) => (ativo ? 'Sim' : 'Não')
                }
            ]
        },
    ],
    colunas: [
        {
            field: 'ativo',
            label: 'Ativo',
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
            sizeCellTable: 10,
            textAlignCellTable: 'center',
            calculateDisplayValue: (ativo: boolean) => {
                if (ativo) return displayColorHexadeciamlToHTMLCircle('#28a745', 15, 15);
                return displayColorHexadeciamlToHTMLCircle('#dc3545', 15, 15);
            },
            calculateDisplayTooltip: (value) => (value ? 'Ativo' : 'Inativo')
        },
        {
            field: 'nome',
            required: true,
            sizeColumn: 'col-md-12 col-lg-12',
        },
        {
            field: 'descricao',
            label: 'Descrição',
            type: ColumnDataType.TextArea,
            textareaConfiguration: {
                rows: 10
            },
            sizeColumn: 'col-md-12 col-lg-12',
            sizeCellTable: 40,
            // calculateDisplayValue: (text: string) => truncateText(text, 200),
            unAvailableOnList: true
        },
        // replicar aqui para formulario conseguir criar o formcontrol
        // {
        //     field: 'antecessor',
        //     label: 'Teste Antecessor',
        //     sizeColumn: 'col-12',
        //     type: ColumnDataType.Lookup,
        //     unAvailableOnList: true,
        //     getValueBeforSave(value) {
        //         return value?.uuid || undefined;
        //     },
        //     lookupConfiguration: {
        //         service: TestesAdmService,
        //         fieldName: 'nome',
        //         fieldPk: 'uuid',
        //         colunas: [
        //             {
        //                 field: 'nome',
        //                 label: 'Nome',
        //             },
        //             {
        //                 field: 'descricao',
        //                 label: 'Descrição',
        //             }
        //         ]
        //     }
        // }
    ],
    actions: [],
    availableReferences: []
};
