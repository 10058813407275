import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdmLicencasRoutingModule } from './adm-licencas-routing.module';
import { AdmLicencasComponent } from './adm-licencas.component';
import { TableListModule } from '../../shared/components/table-list/table-list.module';
import { AdmLicencasService } from 'src/app/shared/api/licencas/adm-licencas.service';
import { AdmLicencasFormComponent } from './adm-licencas-form/adm-licencas-form.component';
import { ToolbarModule } from '../../shared/components/toolbar/toolbar.module';
import { AdmAcessosService } from 'src/app/shared/api/adm-acessos.service';
import { ClientesService } from 'src/app/shared/api/clientes.service';
import { CardModule } from '../../shared/components/card/card.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LookupModule } from '../../shared/components/lookup/lookup.module';
import { AcessosPessoaisClientesService } from 'src/app/shared/api/acessos-pessoais-clientes.service';
import { ButtonModule } from '../../shared/components/button/button.module';
import { TabelaListagemModule } from '../../shared/components/tabela-listagem/tabela-listagem.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LicencasDisponiveisAdmService } from 'src/app/shared/api/licencas/licencas-disponiveis-adm.service';
import { AdmEquipeService } from 'src/app/shared/api/adm-equipe.service';
import { TestesAtivosService } from 'src/app/shared/api/testes/testes.service';
import { TabelaEstaticaModule } from 'src/app/shared/components/tabela-estatica/tabela-estatica.module';
import { ModalModule } from '../../shared/components/modal/modal.module';
import { FormularioDinamicoModule } from '../../shared/components/formulario-dinamico/formulario-dinamico.module';
import { ContainerPadraoModule } from '../../shared/components/container-padrao/container-padrao.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ImportarCsvLicencasComponent } from './importar-csv-licencas/importar-csv-licencas.component';
import { AdmCsvService } from 'src/app/shared/api/licencas/adm-csv.service';
import { InputFileAreaModule } from '../../shared/components/input-file-area/input-file-area.module';

@NgModule({
    declarations: [AdmLicencasComponent, AdmLicencasFormComponent, ImportarCsvLicencasComponent],
    providers: [
        AdmLicencasService,
        AdmAcessosService,
        ClientesService,
        AcessosPessoaisClientesService,
        LicencasDisponiveisAdmService,
        AdmEquipeService,
        TestesAtivosService,
        AdmCsvService
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AdmLicencasRoutingModule,
        TableListModule,
        ToolbarModule,
        CardModule,
        LookupModule,
        ButtonModule,
        TabelaListagemModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        TabelaEstaticaModule,
        ModalModule,
        FormularioDinamicoModule,
        ContainerPadraoModule,
        MatDatepickerModule,
        InputFileAreaModule,
    ]
})
export class AdmLicencasModule {}
