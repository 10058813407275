import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ContainerPadraoModule } from "../container-padrao/container-padrao.module";



@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    ContainerPadraoModule
],
    exports: [
        ToolbarComponent
    ]
})
export class ToolbarModule { }
