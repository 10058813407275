import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationService } from 'src/app/shared/services/notificationService';




@Component({
    selector: 'app-create-account-form',
    templateUrl: './create-account-form.component.html',
    styleUrls: ['../login-form/login-form.css']
})
export class CreateAccountFormComponent {
    loading = false;
    formData: any = {};
    hide = true;

    escolaridades = [
        { name: 'Fundamental Incompleto', value: 'fundamental_incompleto' },
        { name: 'Fundamental Completo', value: 'fundamental_completo' },
        { name: 'Médio Incompleto', value: 'medio_incompleto' },
        { name: 'Médio Completo', value: 'medio_completo' },
        { name: 'Técnico Incompleto', value: 'tecnico_incompleto' },
        { name: 'Técnico Completo', value: 'tecnico_completo' },
        { name: 'Superior Incompleto', value: 'superior_incompleto' },
        { name: 'Superior Completo', value: 'superior_completo' },
        { name: 'Pós Incompleta', value: 'pos_incompleta' },
        { name: 'Pós Completa', value: 'pos_completa' },
    ]

    constructor(private authService: AuthService, private router: Router, private notificationService: NotificationService) { }

    validarFormulario() {
        const senha = String(this.formData.senha);
        const confirmar_senha = String(this.formData.confirmar_senha);

        if (senha != confirmar_senha) {
            this.notificationService.error('As senhas não correspondem');
            return false;
        }

        return true;
    }

    async onSubmit(e: Event) {
        e.preventDefault();
        // const { email, password } = this.formData;


        if (!this.validarFormulario()) return;

        this.loading = true;

        const usuario = {
            ...this.formData
        }

        delete usuario.confirmar_senha;

        this.authService.createAccount(usuario).subscribe({
            complete: () => {
                this.loading = false;
                this.notificationService.success('Conta criada com sucesso');
                this.router.navigate(['/login']);
            },
            error: (e: any) => {
                this.loading = false;
                this.notificationService.error(e);
            }
        })
    }

    confirmPassword = (e: any) => {
        return e.value === this.formData.password;
    }
}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        NgxMaskModule.forChild(),
        MatProgressSpinnerModule,
        MatSnackBarModule
    ],
    declarations: [CreateAccountFormComponent],
    exports: [CreateAccountFormComponent],
})
export class CreateAccountFormModule { }
