import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'primary-button',
    templateUrl: './primary-button.component.html',
    styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent implements OnInit {

    @Input() icon: string | null = null;
    @Input() text: string | null = null;
    @Input() tooltip: string = '';
    @Input() routerLink: string | null = null;
    @Input() loading = false;
    @Input() onClick?: Observable<any>;

    constructor() { }

    ngOnInit(): void {
    }

}
