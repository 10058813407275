import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardMidiaMaterialComponent } from './card-midia-material.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [CardMidiaMaterialComponent],
    imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule],
    exports: [CardMidiaMaterialComponent]
})
export class CardMidiaMaterialModule {}
