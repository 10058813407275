<div [innerHTML]="htmlRelatorio | safeHtmlRelatorio"></div>

<container-padrao>
    <loader-page [show]="loading"></loader-page>

    <div class="mt-5">
        <hr />
        <h2>Materiais para baixar</h2>

        <tabela-listagem
            [colunas]="colunasTabelaMateriais"
            [actions]="actionsMateriais"
            [service]="materiaisService"
        ></tabela-listagem>
    </div>
</container-padrao>
