<toolbar
    [titulo]="resource.nomeEntidade.singular"
    [actions]="actions"
></toolbar>

<container-padrao>
    <card [title]="resource.nomeEntidade.singular">
        <formulario-dinamico
            #formulario
            [colunas]="colunasVisiveis"
            [linhas]="linhasVisiveis"
            [entidade]="entidade"
        ></formulario-dinamico>
    </card>
</container-padrao>
