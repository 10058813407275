import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFileAreaComponent } from './input-file-area.component';

@NgModule({
    declarations: [InputFileAreaComponent],
    imports: [CommonModule],
    exports: [InputFileAreaComponent]
})
export class InputFileAreaModule {}
