import { unformatedStringDateToPortugueseDateString } from 'src/app/shared/functions/data-transform';
import { ColumnDataType, Resource } from '../../shared/model/resource';
import { TemplateApiService } from 'src/app/shared/api/template-api.service';
import { IResponse } from 'src/app/shared/api/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { displayColorHexadeciamlToHTMLCircle } from 'src/app/shared/functions/color-functions';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

export const licencasResource: Resource = {
    disableDelete: true,
    disableEdit: true,
    nomeEntidade: {
        singular: 'Licença',
        plural: 'Licenças'
    },
    fieldPk: 'uuid',
    route: {
        url: 'licencas'
    },
    colunas: [
        {
            field: 'dono.nome_fantasia',
            label: 'Cliente',
            unAvailableOnCreate: true,
            unAvailableOnEdit: true
        },
        {
            field: 'teste.nome',
            label: 'Curso',
            unAvailableOnCreate: true,
            unAvailableOnEdit: true
        },
        {
            field: 'liberado',
            label: 'Liberado',
            type: ColumnDataType.Checkbox,
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
            calculateDisplayValue: (ativo: boolean) => {
                if (ativo) return displayColorHexadeciamlToHTMLCircle('#28a745', 15, 15);
                return displayColorHexadeciamlToHTMLCircle('#dc3545', 15, 15);
            },
            calculateDisplayTooltip: (value) => (value ? 'Liberado' : 'Não liberado')
        },
        {
            field: 'nome_usuario',
            label: 'Usuário',
            unAvailableOnCreate: true,
            unAvailableOnEdit: true
        },
        {
            field: 'equipe.nome',
            label: 'Equipe',
            unAvailableOnCreate: true,
            unAvailableOnEdit: true
        },
        {
            field: 'data_inicio',
            label: 'Início',
            type: ColumnDataType.Date,
            calculateDisplayValue: unformatedStringDateToPortugueseDateString,
            calculateDisplayTooltip: unformatedStringDateToPortugueseDateString,
        },
        {
            field: 'data_fim',
            label: 'Fim',
            type: ColumnDataType.Date,
            calculateDisplayValue: unformatedStringDateToPortugueseDateString,
            calculateDisplayTooltip: unformatedStringDateToPortugueseDateString,
        },
        // {
        //     field: 'disponivel_de',
        //     label: 'Disponivel de',
        //     type: ColumnDataType.Date,
        //     calculateDisplayValue: unformatedStringDateToPortugueseDateString,
        //     calculateDisplayTooltip: unformatedStringDateToPortugueseDateString,
        // },
        {
            field: 'disponivel_ate',
            label: 'Disponivel Até',
            type: ColumnDataType.Date,
            calculateDisplayValue: unformatedStringDateToPortugueseDateString,
            calculateDisplayTooltip: unformatedStringDateToPortugueseDateString,
        }
    ],
    actions: [
        {
            hint: 'Importar arquivo CSV com licenças',
            icon: 'upload',
            text: 'Importar licenças CSV',
            type: 'accent',
            mode: 'batch',
            onClick: (
                entity: any,
                service: TemplateApiService<IResponse>,
                router: Router,
                route: ActivatedRoute
            ) => {
                router.navigate(['importar-licencas'], {relativeTo: route});
            }
        },
        {
            hint: 'Ver relatório',
            icon: 'assignment',
            text: 'Ver relatório',
            type: 'accent', 
            mode: 'single',
            isVisible: (entity: any) => entity.data_fim != null,
            onClick: (
                entity: any,
                service: TemplateApiService<IResponse>,
                router: Router,
                route: ActivatedRoute
            ) => {
                const rota = ConstantsRotasDoSistema.DEFAULT_PATH + ConstantsRotasDoSistema.RELATORIOS + '/' + entity.uuid;
                router.navigate([rota], {relativeTo: route});
            }
        }
    ],
    availableReferences: []
};
