import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { autocadastroFormColumns } from './autocadastro-form-resource';
import { AutocadastroService } from 'src/app/shared/api/autocadastro.service';
import { FormularioDinamicoComponent } from 'src/app/shared/components/formulario-dinamico/formulario-dinamico.component';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notificationService';

@Component({
    selector: 'app-autocadastro-form',
    templateUrl: './autocadastro-form.component.html',
    styleUrls: ['./autocadastro-form.component.scss']
})
export class AutocadastroFormComponent implements OnInit {
    @ViewChild('formularioAutocadastro') formularioAutocadastro!: FormularioDinamicoComponent;

    idAutocadastro!: string;

    autocadastroFormColumns = autocadastroFormColumns;
    loading = false;

    constructor(
        private autocadastroService: AutocadastroService,
        private route: ActivatedRoute,
        private notify: NotificationService
    ) {}

    ngOnInit(): void {
        this.idAutocadastro = this.route.snapshot.paramMap.get('id')!;
    }

    onClickBotao() {
        this.salvarAutocadastro();
    }

    private salvarAutocadastro() {
        if (!this.formularioAutocadastro.isValid()) return;

        const formulario = this.formularioAutocadastro.getDadosFormularioSalvar();
        this.loading = true;
        this.autocadastroService.receberLicenca(this.idAutocadastro, formulario).subscribe({
            next: (response) => {
                this.notify.success('Cadastro criado com sucesso, confira a caixa de entrada do seu email!');
                this.loading = false;
            },
            error: (err) => {
                this.formularioAutocadastro.tratarErroRequisicao(err);
                this.notify.error('Autocadastro indisponível, contate o suporte');
                this.loading = false;
            }
        });
    }
}
