import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientesRoutingModule } from './clientes-routing.module';
import { ClientesComponent } from './clientes.component';
import { ClientesService } from 'src/app/shared/api/clientes.service';
import { ClienteFormComponent } from './cliente-form/cliente-form.component';
import { EditComponentModule } from 'src/app/shared/components/edit-component/edit-component.module';
import { TableListModule } from 'src/app/shared/components/table-list/table-list.module';
import { AdmEquipeService } from 'src/app/shared/api/adm-equipe.service';
import { FormsEquipesClienteComponent } from './forms-equipes-cliente/forms-equipes-cliente.component';
import { AdmSkinService } from 'src/app/shared/api/adm-skin.service';
import { AutocadastroModule } from '../autocadastro/autocadastro.module';
import { AdmAutocadastroService } from 'src/app/shared/api/adm-autocadastro.service';

@NgModule({
    declarations: [ClientesComponent, ClienteFormComponent, FormsEquipesClienteComponent],
    imports: [
        CommonModule,
        ClientesRoutingModule,
        EditComponentModule,
        TableListModule,
        AutocadastroModule
    ],
    providers: [ClientesService, AdmEquipeService, AdmSkinService, AdmAutocadastroService]
})
export class ClientesModule {}
