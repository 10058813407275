import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILicenca, LicencasService } from 'src/app/shared/api/licencas/licencas.service';
import { IMaterial } from 'src/app/shared/api/testes/adm-materiais.service';
import { JogadaService } from 'src/app/shared/api/testes/jogada.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { PessoalMateriaisService } from 'src/app/shared/api/testes/pessoal-materiais.service';
import { TestesService } from 'src/app/shared/api/testes/testes-cliente.service';
import { ConstantsWidthHeightMidia } from 'src/app/shared/components/card-midia/card-midia-constants';
import { ActionToolbar } from 'src/app/shared/components/toolbar/toolbar.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';
import { clonarObjeto } from 'src/app/shared/functions/objects-functions';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

interface ILicencaMateriais {
    licenca: ILicenca;
    materiais: IMaterial[];
}

export interface IGrupoLicenca {
    idGrupo: string;
    licencasMateriais: ILicencaMateriais[]
}

@Component({
    selector: 'app-grupo-testes',
    templateUrl: './grupo-testes.component.html',
    styleUrls: ['./grupo-testes.component.scss']
})
export class GrupoTestesComponent implements OnInit {
    @ViewChildren('grupoContainer') grupoContainers!: QueryList<ElementRef>;

    loading = false;
    grupos: IGrupoLicenca[] = [];
    actionsToolbar: ActionToolbar[] = [];
    titulo = '';

    idTestePrincipal!: string;
    constantsWidthHeightMidia = ConstantsWidthHeightMidia;
    urlCapaMaterial: string | null = null;

    constructor(
        public licencasService: LicencasService,
        private dialog: DialogService,
        private router: Router,
        private jogadaService: JogadaService,
        private route: ActivatedRoute,
        private materiaisService: PessoalMateriaisService,
        private authService: AuthService,
        private testesService: TestesService,
        private midiasService: MidiasService
    ) {}

    ngOnInit(): void {
        this.authService.setTheme('dark');

        this.idTestePrincipal = this.route.snapshot.paramMap.get('id')!;

        this.listarLicencas();
        this.obterCapaMateriais();
    }

    private listarLicencas(params: any = { pagina: 1, tamanho: 999 }) {
        this.loading = true;
        params['teste'] = this.idTestePrincipal;

        this.licencasService.get(params).subscribe({
            next: (response) => {
                this.loading = false;
                const licencas = response.resultados;
                this.obterNomeTestePrincipal(licencas);
                this.listarMateriaislicencas(licencas);
            }
        });
    }

    private obterNomeTestePrincipal(licencas: ILicenca[]) {
        const licenca = licencas.find((licenca) => licenca.teste.uuid == this.idTestePrincipal);
        this.titulo = licenca?.teste.nome || '';
    }

    private separarLicencasEmGrupos(licencas: ILicenca[], materiais: IMaterial[]) {
        this.grupos = [];

        licencas.forEach((licenca) => {
            let grupo: IGrupoLicenca | undefined = this.grupos.find((grupo) => grupo.idGrupo == licenca.grupo);

            if (!grupo) {
                grupo = {
                    idGrupo: licenca.grupo,
                    licencasMateriais: []
                };

                this.grupos.push(grupo);
            }

            let materiaisTeste = materiais.filter(
                (material) => material.teste == licenca.teste.uuid
            );

            grupo.licencasMateriais.push({ licenca: licenca, materiais: materiaisTeste });
        });

        this.ordenarLicencasPorIdAnterior();
    }

    private ordenarLicencasPorIdAnterior() {

        this.grupos.forEach(grupo => {
            const licencasMateriaisOrdenadas: ILicencaMateriais[] = [];

            const primeiraLicenca = grupo.licencasMateriais.find(licencaMateriais => licencaMateriais.licenca.teste_antecessor == null);

            let atual = primeiraLicenca;

            if(!atual) {
                // por algum motivo não tem nenhuma licença com teste_antecessor null
                // por exemplo, foi dado a ele acesso a segunda licença e não a primeira
                // encontrar a licença principal
                atual = this.encontrarPrimeiraLicencaEmCasoDeTodasPossuiremAntecessor(grupo.licencasMateriais);
            }

            while(atual){
                licencasMateriaisOrdenadas.push(clonarObjeto(atual));
                atual = grupo.licencasMateriais.find(licencaMat => licencaMat.licenca.teste_antecessor?.uuid == atual!.licenca.teste.uuid);
            }

            grupo.licencasMateriais = licencasMateriaisOrdenadas;
        })
    }

    private encontrarPrimeiraLicencaEmCasoDeTodasPossuiremAntecessor(licencasMateriais: ILicencaMateriais[]){
        const antecessores = new Set(licencasMateriais.map(l => l.licenca.teste_antecessor!.uuid));
        return licencasMateriais.find(l => !antecessores.has(l.licenca.teste.uuid));
    }

    private listarMateriaislicencas(licencas: ILicenca[]) {
        this.materiaisService.get({ teste: this.idTestePrincipal }).subscribe({
            next: (response) => {
                this.separarLicencasEmGrupos(licencas, response.resultados);
            }
        });
    }

    /**
     * Para obter a capa dos materiais, preciso obter os detalhes do teste.
     * Nos detalhes do teste virá o id da trilha.
     * Usarei o id da trilha para obter as midias da trilha. 
     * A mídia das trilhas de posição 1 (segunda) conterá  a capa dos materiais
     */
    private obterCapaMateriais(){
        const sub = this.testesService.detail(this.idTestePrincipal).subscribe({
            next: (response) => {
                sub.unsubscribe();
                const idTrilha = response.trilha!;

                const subMidia = this.midiasService.getMidiaCapaTrilha(idTrilha).subscribe({
                    next: (responseMidia) => {
                        subMidia.unsubscribe();
                        this.urlCapaMaterial = responseMidia.resultados[1].url!;
                    }
                })
            }
        })
    }

    iniciarJogadaClick = (licenca: ILicenca) => {
        if (licenca.data_inicio) {
            this.iniciarJogada(licenca);
            return;
        }

        this.dialog
            .confirm('Confirmar', 'Deseja realmente iniciar o jogo agora?')
            .subscribe((confirmado) => {
                if (confirmado) {
                    this.iniciarJogada(licenca);
                }
            });
    };

    visualizarRelatorio = (licenca: any) => {
        const url = ConstantsRotasDoSistema.RELATORIOS + '/' + licenca.uuid;
        this.router.navigate([url]);
    };

    iniciarJogada(licenca: ILicenca) {
        this.jogadaService.getRodadaAtual(licenca.uuid).subscribe({
            next: (response) => {
                this.goToJogada(licenca.uuid);
            },
            error: () => {
                this.jogadaService.iniciarJogada(licenca.uuid).subscribe({
                    next: (response) => {
                        this.goToJogada(licenca.uuid);
                    }
                });
            }
        });
    }

    goToJogada(id: string) {
        this.router.navigate([
            ConstantsRotasDoSistema.DEFAULT_PATH + ConstantsRotasDoSistema.PLAY + `/${id}`
        ]);
    }

    scrollLeft(index: number) {
        const container = this.grupoContainers.toArray()[index]?.nativeElement;
        if (container) {
            container.scrollBy({
                left: -300,
                behavior: 'smooth' // Scroll suave
            });
        }
    }
    
    scrollRight(index: number) {
        const container = this.grupoContainers.toArray()[index]?.nativeElement;
        if (container) {
            container.scrollBy({
                left: 300,
                behavior: 'smooth' // Scroll suave
            });
        }
    }
}
