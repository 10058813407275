import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router } from '@angular/router';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { RoleEnum } from 'src/app/app-navigation';
import { IAcesso } from '../api/acessos.service';
import { TermosDeUsoService } from '../api/termos-de-uso.service';

export interface IUser {
    email: string;
    first_name: string;
    last_name: string;
    id: string;
    username: string;
}

const defaultPath = '/';
const loginPath = '/login';
const acessoPath = '/acessos';
const termosPath = 'aceite-de-termos';

@Injectable()
export class AuthService implements OnInit {

    private acessoSubject = new BehaviorSubject<IAcesso>({} as IAcesso);
    acessoSubject$ = this.acessoSubject.asObservable();

    private _isTelaAutocadastro = false;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private termosDeUsoService: TermosDeUsoService
    ) {}

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this._isTelaAutocadastro = false;
        })
    }

    get loggedIn(): boolean {
        const user = localStorage.getItem('user');
        return !!user;
    }

    get perfilSelecionado(): boolean {
        const acesso = localStorage.getItem('acesso');
        return !!acesso;
    }

    get isTelaDeJogada(): boolean {
        const currentPath = this.router.url;
        return currentPath.includes('play');
    }

    get isTelaAutocadastro(): boolean {
        const currentPath = this.router.url;
        return currentPath.includes('autocadastro');
    }

    get showTermosDeUso(): boolean {
        const showTermos = localStorage.getItem('showTermos');
        if (showTermos) return JSON.parse(showTermos);
        else return false;
    }

    setUser(user: IUser) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    setShowAceiteTermos() {
        localStorage.setItem('showTermos', JSON.stringify(true));
    }

    removeShowAceiteTermos() {
        localStorage.removeItem('showTermos');
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    setAcesso(acesso: string) {
        localStorage.setItem('acesso', acesso);
    }

    setTipoAcesso(tipoAcesso: string) {
        localStorage.setItem('tipoAcesso', tipoAcesso);
    }

    setDetalheAcesso(detalheAcesso: any){
        localStorage.setItem('detalheAcesso', JSON.stringify(detalheAcesso));
    }

    getToken() {
        return localStorage.getItem('token');
    }

    getAcesso() {
        return localStorage.getItem('acesso');
    }

    getTipoAcesso() {
        return localStorage.getItem('tipoAcesso');
    }

    getDetalheAcesso(){
        return JSON.parse(localStorage.getItem('detalheAcesso') || '');
    }

    logIn(email: string, password: string) {
        return new Observable((observer) => {
            this.apiService
                .post('autenticar', { username: email, senha: password })
                .subscribe({
                    next: (response: any) => {
                        this.setToken(response.token);
                        this.setUser(response.user);

                        observer.next(response);
                    },
                    error: (e: any) => {
                        observer.error(e);
                    },
                    complete: () => {
                        observer.complete();
                    }
                });
        });
    }

    public createAccount(usuario: any) {
        return this.apiService.post('acessos-pessoais', usuario);
    }

    public changePassword(senha: string, token: string) {
        return new Observable((observer) => {
            this.apiService
                .post('alterar-senha', {
                    token,
                    senha
                })
                .subscribe({
                    next: (response) => {
                        observer.next(response);
                    },
                    error: (error) => {
                        observer.error(error);
                    }
                });
        });
    }

    public sendRecoverEmail(email: string) {
        return new Observable((observer) => {
            this.apiService
                .post('solicitar-alteracao-senha', { email })
                .subscribe(
                    (response) => {
                        observer.next(response);
                    },
                    (error) => {
                        observer.error(error);
                    }
                );
        });
    }

    async logOut() {
        localStorage.clear();
        this.goToLogin();
    }

    getRolesUser() {
        const roles: {
            roleId: string;
            roleName: RoleEnum;
            authority: string;
        }[] = JSON.parse(localStorage.getItem('roles')!);
        return roles.map((role) => role.roleName);
    }

    goToLogin() {
        this.router.navigate([loginPath]);
    }

    private navegarParaDashboard() {
        this.router.navigate(['/']);
    }

    private navegarParaAceiteTermos() {
        this.router.navigate(['/aceite-de-termos']);
    }

    private setarLocalStorageTermos() {
        this.setShowAceiteTermos();
    }

    private salvarDadosDeAcesso(
        token: string,
        acesso: IAcesso
    ) {
        this.setToken(token);
        this.setAcesso(acesso.uuid);
        this.setTipoAcesso(acesso.tipo);
        this.setDetalheAcesso(acesso.detalhe);
        this.acessoSubject.next(acesso);
    }

    private obterAceiteDeTermos(
        token: string,
        acesso: IAcesso
    ) {
        this.termosDeUsoService.get().subscribe({
            next: (responseTermos) => {
                this.salvarDadosDeAcesso(token, acesso);

                if (responseTermos.resultados.length > 0) {
                    this.setarLocalStorageTermos();
                    this.navegarParaAceiteTermos();
                } else {
                    this.navegarParaDashboard();
                }
            }
        });
    }

    public selecionarAcesso(acesso: IAcesso, token: string) {
        if (acesso.tipo == RoleEnum.ADMINISTRADOR) {
            this.navegarParaDashboard();
            this.salvarDadosDeAcesso(token, acesso);
        } else {
            this.setToken(token);
            this.obterAceiteDeTermos(token, acesso);
        }
    }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const isLoggedIn = this.authService.loggedIn;
        const isAcessoSelecionado = this.authService.perfilSelecionado;
        const showTermos = this.authService.showTermosDeUso;

        if (!isLoggedIn) {
            this.router.navigate([loginPath]);
        } else if (!isAcessoSelecionado) {
            this.router.navigate([acessoPath]);
        } else if (showTermos) {
            this.router.navigate([termosPath]);
        }

        return isLoggedIn && isAcessoSelecionado;
    }
}

@Injectable()
export class AuthGuardServiceAceiteTermos implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const isLoggedIn = this.authService.loggedIn;
        const isAcessoSelecionado = this.authService.perfilSelecionado;

        if (!isLoggedIn) {
            this.router.navigate([loginPath]);
        } else if (!isAcessoSelecionado) {
            this.router.navigate([acessoPath]);
        }

        return isLoggedIn && isAcessoSelecionado;
    }
}

@Injectable()
export class AuthGuardServiceAcesso implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const isLoggedIn = this.authService.loggedIn;

        if (!isLoggedIn) {
            this.router.navigate([loginPath]);
        }

        return isLoggedIn;
    }
}

@Injectable()
export class AuthGuardServiceLoginForm implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const isLoggedIn = this.authService.loggedIn;
        const isAcessoSelecionado = this.authService.perfilSelecionado;

        if (isLoggedIn && isAcessoSelecionado) {
            this.router.navigate([defaultPath]);
            return false;
        } else if (isLoggedIn && !isAcessoSelecionado) {
            this.router.navigate([acessoPath]);
            return false;
        }

        return true;
    }
}
