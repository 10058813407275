import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModulosComponent } from './modulos.component';
import { TabelaListagemModule } from "../../shared/components/tabela-listagem/tabela-listagem.module";
import { ModuloService } from 'src/app/shared/api/testes/modulos.service';
import { ModalModule } from "../../shared/components/modal/modal.module";
import { ButtonModule } from "../../shared/components/button/button.module";
import { FormularioDinamicoModule } from "../../shared/components/formulario-dinamico/formulario-dinamico.module";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import { CardBotoesModule } from 'src/app/shared/components/card-botoes/card-botoes.module';
import { RodadasModule } from '../rodadas/rodadas.module';
import { MidiasModule } from "../midias/midias.module";
import { LoaderPageModule } from "../../shared/components/loader-page/loader-page.module";


@NgModule({
    declarations: [
        ModulosComponent
    ],
    providers: [
        ModuloService
    ],
    exports: [
        ModulosComponent
    ],
    imports: [
    CommonModule,
    TabelaListagemModule,
    ModalModule,
    ButtonModule,
    FormularioDinamicoModule,
    MatButtonModule,
    MatIconModule,
    CardBotoesModule,
    MatTabsModule,
    RodadasModule,
    MidiasModule,
    LoaderPageModule
]
})
export class ModulosModule { }
