<mat-drawer-container class="container-menu-lateral" autosize>
    <mat-drawer #drawer [opened]="menuAberto" class="menu-lateral" [mode]="modoMenu">
        <mat-nav-list>
            <mat-list-item class="logo-menu-lateral">
                <img src="assets/img/mindtest_logo.png" height="100px" />
            </mat-list-item>

            <ng-container *ngFor="let route of rotasListar">
                <a
                    [routerLink]="route.path"
                    class="item-menu"
                    [ngClass]="{
                        'rota-selecionada': currentRoute && currentRoute.includes(route.path!)
                    }"
                >
                    <mat-list-item>
                        <mat-icon class="me-2">{{ route.icon }}</mat-icon>
                        {{ route.text }}
                    </mat-list-item>
                </a>
            </ng-container>
        </mat-nav-list>
    </mat-drawer>

    <mat-drawer-content class="pb-3">
        <ng-content></ng-content>
    </mat-drawer-content>
</mat-drawer-container>
