import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UnauthenticatedContentModule } from './shared/components/unauthenticated-content/unauthenticated-content';
import { SideNavOuterToolbarModule } from './shared/components/side-nav-outer-toolbar/side-nav-outer-toolbar.component';
import { AuthService } from './shared/services/auth.service';
import { ScreenService } from './shared/services/screen.service';
import { AppInfoService } from './shared/services/app-info.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AddHeaderInterceptor } from './shared/services/httpInterceptor';


import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationService } from './shared/services/notificationService';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { AcessosModule } from './pages/acessos/acessos.module';
import { HeaderModule } from "./shared/components/header/header.module";
import { ClientesModule } from './pages/clientes/clientes.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DialogModule } from './shared/components/dialog/dialog.module';
import { SkinsModule } from './pages/skins/skins.module';
import { AcessoPessoalModule } from './pages/acesso-pessoal/acesso-pessoal.module';
import { EquipesModule } from './pages/equipes/equipes.module';
import { TestesModule } from './pages/testes/testes.module';
import { AdmLicencasModule } from './pages/adm-licencas/adm-licencas.module';
import { PessoalLicencasModule } from './pages/pessoal-licencas/pessoal-licencas.module';
import { PlayModule } from './pages/play/play.module';
import { TermoDeUsoModule } from './pages/termo-de-uso/termo-de-uso.module';
import { AceiteDeTermosModule } from './pages/aceite-de-termos/aceite-de-termos.module';
import { AutocadastroFormModule } from './pages/autocadastro-form/autocadastro-form.module';
import { RelatoriosModule } from './pages/relatorios/relatorios.module';


@NgModule({
    declarations: [
        AppComponent,
    ],
    providers: [
        AuthService,
        ScreenService,
        AppInfoService,
        NotificationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeaderInterceptor,
            multi: true
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 2500 }
        },
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        UnauthenticatedContentModule,
        SideNavOuterToolbarModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        NgxMaskModule.forRoot({
            dropSpecialCharacters: true
        }),
        MatSnackBarModule,
        HeaderModule,
        DialogModule,

        // Rotas do sistema
        DashboardModule,
        AcessosModule,
        AppRoutingModule,
        ClientesModule,
        SkinsModule,
        AcessoPessoalModule,
        EquipesModule,
        TestesModule,
        AdmLicencasModule,
        PessoalLicencasModule,
        PlayModule,
        TermoDeUsoModule,
        AceiteDeTermosModule,
        AutocadastroFormModule,
        RelatoriosModule
    ],
})
export class AppModule { }
