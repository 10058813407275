<edit-component
    [(entidadeOriginal)]="entidade"
    [resource]="equipeResource"
    [service]="equipeService"
    (entidadeOriginalChange)="changeEntidadeOriginal($event)"
    rota="equipes"
    fieldId="idEquipe"
></edit-component>

<autocadastro
    *ngIf="entidade && showAutocadastro"
    [autocadastroService]="autocadastroService"
    [licencasService]="testesAtivosService"
    [modo]="'adm'"
></autocadastro>
