import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AdmMateriaisService, IMaterial } from 'src/app/shared/api/testes/adm-materiais.service';
import { FormularioDinamicoComponent } from 'src/app/shared/components/formulario-dinamico/formulario-dinamico.component';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import {
    ColumnDataType,
    ConfigurationColumn,
    SelectConfiguration
} from 'src/app/shared/model/resource';
import { CardMidiaMaterialComponent } from '../card-midia-material/card-midia-material.component';
import {
    ActionsTabela,
    TabelaListagemComponent
} from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AdmMidiasService } from 'src/app/shared/api/testes/adm-midias.service';

export interface IMidiaMaterial {
    url?: string;
    formato?: string;
    descricao?: string;
    uuid?: string;
}

@Component({
    selector: 'materiais-curso',
    templateUrl: './materiais-curso.component.html',
    styleUrls: ['./materiais-curso.component.css']
})
export class MateriaisCursoComponent implements OnInit {
    @ViewChild('modalMaterial') modalMaterial!: ModalComponent;
    @ViewChild('formularioMaterial') formularioMaterial!: FormularioDinamicoComponent;
    @ViewChild('cardMidia') cardMidia!: CardMidiaMaterialComponent;
    @ViewChild('tabelaListagemMateriais') tabelaListagemMateriais!: TabelaListagemComponent;

    @Input() id!: string;

    showTabelamMateriais = false;

    modalMaterialData: IDialogData = {
        titulo: 'Material'
    };

    readonly MIDIA_VAZIA = { formato: '' };
    readonly MATERIAL_VAZIO = { uuid: '', descricao: '', nome: '', teste: '' };

    entidadeMaterial: IMaterial = this.MATERIAL_VAZIO;
    midiaModal: IMidiaMaterial = this.MIDIA_VAZIA;

    colunasFormularioMaterial: ConfigurationColumn[] = [
        {
            field: 'nome',
            sizeColumn: 'col-12',
            required: true
        },
        {
            field: 'descricao',
            type: ColumnDataType.TextArea,
            textareaConfiguration: {
                rows: 5
            },
            sizeColumn: 'col-12'
        }
    ];

    actionsTabelaMateriais: ActionsTabela[] = [];
    colunasTabelaMateriais: ConfigurationColumn[] = [
        {
            field: 'nome',
            label: 'Nome'
        },
        {
            field: 'descricao',
            label: 'Descrição'
        }
    ];

    configurationSelectTipoMidia!: SelectConfiguration;
    selectTipoMidia = { id: 1, nome: 'Arquivo' };

    constructor(
        public admMateriaisService: AdmMateriaisService,
        private dialog: DialogService,
        private midiaService: AdmMidiasService
    ) {}

    ngOnInit(): void {
        this.admMateriaisService.setBaseUrl(this.id);
        this.showTabelamMateriais = true;

        this.actionsTabelaMateriais.push({
            hint: 'Editar material',
            icon: 'edit',
            onClick: this.editarMaterial,
            text: 'Editar material',
            type: 'primary'
        });

        this.actionsTabelaMateriais.push({
            hint: 'Excluir material',
            icon: 'delete',
            onClick: this.excluirMaterial,
            text: 'Excluir material',
            type: 'warn'
        });

        this.configurationSelectTipoMidia = {
            displayExpr: 'nome',
            valueExpr: 'id',
            defaultData: [
                { id: 1, nome: 'Arquivo' },
                { id: 2, nome: 'Link' }
            ],
            defaultValue: { id: 1, nome: 'Arquivo' }
        };
    }

    abrirModalCadastrarMidia() {
        this.entidadeMaterial = this.MATERIAL_VAZIO;
        this.selectTipoMidia = { id: 1, nome: 'Arquivo' };
        this.midiaModal = this.MIDIA_VAZIA;
        this.cardMidia?.limparFile();
        this.modalMaterial.open();
    }

    private editarMaterial = (entity: IMaterial) => {
        this.entidadeMaterial = entity;
        this.cardMidia?.limparFile();
        this.cardMidia.carregarMaterial(this.entidadeMaterial.uuid);
        this.modalMaterial.open();
    };

    onSaveMaterial = new Observable((observer) => {
        const formValid = this.formularioMaterial.isValid();
        if (!formValid) {
            observer.error();
            observer.complete();
        } else {
            const formulario = this.formularioMaterial.getDadosFormularioSalvar();
            formulario['teste'] = this.id;

            let request = this.admMateriaisService.create(formulario);
            if (this.entidadeMaterial.uuid) {
                request = this.admMateriaisService.update(this.entidadeMaterial.uuid, formulario);
            }

            request.subscribe({
                next: (response) => {
                    this.cardMidia.salvarMidia(response.uuid).subscribe({
                        next: (midiaSalva) => {
                            this.entidadeMaterial = this.MATERIAL_VAZIO;
                            this.midiaModal = this.MIDIA_VAZIA;
                            this.tabelaListagemMateriais.listarEntidades();
                            observer.next();
                            observer.complete();
                        },
                        error: (err) => {
                            observer.error();
                            observer.complete();
                        }
                    });
                },
                error: (err) => {
                    this.formularioMaterial.tratarErroRequisicao(err);
                    observer.error(err);
                    observer.complete();
                }
            });
        }
    });

    private excluirMaterial = (entity: IMaterial) => {
        this.dialog
            .confirm(
                'Confirmar Exclusão',
                'Tem certeza que deseja excluir o material atual e suas respectivas midias?'
            )
            .subscribe({
                next: (result: boolean) => {
                    if (result) {
                        const params = { obj_referencia: entity.uuid, content_type: 'material' };
                        this.midiaService.get(params).subscribe({
                            next: (response) => {
                                response.resultados.forEach((midia) => {
                                    this.midiaService.delete(midia.uuid, params).subscribe();
                                });

                                this.admMateriaisService
                                    .delete(entity.uuid!)
                                    .subscribe((response) => {
                                        this.tabelaListagemMateriais.listarEntidades();
                                    });
                            }
                        });
                    }
                }
            });
    };

    onMidiaChange(midia: IMidiaMaterial) {
        if(!midia) return;
        if (midia.formato == 'link') {
            this.selectTipoMidia = { id: 2, nome: 'Link' };
        } else {
            this.selectTipoMidia = { id: 1, nome: 'Arquivo' };
        }
    }
}
