<button-component
    [routerLink]="routerLink"
    color="primary"
    [icon]="icon"
    [text]="text"
    [loading]="loading"
    [onClick]="onClick"
    [matTooltip]="tooltip"
>
    <ng-content></ng-content>
</button-component>
