<div class="d-flex mt-2 justify-content-end">
	<primary-button (click)="abrirModalCriarRodada()"
		icon="add"
		text="Adicionar {{rodadaResouce.nomeEntidade.singular}}"></primary-button>
</div>

<div class="mt-2">
	<card-botoes *ngFor="let rodada of listaRodadas"
		#card
		[title]="rodada.nome"
		[cardOpened]="false"
		[entity]="rodada"
		[actions]="actions">

		<mat-tab-group #grupoTab (selectedTabChange)="grupoTab.selectedIndex = $event.index">
			<mat-tab label="Mídias">
				<midias *ngIf="card.cardOpened" content_type="rodada" [obj_referencia]="rodada.uuid"></midias>
			</mat-tab>
			<mat-tab label="Perguntas">
				<perguntas *ngIf="grupoTab.selectedIndex == 1" [idModulo]="idModulo" [idTeste]="idTeste" [idRodada]="rodada.uuid"></perguntas>
			</mat-tab>
		</mat-tab-group>
	</card-botoes>
</div>


<modal #modal
	[data]="dataModal"
	[onSave]="onSaveRodada()">
	<formulario-dinamico #formularioRodada
		*ngIf="showFormModal"
		[colunas]="colunasFormularioRodada"
		[(entidade)]="entidadeRodada"></formulario-dinamico>
</modal>
