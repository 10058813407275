import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ButtonComponent } from '../button/button.component';
import { PrimaryButtonComponent } from '../button/primary-button/primary-button.component';

export interface IDialogData {
    titulo: string,
    labelCancel?: String,
    labelOk?: string
}

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})
export class ModalComponent {
    @ViewChild(TemplateRef) dialogTemplate!: TemplateRef<any>;
    @Input() data!: IDialogData;
    @Input() onSave!: Observable<any>;
    @Input() showBtnConfirmar = true;
    @Input() showBtnCancelar = true;

    constructor(
        private dialogRef: MatDialogRef<ModalComponent>,
        readonly dialog: MatDialog
    ) {}

    open(config?: MatDialogConfig) {
        if(!config) config = {width: '80%'};

        this.dialogRef = this.dialog.open(this.dialogTemplate, config);
        return this.dialogRef;
    }

    close(confirmado: boolean){
        this.dialogRef.close(confirmado);
    }

    cancelar(){
        this.close(false);
    }

    concluir(button: PrimaryButtonComponent){
        button.loading = true;
        //TODO: Alterar botao de save para loading
        this.onSave.subscribe({
            next: (response) => {
                button.loading = false;
                //TODO: Alterar botao de loading para save
                this.close(true);
            },
            error: (err: any) => {
                button.loading = false;
            }
        })
    }
}
