export interface ISexo {
    nome: string,
    id: string
}

export const ListaSexos: ISexo[] = [
    {id: 'M', nome: 'Masculino'},
    {id: 'F', nome: 'Feminino'},
    {id: 'O', nome: 'Outro'},
    {id: 'N', nome: 'Não responder'},
]
