import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'button-component',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

    @Input() icon: string | null = null;
    @Input() text: string | null = null;
    @Input() color: 'primary' | 'accent' | 'warn' | 'basic' = 'basic';
    @Input() tooltip: string = '';
    @Input() routerLink!: string | null;
    @Input() loading = false;
    @Input() onClick?: Observable<any>;


    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    buttonClick(){
        if(this.routerLink){
            this.router.navigate([this.routerLink]);
        }
        if(this.onClick){
            this.subscribeClickFunction();
        }
    }

    subscribeClickFunction(){
        this.loading = true;
        this.onClick?.subscribe({
            next: () => {
                this.loading = false;
            },
            error: () => {
                this.loading = false;
            }
        })
    }
}
