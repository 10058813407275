import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";


export interface IMidia extends IResponse {
    obj_referencia: string,
    content_type: string,
    nome?: string,
    url?: string,
    formato?: string,
    posicao?: number
}

@Injectable()
export class AdmMidiasService extends TemplateApiService<IMidia> {

    public override setBaseUrl(){
        this.baseUrl = `admin/midias`;
    }
}
