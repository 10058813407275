import { Component, OnInit } from '@angular/core';
import { AdmLicencasService } from 'src/app/shared/api/licencas/adm-licencas.service';
import { licencasResource } from './licencas.resource';

@Component({
    selector: 'app-adm-licencas',
    templateUrl: './adm-licencas.component.html',
    styleUrls: ['./adm-licencas.component.css']
})
export class AdmLicencasComponent implements OnInit {
    licencasResource = licencasResource;

    constructor(public admLicencasService: AdmLicencasService) { }

    ngOnInit(): void {
    }

}
