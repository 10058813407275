<div class="d-grid justify-content-end">
    <primary-button
        (click)="abrirModalCadastrarMidia()"
        icon="add"
        text="Adicionar material"
        class="m-auto mt-2"
    ></primary-button>
</div>

<div class="mt-3">
    <tabela-listagem
        *ngIf="showTabelamMateriais"
        #tabelaListagemMateriais
        [actions]="actionsTabelaMateriais"
        [colunas]="colunasTabelaMateriais"
        [service]="admMateriaisService"
    ></tabela-listagem>
</div>

<modal #modalMaterial [data]="modalMaterialData" [onSave]="onSaveMaterial">
    <div class="row">
        <div class="col-lg-6">
            <formulario-dinamico
                #formularioMaterial
                [colunas]="colunasFormularioMaterial"
                [(entidade)]="entidadeMaterial"
            ></formulario-dinamico>

            <div class="ps-2 row">
                <div class="row">
                    <small>Tipo de mídia:</small>
                    <div class="form-control">
                        <select-padrao
                            [selectConfiguration]="configurationSelectTipoMidia"
                            [(value)]="selectTipoMidia"
                        ></select-padrao>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <card-midia-material
                #cardMidia
                [midia]="midiaModal"
                [obj_referencia]="entidadeMaterial.uuid"
                [tipoMidia]="selectTipoMidia.id"
                (midiaChange)="onMidiaChange($event)"
            ></card-midia-material>
        </div>
    </div>
</modal>
