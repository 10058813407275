import { Component, OnInit } from '@angular/core';
import { skinResource } from '../skin.resource';
import { SkinService } from 'src/app/shared/api/skins.service';

@Component({
    selector: 'app-form-skin',
    templateUrl: './form-skin.component.html',
    styleUrls: ['./form-skin.component.css']
})
export class FormSkinComponent implements OnInit {

    skinResource = skinResource;

    constructor(public skinService: SkinService) { }

    ngOnInit(): void {
    }

}
