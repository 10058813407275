import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AdmCsvService } from 'src/app/shared/api/licencas/adm-csv.service';
import { ActionToolbar } from 'src/app/shared/components/toolbar/toolbar.component';
import { NotificationService } from 'src/app/shared/services/notificationService';

@Component({
    selector: 'importar-csv-licencas',
    templateUrl: './importar-csv-licencas.component.html',
    styleUrls: ['./importar-csv-licencas.component.scss']
})
export class ImportarCsvLicencasComponent implements OnInit {
    actions: ActionToolbar[] = [];

    arquivoImportar!: File;

    constructor(
        private admCsvService: AdmCsvService,
        private notify: NotificationService
    ) {}

    ngOnInit(): void {
        this.configurarActions();
    }

    private configurarActions() {
        this.actions.push({
            icon: 'download',
            text: 'Baixar Modelo',
            type: 'accent',
            onClick: this.baixarModelo()
        });

        this.actions.push({
            icon: 'upload',
            text: 'Realizar Importação',
            type: 'primary',
            onClick: this.realizarImportacao()
        });
    }

    private baixarModelo() {
        return new Observable((observer) => {
            this.admCsvService.obterModeloCSV();
            observer.next();
            observer.complete();
        });
    }

    private realizarImportacao() {
        return new Observable((observer) => {
            if (!this.arquivoImportar) {
                const error = 'Selecione um arquivo antes de realizar a importação';
                this.notify.error(error);
                observer.error(error);
                observer.complete();
            } else {
                this.admCsvService.importarCSV(this.arquivoImportar).subscribe({
                    next: (response) => {
                        // TODO: salvar id da tarefa em background para exibir loading de arquivo csv 
                        // sugestão: mostrar na barra header: (loader) 1 arquivo sendo importado
                        observer.next();
                    },
                    error: (err) => {
                        this.notify.error('Ocorreu um erro ao tentar importar o arquivo. Consulte o administrador do sistema');
                        observer.error(err);
                    },
                    complete: () => {
                        observer.complete();
                    }
                })
            }
        });
    }
}
