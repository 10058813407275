import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {

    @Input() color:string = 'primary';

    @Input() value: boolean = false;

    @Output() valueChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    checkChange(event: any){
        this.valueChange.emit(event.checked);
    }
}
