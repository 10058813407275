<div *ngIf="midias && midias.length > 0" class="carousel-container">

    <ng-container *ngFor="let midia of midias; let i = index">
        <img
            *ngIf="imageFormats.includes(midia.formato!.toLocaleLowerCase())"
            [src]="midia.url"
            [ngClass]="{ 'image-active': selectedIndex === i }"
            class="imagem-carousel"
        />
        <video
            *ngIf="videoFormats.includes(midia.formato!.toLocaleLowerCase())"
            [ngClass]="{ 'image-active': selectedIndex === i }"
            class="imagem-carousel"
            controls
        >
            <source [src]="midia.url" />
        </video>
    </ng-container>

    <div *ngIf="indicators && midias.length > 1" class="carousel-dot-container">
        <span
            *ngFor="let dot of midias; let i = index"
            [ngClass]="{ active: selectedIndex === i }"
            (click)="selectImage(i)"
            class="dot"
        >
        </span>
    </div>

    <button
        *ngIf="controls && midias.length > 1"
        (click)="onPrevClick()"
        class="btn-carousel btn-prev"
    >
        <mat-icon class="icon-carousel icon-prev">arrow_back_ios</mat-icon>
    </button>

    <button
        *ngIf="controls && midias.length > 1"
        (click)="onNextClick()"
        class="btn-carousel btn-next"
    >
        <mat-icon class="icon-carousel icon-next">arrow_forward_ios</mat-icon>
    </button>
</div>
