import { CommonModule } from '@angular/common';
import { Component, NgModule, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationService } from 'src/app/shared/services/notificationService';

const notificationText =
    'Mandamos um código para alterar sua senha. Cheque sua caixa de entrada do e-mail.';

@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnDestroy {
    loading = false;
    formData: any = {};
    subscription: any;

    constructor(
        private authService: AuthService,
        private notify: NotificationService
    ) {}

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    async onSubmit(e: Event) {
        e.preventDefault();
        const { username } = this.formData;
        this.loading = true;

        this.subscription = this.authService
            .sendRecoverEmail(username)
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.notify.success(notificationText);
                },
                error: (error) => {
                    this.loading = false;
                    this.notify.error(error.error.erro);
                }
            });
    }
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ],
    declarations: [ResetPasswordFormComponent],
    exports: [ResetPasswordFormComponent]
})
export class ResetPasswordFormModule {}
