import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardMidiaComponent } from './card-midia.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { DialogService } from '../../services/dialog.service';
import { MatIconModule } from '@angular/material/icon';
import { AdmMidiasService } from '../../api/testes/adm-midias.service';



@NgModule({
    declarations: [
        CardMidiaComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule
    ],
    exports: [
        CardMidiaComponent
    ],
    providers: [
        AdmMidiasService,
        DialogService
    ]
})
export class CardMidiaModule { }
