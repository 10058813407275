import { getListaEscolaridadesDisponiveis, getNomeEscolaridadeFromValue } from "src/app/shared/model/escolaridade.model";
import {ColumnDataType, Resource} from "../../shared/model/resource";
import { dateToEnglishDateString, englishDateStringToPortugueseDateString } from "src/app/shared/functions/data-transform";
import { ListaSexos } from "src/app/shared/model/sexo.model";
import { formatarCPF } from "src/app/shared/functions/format-functions";

export const acessoPessoalResource: Resource = {
    disableDelete: true,
    disableEdit: true,
    disableNew: true,
    nomeEntidade: {
        singular: 'Acesso Pessoal',
        plural: 'Acessos Pessoais'
    },
    fieldPk: 'uuid',
    route: {
        url: 'acesso-pessoal',
    },
    colunas: [
        {
            field: 'username',
            label: 'E-mail',
            validation: [{type: 'email'}],
            required: true,
        },
        {
            field: 'senha',
            label: 'Senha',
            type: ColumnDataType.Password,
            required: true,
            unAvailableOnList: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'nome',
            label: 'Nome',
            required: true,
        },
        {
            field: 'cpf',
            label: 'CPF',
            required: true,
            calculateDisplayValue: (value) => value ? formatarCPF(value) : ''
        },
        {
            field: 'sexo',
            label: 'Sexo',
            required: true,
            type: ColumnDataType.Select,
            selectConfiguration: {
                defaultData: ListaSexos,
                displayExpr: 'nome',
                valueExpr: 'id'
            },
            calculateDisplayValue: (value: string) => {
                if(!value) return '';
                return ListaSexos.find(sexo => sexo.id == value)?.nome;
            },
        },
        {
            field: 'data_de_nascimento',
            label: 'Nascimento',
            type: ColumnDataType.Date,
            required: true,
            getValueBeforSave: dateToEnglishDateString,
            calculateDisplayValue: (value) => value ? englishDateStringToPortugueseDateString(value) : ''
        },
        {
            field: 'telefone',
            label: 'Telefone',
            required: true,
        },
        {
            field: 'escolaridade',
            label: 'Escolaridade',
            type: ColumnDataType.Select,
            selectConfiguration: {
                defaultData: getListaEscolaridadesDisponiveis(),
                displayExpr: 'nome',
                valueExpr: 'id',
            },
            calculateDisplayValue: (value) => value ? getNomeEscolaridadeFromValue(value) : ''
        },
        {
            field: 'empregabilidade',
            label: 'Empregabilidade',
            required: false,
            type: ColumnDataType.Checkbox,
            calculateDisplayValue: (value) => value ? 'Sim' : 'Não'
        },
    ],
    actions: [],
    availableReferences: []
}
