<container-padrao>
    <card class="shadow pt-3">
        <div class="mb-2 d-flex justify-content-between">
            <h2>Listagem de {{ resource.nomeEntidade.plural }}</h2>

            <primary-button
                *ngIf="!resource.disableNew"
                (click)="onClickNovo()"
                text="Adicionar {{ resource.nomeEntidade.singular }}"
                icon="playlist_add"
            ></primary-button>
        </div>

        <tabela-listagem
            #tabela
            [colunas]="resource.colunas"
            [actions]="actionsTabela"
            [valueExpr]="fieldPk"
            [service]="service"
        ></tabela-listagem>
    </card>
</container-padrao>
