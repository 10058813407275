<form [formGroup]="parentFormGroup">
    <mat-form-field appearance="fill">
        <mat-label>{{coluna.label || coluna.field}}</mat-label>

        <input matInput
            [formControlName]="coluna.field"
            [(ngModel)]="configurationColor" [ngxMatColorPicker]="picker"
            [disabled]="false" [name]="coluna.field"
            (colorChange)="changeColor($event)">

        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
        <ngx-mat-color-picker #picker [touchUi]="true"></ngx-mat-color-picker>
    </mat-form-field>
</form>
