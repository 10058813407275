import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '../../services/notificationService';

@Component({
    selector: 'input-file-area',
    templateUrl: './input-file-area.component.html',
    styleUrls: ['./input-file-area.component.scss']
})
export class InputFileAreaComponent implements OnInit {

    @Input() accept?: string;
    @Input() multiple = false;

    @Input() file!: File;

    @Output() fileChange = new EventEmitter();

    constructor(private notify: NotificationService) {}

    ngOnInit(): void {}

    onDragOver(event: any) {
        event.preventDefault();
        event.currentTarget?.classList.add('dragging');
    }

    onDragLeave(event: any) {
        event.preventDefault();
        event.currentTarget?.classList.remove('dragging');
    }

    onDrop(event: any) {
        event.preventDefault();
        event.currentTarget?.classList.remove('dragging');
        const files = event.dataTransfer?.files;
        if (files && files[0]) this.selecionarArquivo(files[0]);
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        const files = input.files;
        if (files && files[0]) this.selecionarArquivo(files[0]);
    }

    selecionarArquivo(arquivo: File) {
        this.validarArquivo(arquivo);
        this.file = arquivo;
        this.fileChange.emit(arquivo);
    }

    private validarArquivo(arquivo: File){
        const formatoArquivo: string = arquivo.name.split('.').at(-1) || '';

        if(this.accept && !this.accept.includes(formatoArquivo)){
            this.notify.error('O formato de arquivo não é válido');
            throw new Error('Formato de arquivo inválido');
        }
    }
}
