import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';


@Component({
    selector: 'app-single-card',
    templateUrl: './single-card.component.html',
    styleUrls: [
        './single-card.component.css'
    ]
})
export class SingleCardComponent {
    @Input()
    title!: string;

    @Input()
    description!: string;

    constructor() { }
}

@NgModule({
    imports: [CommonModule, MatCardModule],
    exports: [SingleCardComponent],
    declarations: [SingleCardComponent]
})
export class SingleCardModule {

}
