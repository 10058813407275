import { Observable } from "rxjs";
import { ILicenca } from "./licencas.service";
import { PaginatedResponse } from "../pagination.model";
import { TemplateApiService } from "../template-api.service";
import { Injectable } from "@angular/core";


@Injectable()
export class LicencasDisponiveisAdmService extends TemplateApiService<ILicenca> {

    private params:any = {};

    public override setBaseUrl(idCliente: string, params = {}): void {
        this.baseUrl = `admin/acessos-clientes/${idCliente}/licencas-disponiveis`;
        this.params = params;
    }

    override get(params?: any): Observable<PaginatedResponse<ILicenca>> {
        this.params.pagina = params?.pagina;
        this.params.tamanho = params?.tamanho;
        return this.apiService.get(this.getBaseUrl(), this.params) as Observable<PaginatedResponse<ILicenca>>;
    }
}
