import { Injectable } from "@angular/core";
import { TemplateApiService } from "../template-api.service";
import { IMaterial } from "./adm-materiais.service";
import { Observable } from "rxjs";

@Injectable()
export class PessoalMateriaisService extends TemplateApiService<IMaterial> {

    public override setBaseUrl(){
        this.baseUrl = `materiais`;
    }

    override create(body: any): Observable<IMaterial> {
        throw new Error('Perfil pessoal não pode criar material');
    }

    override delete(id: string, params?: {}): Observable<void> {
        throw new Error('Perfil pessoal não pode deletar material');
    }

    override update(id: string, body: any): Observable<IMaterial> {
        throw new Error('Perfil pessoal não pode atualizar material');
    }
}
