import { Component, OnInit } from '@angular/core';
import { AdmLicencasService } from 'src/app/shared/api/licencas/adm-licencas.service';
import { licencasResource } from './licencas.resource';
import { IFilterComponentConfiguration } from 'src/app/shared/components/filter-component/filter-component.component';
import { ColumnDataType } from 'src/app/shared/model/resource';
import { TestesAtivosService } from 'src/app/shared/api/testes/testes.service';
import { dateToEnglishDateString } from 'src/app/shared/functions/data-transform';

@Component({
    selector: 'app-adm-licencas',
    templateUrl: './adm-licencas.component.html',
    styleUrls: ['./adm-licencas.component.css']
})
export class AdmLicencasComponent implements OnInit {
    licencasResource = licencasResource;

    filterConfiguration!: IFilterComponentConfiguration;

    entidade: any = {};

    constructor(
        public admLicencasService: AdmLicencasService,
        public testesAdmService: TestesAtivosService
    ) {}

    ngOnInit(): void {
        this.configureFilters();
    }

    private configureFilters() {
        this.filterConfiguration = {
            colunas: [
                {
                    field: 'teste',
                    label: 'Curso',
                    type: ColumnDataType.Lookup,
                    lookupConfiguration: {
                        colunas: [
                            {
                                field: 'nome',
                                label: 'Nome',
                                required: true
                            },
                            {
                                field: 'descricao',
                                label: 'Descrição',
                                required: true
                            }
                        ],
                        fieldName: 'nome',
                        fieldPk: 'uuid',
                        instanciaService: this.testesAdmService
                    },
                    sizeColumn: 'col-xl-4'
                },
                {
                    field: 'data_inicio_de',
                    label: 'Data início (de)',
                    type: ColumnDataType.Date,
                    sizeColumn: 'col-xl-2 col-lg-3 col-md-4',
                    getValueBeforSave: dateToEnglishDateString
                },
                {
                    field: 'data_inicio_ate',
                    label: 'Data início (até)',
                    type: ColumnDataType.Date,
                    sizeColumn: 'col-xl-2 col-lg-3 col-md-4',
                    getValueBeforSave: dateToEnglishDateString
                },
                {
                    field: 'data_fim_de',
                    label: 'Data fim (de)',
                    type: ColumnDataType.Date,
                    sizeColumn: 'col-xl-2 col-lg-3 col-md-4',
                    getValueBeforSave: dateToEnglishDateString
                },
                {
                    field: 'data_fim_ate',
                    label: 'Data fim (até)',
                    type: ColumnDataType.Date,
                    sizeColumn: 'col-xl-2 col-lg-3 col-md-4',
                    getValueBeforSave: dateToEnglishDateString
                }
            ],
            tratarValoresFiltrar: ($event: any) => {
                const filtro: any = {};
                if (!$event) return filtro;
                if ($event.teste) filtro['teste'] = $event.teste.uuid;
                if ($event.data_inicio_de) filtro['data_inicio_de'] = $event.data_inicio_de;
                if ($event.data_inicio_ate) filtro['data_inicio_ate'] = $event.data_inicio_ate;
                if ($event.data_fim_de) filtro['data_fim_de'] = $event.data_fim_de;
                if ($event.data_fim_ate) filtro['data_fim_ate'] = $event.data_fim_ate;
                return filtro;
            }
        };
    }
}
