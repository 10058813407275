<container-padrao>
    <card title="Termos de uso e privacidade">
        <ng-container *ngFor="let termo of listaTermos">
            <div class="d-flex">
                <div class="me-2">
                    <checkbox [(value)]="termo.aceito"></checkbox>
                </div>

                <div class="mb-4">
                    {{ termo.texto }}
                </div>
                <hr />
            </div>
        </ng-container>

        <div class="mt-3 d-grid justify-content-center">
            <primary-button (click)="aceitarTermosSelecionados()">Aceitar termos</primary-button>
        </div>
    </card>
</container-padrao>