export const clonarObjeto: any = (obj: any) => {
    // Verifica se o valor é null ou não é um objeto (tipo primitivo)
    if (obj === null || typeof obj !== 'object') {
        return obj; // Retorna o valor diretamente
    }

    // Verifica se o objeto é um array
    if (Array.isArray(obj)) {
        return obj.map(clonarObjeto); // Clona cada elemento do array recursivamente
    }

    // Cria um novo objeto com o mesmo protótipo do objeto original
    const clonedObj = Object.create(Object.getPrototypeOf(obj));

    // Copia cada propriedade e método, verificando se também são objetos e clonando recursivamente
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        clonedObj[key] = clonarObjeto(value); // Chamada recursiva para copiar objetos aninhados
    });

    return clonedObj;
};
