<app-button
    [routerLink]="routerLink"
    color="primary"
    [icon]="icon"
    [text]="text"
    [loading]="loading"
    [onClick]="onClick"
>
    <ng-content></ng-content>
</app-button>
