import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlayComponent } from './play.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';

const routes: Routes = [
    {
        path: 'play/:id',
        component: PlayComponent,
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PlayRoutingModule { }
