import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AcessosRoutingModule } from './acessos-routing.module';
import { AcessosComponent } from './acessos.component';
import { LoaderPageModule } from "../../shared/components/loader-page/loader-page.module";
import { AcessosService } from 'src/app/shared/api/acessos.service';
import { TermosDeUsoService } from 'src/app/shared/api/termos-de-uso.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ContainerPadraoModule } from "../../shared/components/container-padrao/container-padrao.module";


@NgModule({
    declarations: [
        AcessosComponent
    ],
    imports: [
    CommonModule,
    AcessosRoutingModule,
    LoaderPageModule,
    ContainerPadraoModule
],
    providers: [
        AcessosService,
        TermosDeUsoService,
        AuthService
    ]
})
export class AcessosModule { }
