import {ColumnDataType, Resource} from "../../shared/model/resource";

export const perguntaResource: Resource = {
    nomeEntidade: {
        singular: 'Pergunta',
        plural: 'Perguntas'
    },
    fieldPk: 'uuid',
    route: {
        url: 'perguntas',
    },
    colunas: [
        {
            field: 'posicao',
            label: 'Posição',
            required: true,
            type: ColumnDataType.Number,
            numberConfiguration: {
                minValue: 0
            },
            sizeColumn: 'col-12',
            unAvailableOnCreate: true
        },
        {
            field: 'descricao',
            label: 'Descrição',
            required: true,
            sizeColumn: 'col-12',
            type: ColumnDataType.TextArea,
            textareaConfiguration: {
                rows: 6
            }
        },
        {
            field: 'tempo',
            label: 'Tempo',
            required: true,
            sizeColumn: 'col-12',
            type: ColumnDataType.Number,
            numberConfiguration: {
                minValue: 0
            }
        },
        {
            field: 'feedback',
            label: 'Feedback',
            required: true,
            sizeColumn: 'col-12',
            type: ColumnDataType.TextArea,
            textareaConfiguration: {
                rows: 6
            }
        },
    ],
    actions: [],
    availableReferences: []
}
