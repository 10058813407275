import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AceiteDeTermosRoutingModule } from './aceite-de-termos-routing.module';
import { AceiteDeTermosComponent } from './aceite-de-termos.component';
import { TermosDeUsoService } from 'src/app/shared/api/termos-de-uso.service';
import { CardModule } from '../../shared/components/card/card.module';
import { AuthGuardServiceAceiteTermos } from 'src/app/shared/services/auth.service';
import { CheckboxModule } from 'src/app/shared/components/checkbox/checkbox.module';
import { ButtonModule } from "../../shared/components/button/button.module";
import { ContainerPadraoModule } from "../../shared/components/container-padrao/container-padrao.module";

@NgModule({
    declarations: [AceiteDeTermosComponent],
    providers: [TermosDeUsoService, AuthGuardServiceAceiteTermos],
    imports: [CommonModule, AceiteDeTermosRoutingModule, CardModule, CheckboxModule, ButtonModule, ContainerPadraoModule]
})
export class AceiteDeTermosModule {}
