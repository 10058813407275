import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMaterial } from 'src/app/shared/api/testes/adm-materiais.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { PessoalMateriaisService } from 'src/app/shared/api/testes/pessoal-materiais.service';
import { RelatorioService } from 'src/app/shared/api/testes/relatorios.service';
import { ActionsTabela } from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { ConfigurationColumn } from 'src/app/shared/model/resource';

@Component({
    selector: 'app-resultado',
    templateUrl: './resultado.component.html',
    styleUrls: ['./resultado.component.css']
})
export class ResultadoComponent implements OnInit {
    id: any;
    htmlRelatorio: any = '';
    loading = true;
    materiais: IMaterial[] = [];

    colunasTabelaMateriais: ConfigurationColumn[] = [
        {
            field: 'nome',
            label: 'Nome'
        },
        {
            field: 'descricao',
            label: 'Descrição'
        }
    ];

    actionsMateriais: ActionsTabela[] = [];

    constructor(
        private relatorioService: RelatorioService,
        private route: ActivatedRoute,
        public materiaisService: PessoalMateriaisService,
        private midiaservice: MidiasService
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.actionsMateriais.push({
            hint: 'Baixar material',
            icon: 'visibility',
            onClick: this.baixarMaterial,
            text: 'Baixar material',
            type: 'primary'
        })
        this.obterRelatorio();
    }

    obterRelatorio() {
        this.loading = true;
        const sub = this.relatorioService.getRelatorio(this.id).subscribe({
            next: (response) => {
                sub.unsubscribe();
                this.loading = false;
                this.htmlRelatorio = response;
            },
            error: () => {
                sub.unsubscribe();
                this.loading = false;
            }
        })
    }

    baixarMaterial = (entity: IMaterial) => {
        const sub = this.midiaservice.getMidiasMateriais(entity.uuid).subscribe({
            next: (response) => {
                sub.unsubscribe();
                const midia = response.resultados[0];

                if(midia.formato == 'link'){
                    const fullUrl = midia.url!.startsWith('http') ? midia.url : `https://${midia.url}`;
                    window.open(fullUrl, '_blank');
                } else {
                    const link = document.createElement('a');
                    link.href = midia.url!;
                    link.download = 'download';
                    link.click();
                }
            }
        })
    }
}
