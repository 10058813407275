import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { ITeste, TestesAdmService } from 'src/app/shared/api/testes/testes.service';

@Component({
    selector: 'finalizado',
    templateUrl: './finalizado.component.html',
    styleUrls: ['./finalizado.component.css', '../play.component.scss']
})
export class FinalizadoComponent implements OnInit, OnDestroy {
    @Input() idTeste!: string;
    @Input() idLicenca!: string;

    public teste?: ITeste;
    public capaUrl!: string;
    public showLoaderMidia = false;
    private subscribeTesteService!: Subscription;
    private _subscriberGetModiasRodada!: Subscription;

    constructor(
        private testeService: TestesAdmService,
        private midiaService: MidiasService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getDadosTeste();
        this.getMidiaCapa(this.idTeste);
    }

    ngOnDestroy(): void {
        this.subscribeTesteService?.unsubscribe();
        this._subscriberGetModiasRodada?.unsubscribe();
    }

    private getDadosTeste() {
        this.subscribeTesteService = this.testeService
            .detalharTestePerfilPessoal(this.idTeste)
            .subscribe({
                next: (response) => {
                    this.teste = response;
                    this.teste.descricao = this.tratarStringExibirNaTela(this.teste.descricao);
                }
            });
    }

    private tratarStringExibirNaTela(str: string){
        str = this.substituirAsteriscoPorNegrito(str);
        str = this.substituirQuebraDeLinha(str);
        return str;
    }

    private substituirAsteriscoPorNegrito(str: string) {
        return str.replace(/\*\*(.*?)\*\*/g, '<strong style="font-weight: 700">$1</strong>');
    }

    private substituirQuebraDeLinha(str: string){
        return str.replace(/\n/g, '<br>');
    }

    private getMidiaCapa(idLicenca: string) {
        this.showLoaderMidia = true;
        this._subscriberGetModiasRodada = this.midiaService.getMidiaCapa(idLicenca).subscribe({
            next: (response) => {
                const url = response.resultados[0].url!;
                this.capaUrl = url;
                this.showLoaderMidia = false;
            }
        });
    }

    onClickContinuar() {
        const url = 'relatorios/' + this.idLicenca;
        this.router.navigate([url]);
    }
}
