import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermoDeUsoRoutingModule } from './termo-de-uso-routing.module';
import { TermoDeUsoComponent } from './termo-de-uso.component';
import { ToolbarModule } from '../../shared/components/toolbar/toolbar.module';
import { CardModule } from "../../shared/components/card/card.module";
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AdmTermosDeUsoService } from 'src/app/shared/api/adm-termos-de-uso.service';
import { FormTermoComponent } from './form-termo/form-termo.component';
import { TableListModule } from "../../shared/components/table-list/table-list.module";
import { EditComponentModule } from "../../shared/components/edit-component/edit-component.module";

@NgModule({
    declarations: [TermoDeUsoComponent, FormTermoComponent],
    providers: [AdmTermosDeUsoService],
    imports: [CommonModule, TermoDeUsoRoutingModule, ToolbarModule, CardModule, MatInputModule, FormsModule, MatFormFieldModule, TableListModule, EditComponentModule]
})
export class TermoDeUsoModule {}
