import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-template-button',
    template: '',
    styleUrls: ['./template-button.component.css']
})
export abstract class TemplateButtonComponent implements OnInit {
    @Input() icon: string | null = null;
    @Input() text: string | null = null;
    color: 'primary' | 'accent' | 'warning' | null = null;
    @Input() routerLink!: string | null;

    constructor(private router: Router) {}

    ngOnInit(): void {}

    buttonClick() {
        if (this.routerLink) {
            this.router.navigate([this.routerLink]);
        }
    }
}
