import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RodadasComponent } from './rodadas.component';
import { ButtonModule } from "../../shared/components/button/button.module";
import { ModalModule } from "../../shared/components/modal/modal.module";
import { FormularioDinamicoModule } from "../../shared/components/formulario-dinamico/formulario-dinamico.module";
import { CardBotoesModule } from "../../shared/components/card-botoes/card-botoes.module";
import { MatTabsModule } from '@angular/material/tabs';
import { RodadaService } from 'src/app/shared/api/testes/rodada.service';
import { MidiasModule } from "../midias/midias.module";
import { PerguntasModule } from "../perguntas/perguntas.module";



@NgModule({
    declarations: [
        RodadasComponent
    ],
    exports: [
        RodadasComponent
    ],
    providers: [
        RodadaService
    ],
    imports: [
        CommonModule,
        ButtonModule,
        ModalModule,
        FormularioDinamicoModule,
        CardBotoesModule,
        MatTabsModule,
        MidiasModule,
        PerguntasModule
    ]
})
export class RodadasModule { }
