import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigurationColumn } from '../../model/resource';
import { FormularioDinamicoComponent } from '../formulario-dinamico/formulario-dinamico.component';

export interface IFilterComponentConfiguration {
    colunas: ConfigurationColumn[],
    tratarValoresFiltrar?: ($event: any) => any
}

@Component({
    selector: 'filter-component',
    templateUrl: './filter-component.component.html',
    styleUrls: ['./filter-component.component.scss']
})
export class FilterComponentComponent implements OnInit {
    @ViewChild('formulario') formulario!: FormularioDinamicoComponent;

    @Input() filterConfiguration!: IFilterComponentConfiguration;
    @Input() filtro: any = {};

    @Output() filtroChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    changefiltro($event: any) {
        const formValue = this.formulario?.getDadosFormularioSalvar();
        this.filtroChange.emit(this.tratarValoresFiltrar(formValue));
    }

    private tratarValoresFiltrar($event: any){
        if(!this.filterConfiguration.tratarValoresFiltrar){
            return $event;
        } 

        return this.filterConfiguration.tratarValoresFiltrar($event);
    }
}
