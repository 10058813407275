<!-- <div class="timer">
    {{ segundosParaMinutos(segundos) }}
</div> -->


<svg width="60" height="60" viewBox="0 0 100 100">
    <!-- Círculo de fundo -->
    <circle cx="50" cy="50" r="45" [attr.stroke]="corDark" stroke-width="5" fill="none" />

    <!-- Barra de progresso -->
    <circle cx="50" cy="50" r="45" [attr.stroke]="corPrimaria" stroke-width="7" fill="none" stroke-dasharray="282.74"
        [attr.stroke-dashoffset]="strokeDashoffset" stroke-linecap="round" transform="rotate(-90 50 50)" />

    <!-- Texto do timer -->
    <text x="50%" y="55%" text-anchor="middle" [attr.fill]="corPrimaria" font-size="22" font-weight="bold">
        {{ segundosParaMinutos(segundos) }}
    </text>
</svg>