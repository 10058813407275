import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabelaListagemComponent } from './tabela-listagem.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeHtmlPipe } from '../../services/safeHtmlPipe';



@NgModule({
    declarations: [
        TabelaListagemComponent,
        SafeHtmlPipe,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatTooltipModule,
    ],
    exports: [
        TabelaListagemComponent
    ],
    providers: [
        SafeHtmlPipe
    ]
})
export class TabelaListagemModule { }
