import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationMenu, navigation } from 'src/app/app-navigation';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    apps_disponiveis: NavigationMenu[] = [];

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.authService.acessoSubject$.pipe().subscribe((acesso) => {
            this.listarApps();
        });
        this.listarApps();
    }

    listarApps() {
        this.apps_disponiveis = [];
        const tipoAcesso = this.authService.getTipoAcesso();

        if (!tipoAcesso) return;

        navigation.forEach((rota) => {
            const permissoes = rota.permissoes as string[];
            if (!permissoes || permissoes.includes(tipoAcesso)) {
                // se a rota não tiver permissoes
                // ou se o usuario possuir permissoes
                this.apps_disponiveis.push(rota);
            }
        });

        if (this.apps_disponiveis.length == 1) this.selecionarCard(this.apps_disponiveis[0]);
    }

    selecionarCard(nav: NavigationMenu) {
        this.router.navigate([`/${nav.path}`]);
    }
}
