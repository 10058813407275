<mat-card
    class="card-licenca"
    *ngIf="midiaTeste"
    [ngClass]="{'d-none': !imageLoaded}"
    appearance="outlined"
    (mouseenter)="toggleCard(true)"
    (mouseleave)="toggleCard(false)"
>
    <img
        (load)="loadImage()"
        mat-card-image
        class="card-image"
        *ngIf="imageFormats.includes(midiaTeste.formato!.toLocaleLowerCase())"
        [ngStyle]="{
            'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
        }"
        [src]="midiaTeste.url"
        height="100%"
    />

    <video
        (canplay)="loadImage()"
        mat-card-image
        class="card-image"
        *ngIf="videoFormats.includes(midiaTeste.formato!.toLocaleLowerCase())"
        [ngStyle]="{
            'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
        }"
        controls
    >
        <source [src]="midiaTeste.url" />
    </video>

    <div
        #cardContent
        class="card-content"
        [ngClass]="{
            'card-content-opened': cardOpened,
            'card-content-closed': !cardOpened
        }"
    >
        <mat-card-content class="card-content-text">
            <!-- verificar cardOpeded no titulo para corrigir glitch visual -->
            <div class="card-title" *ngIf="cardOpened">{{ teste.nome }}</div>
        </mat-card-content>

        <mat-card-actions class="card-actions">
            <div class="row">
                <div class="col-6">
                    <button mat-mini-fab color="primary" (click)="iniciar()">
                        <mat-icon>play_arrow</mat-icon>
                    </button>
                    <span class="ms-3">Iniciar</span>
                </div>
            </div>
        </mat-card-actions>
    </div>
</mat-card>

<div class="skeleton-card" *ngIf="!imageLoaded">
    <div
        class="skeleton-image"
        [ngStyle]="{
            'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
        }"
    ></div>
    <div class="skeleton-text">
        <div class="skeleton-line short"></div>
        <div class="skeleton-line"></div>
        <div class="skeleton-line"></div>
    </div>
</div>
