import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EquipesRoutingModule } from './equipes-routing.module';
import { EquipesComponent } from './equipes.component';
import { TableListModule } from '../../shared/components/table-list/table-list.module';
import { EquipeService } from 'src/app/shared/api/equipe.service';
import { FormEquipeComponent } from './form-equipe/form-equipe.component';
import { EditComponentModule } from '../../shared/components/edit-component/edit-component.module';
import { LiberarLicencaEquipeComponent } from './liberar-licenca-equipe/liberar-licenca-equipe.component';
import { CardModule } from '../../shared/components/card/card.module';
import { TestesClienteService } from 'src/app/shared/api/testes/testes-cliente.service';
import { LookupModule } from '../../shared/components/lookup/lookup.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ButtonModule } from '../../shared/components/button/button.module';
import { LicencasDisponiveisService } from 'src/app/shared/api/licencas/licencas-disponiveis.service';
import { AutocadastroModule } from '../autocadastro/autocadastro.module';
import { ContainerPadraoModule } from '../../shared/components/container-padrao/container-padrao.module';

@NgModule({
    declarations: [EquipesComponent, FormEquipeComponent, LiberarLicencaEquipeComponent],
    providers: [EquipeService, LicencasDisponiveisService, TestesClienteService],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        EquipesRoutingModule,
        TableListModule,
        EditComponentModule,
        CardModule,
        LookupModule,
        ButtonModule,
        AutocadastroModule,
        ContainerPadraoModule
    ]
})
export class EquipesModule {}
