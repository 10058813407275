import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'progresso',
    templateUrl: './progresso.component.html',
    styleUrls: ['./progresso.component.css']
})
export class ProgressoComponent implements OnInit {

    @Input() valor = 0;

    constructor() {}

    ngOnInit(): void {}
}
