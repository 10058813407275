<mat-select
    [value]="value[selectConfiguration.valueExpr]"
    (valueChange)="onSelectChangeValue($event)"
>
    <mat-option
        *ngFor="let option of optionsSelect"
        [value]="option[selectConfiguration.valueExpr]"
    >
        {{ calculateDisplayValue(option) }}
    </mat-option>
</mat-select>
