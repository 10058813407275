import { Observable } from "rxjs";
import { IResponse } from "./response.model";
import { TemplateApiService } from "./template-api.service";
import { Injectable } from "@angular/core";


export interface ITermo extends IResponse {
    texto: string,
    data_criacao: string,
    usuario: {
        nome: string,
        email: string,
    },
    acesso: string,
    ativo: boolean
}

@Injectable()
export class TermosDeUsoService extends TemplateApiService<ITermo> {

    public override setBaseUrl(...props: any) {
        this.baseUrl = 'termos';
    }

    public aceitarTermo(termo: string): Observable<ITermo>{
        return this.apiService.post('termos-usuario', {termo: termo});
    }

    override create(body: any): Observable<ITermo> {
        throw new Error('Not Supported Method Exception');
    }

    override update(id: string, body: any): Observable<ITermo> {
        throw new Error('Not Supported Method Exception');
    }

    override delete(id: string, params?: {}): Observable<void> {
        throw new Error('Not Supported Method Exception');
    }

    override detail(id: string): Observable<ITermo> {
        throw new Error('Not Supported Method Exception');
    }
}
