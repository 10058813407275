import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigurationColumn } from '../../model/resource';
import { FormGroup } from '@angular/forms';
import { hexadecimalToColorPicker } from '../../functions/color-functions';
import { IColorPicker } from '../../model/color.model';
import { Color, NgxMatColorPickerInputEvent } from '@angular-material-components/color-picker';

@Component({
    selector: 'color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit, OnChanges {

    @Input() parentFormGroup!: FormGroup;
    @Input() coluna!: ConfigurationColumn;
    @Input() value: string = '';
    @Output() valueChange = new EventEmitter();

    configurationColor!: Color;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['value']?.currentValue){
            this.setConfigurationColor(changes['value'].currentValue);
        }
    }

    changeColor(event: NgxMatColorPickerInputEvent){
        if(event.value?.hex) this.value = '#' + event.value?.hex;
        else this.value = '';

        this.valueChange.emit(this.value);
    }

    setConfigurationColor(cor: string){
        const colorPicker = hexadecimalToColorPicker(cor);
        this.configurationColor = new Color(colorPicker.r, colorPicker.g, colorPicker.b, colorPicker.a);
    }

}
