import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from "../components/dialog/dialog.component";


@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private dialog: MatDialog) { }

    confirm(titulo: string, texto: string, labelOk = null, labelCancel = null, tipoBtn = 'danger'){
        return this.dialog.open(DialogComponent, {
            width: '400px',
            data: {titulo, texto, labelOk, labelCancel, tipoBtn}
        }).afterClosed();
    }
}
