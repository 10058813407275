import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaginatedResponse } from '../api/pagination.model';
import { IResponse } from '../api/response.model';


@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private readonly API = environment.API;

    constructor(private http: HttpClient){
    }

    get(url: string, params = {}) {
        return this.http.get<PaginatedResponse<IResponse>>(`${this.API}/${url}/`, {
            params: params
        });
    }

    detail(url: string, id: string) {
        return this.http.get<IResponse>(`${this.API}/${url}/${id}/`).pipe(take(1));
    }

    delete(url: string, id: string, params = {}) {
        return this.http.delete<void>(`${this.API}/${url}/${id}/`, {params: params});
    }

    post(url: string, entity: any, params = {}) {
        return this.http.post<IResponse | any>(`${this.API}/${url}/`, entity, {
            params: params
        });
    }

    put(url: string, id: string, entity: any ) {
        return this.http.put<IResponse>(`${this.API}/${url}/${id}/`, entity);
    }

    patch(url: string, entity: any ) {
        return this.http.patch<IResponse | any>(`${this.API}/${url}/`, entity);
    }

    getFile(url: string, params = {}){
        return this.http.get(`${this.API}/${url}`, {
            responseType: 'blob',
            params: params
        });
    }

    postFile(url: string, formData: FormData) {
        return this.http.post(`${this.API}/${url}`, formData, {
            responseType: 'text',
        });
    }

    getHtml(url: string, params = {}) {
        return this.http.get(`${this.API}/${url}/`, {
            responseType: 'text',
            params: params
        });
    }
}
