import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { alternativasResource } from './alternativa.resource';
import { ActionsTabela, TabelaListagemComponent } from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { ConfigurationColumn } from 'src/app/shared/model/resource';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AlternativaService, IAlternativa } from 'src/app/shared/api/testes/alternativa.service';
import { Observable, Subscription } from 'rxjs';
import { FormularioDinamicoComponent } from 'src/app/shared/components/formulario-dinamico/formulario-dinamico.component';
import { NotificationService } from 'src/app/shared/services/notificationService';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
    selector: 'alternativas',
    templateUrl: './alternativas.component.html',
    styleUrls: ['./alternativas.component.css']
})
export class AlternativasComponent implements OnInit, OnDestroy {
    @ViewChild('modal') modal!: ModalComponent;
    @ViewChild('formularioAlternativa') formularioAlternativa!: FormularioDinamicoComponent;
    @ViewChild('tabelaAlternativas') tabelaAlternativas!: TabelaListagemComponent;

    @Input() idTeste!: string;
    @Input() idModulo!: string;
    @Input() idRodada!: string;
    @Input() idPergunta!: string;

    readonly ADICIONAR_ALTERNATIVA = 'Adicionar alternativa';
    readonly EDITAR_ALTERNATIVA = 'Editar alternativa';
    readonly EXCLUIR_ALTERNATIVA = 'Excluir alternativa';


    urlAlternativas!: string;
    colunasFormulario: ConfigurationColumn[] = [];
    dataModal: IDialogData = { titulo: '' };
    showFormModal = false;

    alternativasResource = alternativasResource;
    actions: ActionsTabela[] = [];
    listaAlternativas: IAlternativa[] = [];
    entidadeAlternativa!: any;

    private _subscriberModal: Subscription | null = null;
    private _subscriberCreateAlternativa: Subscription | null = null;
    private _subscriberDeleteAlternativa: Subscription | null = null;

    constructor(
        public alternativaService: AlternativaService,
        private notify: NotificationService,
        private dialog: DialogService
    ) { }

    ngOnInit(): void {
        this.setarIdsNaCamadaDeServico();
        
        this.actions.push({
            hint: this.EDITAR_ALTERNATIVA,
            icon: 'edit',
            text: '',
            type: 'primary', 
            mode: 'single',
            onClick: this.abrirModalEditarAlternativa
        });

        this.actions.push({
            hint: this.EXCLUIR_ALTERNATIVA,
            icon: 'delete',
            text: 'Excluir',
            type: 'warn',
            mode: 'single',
            onClick: this.excluirAlternativa
        });
    }

    private setarIdsNaCamadaDeServico(){
        // é importante setar esses valores a cada vez que o serviço for chamado
        // pois esse componente pode estar sendo chamado mais de uma vez na mesma tela com idPergunta diferente
        this.alternativaService.setBaseUrl(this.idTeste, this.idModulo, this.idRodada, this.idPergunta);
    }

    ngOnDestroy(): void {
        this._subscriberModal?.unsubscribe();
        this._subscriberCreateAlternativa?.unsubscribe();
        this._subscriberDeleteAlternativa?.unsubscribe();
    }

    showModalFormularioAlternativa() {
        this.showFormModal = true;
        this._subscriberModal = this.modal.open({ width: '40em' }).afterClosed().subscribe({
            next: () => {
                this.showFormModal = false;
            }
        });
    }

    abrirModalCriarAlternativa(){
        this.entidadeAlternativa = {};
        this.dataModal.titulo = this.ADICIONAR_ALTERNATIVA;
        this.colunasFormulario = this.alternativasResource.colunas.filter(c => !c.unAvailableOnCreate);
        this.showModalFormularioAlternativa();
    }

    abrirModalEditarAlternativa = (entity: any) => {
        this.entidadeAlternativa = entity;
        this.dataModal.titulo = this.EDITAR_ALTERNATIVA;
        this.colunasFormulario = this.alternativasResource.colunas.filter(c => c);
        this.showModalFormularioAlternativa();
    }

    onSaveAlternativa(){
        return new Observable(observer => {
            if (!this.formularioAlternativa.isValid()) {
                observer.error();
                return;
            }

            this.setarIdsNaCamadaDeServico();
            const alternativa = this.formularioAlternativa.getDadosFormularioSalvar();

            let createOrSaveRequest = null;
            if (this.entidadeAlternativa?.uuid) {
                createOrSaveRequest = this.alternativaService.update(this.entidadeAlternativa.uuid, alternativa);
            } else {
                createOrSaveRequest = this.alternativaService.create(alternativa);
            }

            this._subscriberCreateAlternativa = createOrSaveRequest.subscribe({
                next: () => {
                    this.tabelaAlternativas.listarEntidades();
                    observer.next();
                },
                error: (e: any) => {
                    this.formularioAlternativa.tratarErroRequisicao(e);
                    this.notify.error(e.error.erro);
                    observer.error();
                }
            })
        })
    }

    public excluirAlternativa = (data: IAlternativa) => {
        const entidade = data;
        this.dialog.confirm('Confirmar exclusão', 'Tem certeza que deseja excluir? Não será possível desfazer a alteração.').subscribe({
            next: (confirm: boolean) => {
                if(confirm){
                    this.setarIdsNaCamadaDeServico();

                    const idAlternativa = entidade.uuid;
                    this._subscriberDeleteAlternativa = this.alternativaService.delete(idAlternativa).subscribe({
                        next: () => {
                            this.tabelaAlternativas.listarEntidades();
                        },
                        error: e => {
                            this.notify.error(e.error.erro);
                        }
                    });
                }
            }
        })
    }
}
