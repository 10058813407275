import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardMidiaFormularioComponent } from './card-midia-formulario.component';
import { CardMidiaModule } from '../card-midia/card-midia.module';
import { AdmMidiasService } from '../../api/testes/adm-midias.service';

@NgModule({
    declarations: [CardMidiaFormularioComponent],
    imports: [CommonModule, CardMidiaModule],
    providers: [AdmMidiasService],
    exports: [CardMidiaFormularioComponent]
})
export class CardMidiaFormularioModule {}
