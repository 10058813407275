<form (submit)="onSubmit($event)">


    <mat-form-field>
        <mat-label>Nova Senha</mat-label>

        <input [(ngModel)]="formData.senha"
            [type]="hide ? 'password' : 'text'"
            name="senha"
            matInput>
        <button [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            (click)="hide = !hide"
            type="button"
            tabindex="-1"
            mat-icon-button
            matSuffix>
            <mat-icon>
                {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </button>
    </mat-form-field>


    <!-- BOTAO LOGIN -->
    <button [disabled]="loading"
        (click)="onSubmit($event)"
        color="primary"
        mat-raised-button
        type="submit"
        style="width: 100%;">
        <ng-container *ngIf="!loading">
            <mat-icon>login</mat-icon>
            Alterar Senha
        </ng-container>



        <div *ngIf="loading"
            style="width: 100%; display: flex; justify-content: center; padding: 0.3em">
            <mat-icon>login</mat-icon>
            <mat-spinner [diameter]="30"
                color="accent"></mat-spinner>
        </div>
    </button>




    <div class="login-link mt-3">
        <a routerLink="/login">Retornar para Login</a>
    </div>


</form>
