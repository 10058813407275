<container-padrao>
    <card title="Liberar liceças">
        <form [formGroup]="formulario">
            <div class="row">
                <div class="col-lg-6">
                    <lookup
                        [parentFormGroup]="formulario"
                        [coluna]="colunaLicencasDisponiveisEquipe"
                        [service]="licencasDisponiveisService"
                        [(value)]="licenca"
                        (valueChange)="changeLicenca()"
                    ></lookup>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Digite a quantidade de licenças a serem liberadas
                        </mat-label>
                        <input
                            [formControlName]="colunaQuantidadeLicencas.field"
                            [(ngModel)]="quantidadeLicencas"
                            [min]="0"
                            [max]="maxQuantidadelicencas"
                            [name]="colunaQuantidadeLicencas.field"
                            width="100%"
                            type="number"
                            matInput
                        />
                    </mat-form-field>
                </div>

                <div class="col-lg-6">
                    <div class="d-grid justify-content-end">
                        <primary-button
                            icon="save"
                            text="Liberar licenças"
                            (click)="onClickLiberarLicencas()"
                        ></primary-button>
                    </div>
                </div>
            </div>
        </form>
    </card>
</container-padrao>
