import {ColumnDataType, ColumnValidation, Resource} from "../../shared/model/resource";

export const clienteResource: Resource = {
    disableDelete: true,
    nomeEntidade: {
        singular: 'Cliente',
        plural: 'Clientes'
    },
    fieldPk: 'uuid',
    route: {
        url: 'clientes',
    },
    colunas: [
        {
            field: 'username',
            label: 'E-mail',
            validation: [{type: 'email'}],
            required: true,
            unAvailableOnList: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'senha',
            label: 'Senha',
            type: ColumnDataType.Password,
            required: true,
            unAvailableOnList: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'nome',
            label: 'Nome',
            required: true,
            unAvailableOnList: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'razao_social',
            label: 'Razão Social',
            required: true,
        },
        {
            field: 'nome_fantasia',
            label: 'Nome Fantasia',
            required: true
        },
        {
            field: 'cnpj',
            label: 'CNPJ',
            required: true,
        },
        {
            field: 'ativo',
            label: 'Ativo',
            required: false,
            readOnly: true,
            type: ColumnDataType.Checkbox,
            calculateDisplayValue: (ativo: any) => {
                return ativo ? 'Sim' : 'Não'
            }
        }
    ],
    actions: [],
    availableReferences: []
}
