import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LicencasDisponiveisService } from 'src/app/shared/api/licencas/licencas-disponiveis.service';
import {
    ColumnDataType,
    ConfigurationColumn
} from 'src/app/shared/model/resource';
import { NotificationService } from 'src/app/shared/services/notificationService';

@Component({
    selector: 'liberar-licenca-equipe',
    templateUrl: './liberar-licenca-equipe.component.html',
    styleUrls: [
        './liberar-licenca-equipe.component.css',
        '../../../shared/components/formulario-dinamico/formulario-dinamico.component.css'
    ]
})
export class LiberarLicencaEquipeComponent implements OnInit, OnDestroy {
    @Input() idEquipe!: string;

    formulario: any;
    licenca: any;
    quantidadeLicencas: number = 0;
    maxQuantidadelicencas = 0;

    colunaLicencasDisponiveisEquipe!: ConfigurationColumn;
    colunaQuantidadeLicencas!: ConfigurationColumn;

    private subscriberLicencasService!: Subscription;

    constructor(
        public licencasDisponiveisService: LicencasDisponiveisService,
        private notify: NotificationService
    ) {}

    ngOnInit(): void {
        this.licencasDisponiveisService.setBaseUrl({ equipe: null });
        this.configurarColunasFormulario();
        this.configureFormGroup();
    }

    ngOnDestroy(): void {
        this.subscriberLicencasService?.unsubscribe();
    }

    onClickLiberarLicencas() {
        this.liberarLicencas();
    }

    private liberarLicencas() {
        if (!this.validarFormulario()) return;

        this.subscriberLicencasService = this.licencasDisponiveisService
            .alocarLicencaEquipe(
                this.idEquipe,
                this.quantidadeLicencas,
                this.licenca.uuid
            )
            .subscribe({
                next: (response) => {
                    this.notify.success('Licenças alocadas com sucesso!');
                    this.clearDados();
                },
                error: (erro) => {
                    if (erro.error.quantidade[0]) {
                        this.notify.error(erro.error.quantidade[0]);
                    } else {
                        this.notify.error('Ocorreu um erro');
                    }
                }
            });
    }

    private clearDados() {
        this.quantidadeLicencas = 0;
        this.licenca = null;
    }

    private validarFormulario() {
        if (!this.licenca) {
            this.notify.error('Selecione uma licença');
            return false;
        }

        if (!this.quantidadeLicencas) {
            this.notify.error(
                'Digite uma quantidade de licenças maior que zero!'
            );
            return false;
        }

        return true;
    }

    private configurarColunasFormulario() {
        this.colunaLicencasDisponiveisEquipe = {
            field: 'licenca',
            label: 'Licença disponível',
            type: ColumnDataType.Lookup,
            lookupConfiguration: {
                colunas: [
                    {
                        field: 'teste',
                        label: 'Curso'
                    },
                    {
                        field: 'disponiveis',
                        label: 'Qtd Disponíveis'
                    }
                ],
                fieldName: 'teste',
                fieldPk: 'uuid',
                service: null
            }
        };

        this.colunaQuantidadeLicencas = {
            field: 'quantidade',
            label: 'Quantidade de licenças',
            required: true,
            type: ColumnDataType.Number,
            numberConfiguration: {
                minValue: 0
            }
        };
    }

    private configureFormGroup() {
        const grupo: any = {};

        grupo[this.colunaLicencasDisponiveisEquipe.field] = new FormControl('');
        grupo[this.colunaQuantidadeLicencas.field] = new FormControl('');

        this.formulario = new FormGroup(grupo);
    }

    changeLicenca() {
        this.maxQuantidadelicencas = this.licenca?.disponiveis || 0;
    }
}
