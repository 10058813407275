<loader-page [show]="showLoaderModulos"></loader-page>

<div class="d-flex justify-content-end" *ngIf="listaModulos.length > 0">
    <primary-button
        (click)="abrirModalCriarNovoModulo()"
        icon="add"
        text="Adicionar módulo"
    ></primary-button>
</div>

<div class="mt-2">
    <card-botoes
        *ngFor="let modulo of listaModulos"
        [title]="modulo.nome"
        #card
        [cardOpened]="false"
        [entity]="modulo"
        [actions]="actions"
    >
        <mat-tab-group #grupoTab (selectedTabChange)="grupoTab.selectedIndex = $event.index">
            <mat-tab label="Mídias">
                <midias
                    *ngIf="card.cardOpened"
                    content_type="modulo"
                    [obj_referencia]="modulo.uuid"
                ></midias>
            </mat-tab>
            <mat-tab [label]="rodadaResource.nomeEntidade.plural">
                <rodadas
                    *ngIf="grupoTab.selectedIndex == 1"
                    [idTeste]="id"
                    [idModulo]="modulo.uuid"
                ></rodadas>
            </mat-tab>
        </mat-tab-group>
    </card-botoes>
</div>

<div *ngIf="listaModulos.length == 0 && !showLoaderModulos" class="card-nenhum-modulo-cadastrado">
    <p>Nenhum módulo encontrado!</p>

    <primary-button
        (click)="abrirModalCriarNovoModulo()"
        icon="add"
        text="Adicionar módulo"
        class="m-auto mt-2"
    ></primary-button>
</div>

<modal #modal [data]="dataModal" [onSave]="onSaveModulo()">
    <formulario-dinamico
        #formularioModulo
        *ngIf="showFormModal"
        [colunas]="colunasFormularioModulo"
        [(entidade)]="entidadeModulo"
    ></formulario-dinamico>
</modal>
