import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";


export interface IModulo extends IResponse {
    nome: string,
    descricao: string,
    posicao: number,
    teste: string,
    categoria?: string
}

@Injectable()
export class ModuloService extends TemplateApiService<IModulo> {

    public override setBaseUrl(idTeste: any){
        this.baseUrl = `admin/testes/${idTeste}/modulos`;
    }
}
