import { Component, OnInit, ViewChild } from '@angular/core';
import { LicencasService } from 'src/app/shared/api/licencas/licencas.service';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginatedResponse } from 'src/app/shared/api/pagination.model';
import { filtroPessoalLicencasResource } from './filtro-pessoal-licencas.resource';
import { TestesService } from 'src/app/shared/api/testes/testes-cliente.service';
import { ITeste } from 'src/app/shared/api/testes/testes.service';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'app-pessoal-licencas',
    templateUrl: './pessoal-licencas.component.html',
    styleUrls: ['./pessoal-licencas.component.scss']
})
export class PessoalLicencasComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild('cardContent') cardContent!: HTMLDivElement;

    cardOpened = false;
    loading = true;

    testes: ITeste[] = [];
    paginacao: PaginatedResponse<any> = { 'total-paginas': 0, pagina: 0, resultados: [], total: 0 };

    filtroPessoalLicencasResource = filtroPessoalLicencasResource;
    filtro: any = {};

    constructor(
        public licencasService: LicencasService,
        private testesService: TestesService,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.authService.setTheme('dark');
        this.customizarMatPaginator();

        this.listarTestes();
    }

    private listarTestes(params: any = { pagina: 1, tamanho: 12 }) {
        this.filtro.pagina = params.pagina;
        this.filtro.tamanho = params.tamanho;
        this.filtro.antecessor = null;

        this.loading = true;
        this.paginacao.total = 0;

        this.testesService.get(this.filtro).subscribe({
            next: (response) => {
                this.testes = response.resultados;
                this.loading = false;
            }
        });
    }

    irParaGrupo = (teste: ITeste) => {
        this.goToGrupo(teste.uuid);
    };

    changeFiltro(event: any) {
        const filtro: any = {
            pagina: this.filtro.pagina || 1,
            tamanho: this.filtro.tamanho || 12
        };

        if (this.filtro.concluido && this.filtro.concluido != 3)
            filtro['concluido'] = this.filtro.concluido.id;
        if (this.filtro.teste) filtro['teste'] = this.filtro.teste;

        this.listarTestes(filtro);
    }

    goToGrupo(id: string) {
        this.router.navigate([
            ConstantsRotasDoSistema.DEFAULT_PATH +
                ConstantsRotasDoSistema.GRUPOS_LICENCAS +
                `/${id}`
        ]);
    }

    public alterarPagina(pageEvent: PageEvent) {
        const tamanho = pageEvent.pageSize;
        const pagina = pageEvent.pageIndex + 1;
        this.listarTestes({ tamanho, pagina });
    }

    private customizarMatPaginator() {
        this.paginator._intl.itemsPerPageLabel = 'Itens por página';

        this.paginator._intl.nextPageLabel = 'Próxima';

        this.paginator._intl.previousPageLabel = 'Anterior';

        this.paginator._intl.firstPageLabel = 'Primeira página';
        this.paginator._intl.lastPageLabel = 'Última página';

        this.paginator._intl.getRangeLabel = this.getRangeLabel;
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 de ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        const label = `${startIndex + 1} - ${endIndex} de ${length}`;
        return label;
    };
}
