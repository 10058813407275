<container-padrao *ngIf="filterConfiguration">
    <card title="Filtros">
        <filter-component 
            [filterConfiguration]="filterConfiguration"
            (filtroChange)="filtroChange($event)"
        ></filter-component>
    </card>
</container-padrao>


<container-padrao>
    <card class="pt-3">
        <div class="mb-2 titulo-e-actions">
            <h2>Listagem de {{ resource.nomeEntidade.plural }}</h2>

            <!-- <primary-button
                *ngIf="!resource.disableNew"
                (click)="onClickNovo()"
                text="Adicionar {{ resource.nomeEntidade.singular }}"
                icon="playlist_add"
            ></primary-button> -->

            <div class="actions">
                <ng-container *ngFor="let action of actionsBatch">
                    <button-component (click)="action.onClick(null, service, router, route)" [color]="action.type"
                        [text]="action.text" [icon]="action.icon" [tooltip]="action.hint"></button-component>
                </ng-container>
            </div>
        </div>

        <tabela-listagem #tabela [colunas]="resource.colunas" [actions]="actionsTabela" [valueExpr]="fieldPk"
            [service]="service"></tabela-listagem>
    </card>
</container-padrao>