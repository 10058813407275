import { Observable } from "rxjs";
import { TemplateApiService } from "../template-api.service";
import { IMidia } from "./adm-midias.service";
import { PaginatedResponse } from "../pagination.model";
import { Injectable } from "@angular/core";


@Injectable()
export class MidiasService extends TemplateApiService<IMidia> {

    readonly content_type_capa = 'teste';
    readonly content_type_modulo = 'modulo';
    readonly content_type_rodada = 'rodada';
    readonly content_type_pergunta = 'pergunta';
    readonly content_type_material = 'material';

    public override setBaseUrl(){
        this.baseUrl = `midias`;
    }

    getMidiaCapa(id: string) : Observable<PaginatedResponse<IMidia>> {
        return this.apiService.get(this.baseUrl, {
            content_type: this.content_type_capa,
            obj_referencia: id
        }) as Observable<PaginatedResponse<IMidia>>;
    }

    getMidiasModulos(id: string) : Observable<PaginatedResponse<IMidia>> {
        return this.apiService.get(this.baseUrl, {
            content_type: this.content_type_modulo, 
            obj_referencia: id
        }) as Observable<PaginatedResponse<IMidia>>;
    }

    getMidiasRodada(id: string) : Observable<PaginatedResponse<IMidia>> {
        return this.apiService.get(this.baseUrl, {
            content_type: this.content_type_rodada,
            obj_referencia: id
        }) as Observable<PaginatedResponse<IMidia>>;
    }

    getMidiasPergunta(id: string) : Observable<PaginatedResponse<IMidia>> {
        return this.apiService.get(this.baseUrl, {
            content_type: this.content_type_pergunta,
            obj_referencia: id
        }) as Observable<PaginatedResponse<IMidia>>;
    }

    getMidiasMateriais(id: string) : Observable<PaginatedResponse<IMidia>>{
        return this.apiService.get(this.baseUrl, {
            content_type: this.content_type_material,
            obj_referencia: id
        }) as Observable<PaginatedResponse<IMidia>>;
    }

    override get(params?: any): Observable<PaginatedResponse<IMidia>> {
        throw new Error('Método não suportado para esse acesso');
    }

    override detail(id: string): Observable<IMidia> {
        throw new Error('Método não suportado para esse acesso');
    }

    override create(body: any): Observable<IMidia> {
        throw new Error('Método não suportado para esse acesso');
    }

    override delete(id: string, params?: {}): Observable<void> {
        throw new Error('Método não suportado para esse acesso');
    }

    override update(id: string, body: any): Observable<IMidia> {
        throw new Error('Método não suportado para esse acesso');
    }
}
