<div class="col-sm-6 esquerda p-0">
    <img src="assets/img/imagem_feedback.png" class="img-feedback">
</div>

<div class="col-sm-6 direita ps-4 pe-3">
    <div class="d-grid align-items-center">

        <div class="w-100">
            <h1 class="titulo">Feedback</h1>
        </div>

        <div class="descricao" [innerHTML]="texto | safeHtmlJogada"></div>

        <btn-continuar
            (click)="onClickContinuar()"
            class="mt-4"
        ></btn-continuar>
    </div>
</div>
