import { Observable } from "rxjs";
import { IResponse } from "./response.model";
import { TemplateApiService } from "./template-api.service";
import { Injectable } from "@angular/core";
import { ETipoAcesso } from "src/app/app-navigation";


export interface IAcesso extends IResponse {
    ativo: boolean,
    data_criacao: string,
    tipo: ETipoAcesso,
    detalhe?: any,
    usuarios?: {nome: string, username: string}[]
}

export interface IAutenticarResponse {
    token: string
}

@Injectable()
export class AcessosService extends TemplateApiService<IAcesso> {

    public override setBaseUrl(...props: any): void {
        this.baseUrl = 'acessos';
    }

    public selecionarAcesso(idAcesso: string): Observable<IAutenticarResponse>{
        return this.apiService.post('autenticar/token-acesso', {acesso: idAcesso});
    }
}
