<div
    class="file-drop-area"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
    (dragleave)="onDragLeave($event)"
>
    <input
        type="file"
        #fileInput
        class="file-input"
        (change)="onFileSelected($event)"
        [multiple]="multiple"
        [accept]="accept"
    />
    <p class="file-message" *ngIf="!file">Arraste os arquivos aqui ou clique para selecionar</p>
    <p class="file-message" *ngIf="file">{{file.name}}</p>
</div>
