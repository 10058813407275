import { Injectable } from "@angular/core";
import { IResponse } from "./response.model";
import { TemplateApiService } from "./template-api.service";


export interface ICliente extends IResponse {
    ativo: boolean,
    data_criacao: string,
    tipo: string
}

@Injectable()
export class AdmEquipeService extends TemplateApiService<ICliente> {

    public override setBaseUrl(idCliente: string) {
        this.baseUrl = `admin/acessos-clientes/${idCliente}/equipes`;
    }
}
