import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";


export interface IRelatorio extends IResponse {
}

@Injectable()
export class RelatorioService extends TemplateApiService<IRelatorio> {

    public override setBaseUrl(){
        this.baseUrl = `relatorio`;
    }

    public getRelatorio(idLicenca: string){
        const url = this.getBaseUrl() + `/${idLicenca}`;
        return this.apiService.getHtml(url);
    }

    public getRelatorioComparativo(id: string){
        const url = 'comparacao-relatorio/' + id;
        return this.apiService.getHtml(url);
    }
}
