import { Injectable } from "@angular/core";
import { TemplateApiService } from "./template-api.service";
import { ISkin } from "./skins.service";


@Injectable()
export class AdmSkinService extends TemplateApiService<ISkin> {

    public override setBaseUrl(idCliente: string) {
        this.baseUrl = `admin/acessos-clientes/${idCliente}/skins`;
    }
}
