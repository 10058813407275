import { CommonModule } from '@angular/common';
import {
    Component,
    NgModule,
    ViewChild,
    OnDestroy,
    OnInit
} from '@angular/core';
import { NavigationMenu, navigation } from 'src/app/app-navigation';

import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { filter } from 'rxjs';

@Component({
    selector: 'app-side-navigation-menu',
    templateUrl: './side-navigation-menu.component.html',
    styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent implements OnInit, OnDestroy {
    @ViewChild('drawer', { static: false }) drawer!: MatDrawer;

    rotas = navigation;
    rotasListar: NavigationMenu[] = [];
    currentRoute!: string;
    modoMenu: 'over' | 'side' = 'side';
    menuAberto = true;

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                this.currentRoute = event.urlAfterRedirects.replace('/', '');
            });

        this.authService.acessoSubject$.pipe().subscribe(acesso => {
            this.listarRotasMenu();
        })

        this.configurarModoMenu();

        this.listarRotasMenu();
    }

    ngOnDestroy(): void {}

    toggleMenu() {
        this.drawer.toggle();
    }

    private configurarModoMenu(){
        const ismobile = window.matchMedia('(max-width: 1000px)').matches;

        if(ismobile) {
            this.modoMenu = 'over';
            this.menuAberto = false;
        }
        else this.modoMenu = 'side';
    }

    private listarRotasMenu() {
        this.rotasListar = [];
        const tipoAcesso = this.authService.getTipoAcesso();

        if (!tipoAcesso) return;

        this.rotas.forEach((rota) => {
            const permissoes = rota.permissoes as string[];
            if (!permissoes || permissoes.includes(tipoAcesso)) {
                // se a rota não tiver permissoes
                // ou se o usuario possuir permissoes
                this.rotasListar.push(rota);
            }
        });
    }
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatSidenavModule,
        MatButtonModule,
        MatListModule,
        MatIconModule
    ],
    declarations: [SideNavigationMenuComponent],
    exports: [SideNavigationMenuComponent]
})
export class SideNavigationMenuModule {}
