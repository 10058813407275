import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlayRoutingModule } from './play-routing.module';
import { PlayComponent } from './play.component';
import { SliderMidiaComponent } from './slider-midia/slider-midia.component';
import { CarouselModule } from "../../shared/components/carousel/carousel.module";
import { MatButtonModule } from '@angular/material/button';
import { AlternativasListComponent } from './alternativas-list/alternativas-list.component';
import { BtnContinuarComponent } from './btn-continuar/btn-continuar.component';
import { ModuloRodadaComponent } from './modulo-rodada/modulo-rodada.component';
import { PerguntasAlternativasComponent } from './perguntas-alternativas/perguntas-alternativas.component';
import { TimerComponent } from './timer/timer.component';
import { JogadaService } from 'src/app/shared/api/testes/jogada.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { LoaderPageModule } from "../../shared/components/loader-page/loader-page.module";
import { FinalizadoComponent } from './finalizado/finalizado.component';
import { MatIconModule } from '@angular/material/icon';
import { ProgressoComponent } from './progresso/progresso.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FeedbackComponent } from './feedback/feedback.component';
import { CapaComponent } from './capa/capa.component';



@NgModule({
    declarations: [
        PlayComponent,
        SliderMidiaComponent,
        AlternativasListComponent,
        BtnContinuarComponent,
        ModuloRodadaComponent,
        PerguntasAlternativasComponent,
        TimerComponent,
        FinalizadoComponent,
        ProgressoComponent,
        FeedbackComponent,
        CapaComponent
    ],
    providers: [
        JogadaService,
        MidiasService
    ],
    imports: [
        CommonModule,
        PlayRoutingModule,
        CarouselModule,
        MatButtonModule,
        LoaderPageModule,
        MatIconModule,
        MatProgressBarModule
    ]
})
export class PlayModule { }
