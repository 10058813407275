import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutocadastroFormRoutingModule } from './autocadastro-form-routing.module';
import { AutocadastroFormComponent } from './autocadastro-form.component';
import { FormularioDinamicoModule } from '../../shared/components/formulario-dinamico/formulario-dinamico.module';
import { SingleCardModule } from '../../shared/components/single-card/single-card.component';
import { ButtonModule } from '../../shared/components/button/button.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [AutocadastroFormComponent],
    imports: [
        CommonModule,
        AutocadastroFormRoutingModule,
        FormularioDinamicoModule,
        SingleCardModule,
        ButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ]
})
export class AutocadastroFormModule {}
