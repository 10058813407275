<div class="col-sm-6 esquerda p-0">
    <slider-midia *ngIf="!showLoaderMidia" [midias]="midiacapa" class="area-midias"></slider-midia>

    <div *ngIf="showLoaderMidia" class="d-grid align-items-center justify-content-center">
        <loader-page [show]="true"></loader-page>
    </div>
</div>

<div class="col-sm-6 direita ps-4 pe-3 pb-2">
    <div class="d-grid align-items-center">
        <div class="w-100">
            <p class="titulo">{{teste?.nome}}</p>
        </div>

        <div class="descricao" *ngIf="teste?.descricao">
            <div [innerHTML]="teste?.descricao | safeHtmlJogada"></div>
        </div>

        <btn-continuar
            *ngIf="teste"
            texto="Iniciar"
            (click)="onClickContinuar()"
            class="mt-4"
        ></btn-continuar>
    </div>
</div>
