import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILicenca } from 'src/app/shared/api/licencas/licencas.service';
import { unformatedStringDateToPortugueseDateString } from 'src/app/shared/functions/data-transform';

@Component({
    selector: 'card-licenca',
    templateUrl: './card-licenca.component.html',
    styleUrls: ['./card-licenca.component.scss'],
    animations: [
        trigger('cardAnimation', [
            state('collapsed', style({
                height: '0',
                paddingTop: '0',
                paddingBottom: '0',
                opacity: '0',
                overflow: 'hidden'
            })),
            state('expanded', style({
                height: '*',
                paddingTop: '0',
                paddingBottom: '0',
                opacity: '1',
                overflow: 'visible'
            })),
            transition('collapsed => expanded', animate('300ms 400ms ease-out')), // 400ms de atraso
            transition('collapsed <=> expanded', animate('300ms ease-out'))
        ]),
    ]
})
export class CardLicencaComponent implements OnInit {
    @Input() licenca!: ILicenca;

    @Output() onIniciarClick = new EventEmitter();
    @Output() onRelatorioClick = new EventEmitter();

    cardOpened = false;
    imageLoaded = false;

    constructor() {}

    ngOnInit(): void {}

    converterData(data: string) {
        return unformatedStringDateToPortugueseDateString(data);
    }

    toggleCard(value: boolean) {
        this.cardOpened = value;
    }

    loadImage(){
        console.log('setting image loaded')
        this.imageLoaded = true;
    }

    iniciar(){
        this.onIniciarClick.emit(this.licenca);
    }

    relatorio(){
        this.onRelatorioClick.emit(this.licenca);
    }
}
