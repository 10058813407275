import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { SelectConfiguration } from '../../model/resource';
import { ApiService } from '../../services/api.service';
import { PaginatedResponse } from '../../api/pagination.model';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'select-formulario',
    templateUrl: './select-formulario.component.html',
    styleUrls: ['./select-formulario.component.css']
})
export class SelectFormularioComponent implements OnInit {
    @Input() value: any; // objeto
    @Input() formControlName: any;
    @Input() selectConfiguration!: SelectConfiguration;
    @Input() name: any;
    @Input() label!: string;
    @Input() parentFormGroup!: FormGroup;

    @Output() valueChange = new EventEmitter();

    optionsSelect: any[] = [];

    constructor(private apiService: ApiService) {}

    ngOnInit(): void {
        this.listarSelect();
    }

    listarSelect() {
        if (this.selectConfiguration.defaultData) {
            this.optionsSelect = this.selectConfiguration.defaultData;
            this.setarDefaultValue();
        } else {
            let request;
            if (this.selectConfiguration.route) {
                request = this.apiService.get(this.selectConfiguration.route);
            } else if (this.selectConfiguration.service) {
                request = this.selectConfiguration.service.get();
            }

            request.subscribe({
                next: (response: PaginatedResponse<any>) => {
                    this.optionsSelect = response.resultados;
                    this.setarDefaultValue();
                }
            });
        }
    }

    private setarDefaultValue(){
        if (this.selectConfiguration.defaultValue != null){
            this.value = this.selectConfiguration.defaultValue;
            this.valueChange.emit(this.value);
        }
    }

    onSelectChangeValue($event: any) {
        const valor = this.optionsSelect.find(
            (option) => option[this.selectConfiguration.valueExpr] === $event
        );
        this.valueChange.emit(valor);
        this.selectConfiguration.onChange ? this.selectConfiguration.onChange(valor) : null;
    }

    calculateDisplayValue(value: any) {
        if (this.selectConfiguration.calculateDisplayValue) {
            return this.selectConfiguration.calculateDisplayValue(value);
        }
        return value[this.selectConfiguration.displayExpr];
    }
}
