import { Injectable } from "@angular/core";
import { TemplateApiService } from "./template-api.service";
import { IResponse } from "./response.model";
import { Observable } from "rxjs";


export interface IAutoCadastro extends IResponse{
    email: string,
    nome: string,
    telefone: string
}

export interface IAutoCadastroService extends TemplateApiService<IAutoCadastro>{
    ativar: (idAutocadastro: string, ativo: boolean) => Observable<any>,
    receberLicenca: (idAutocadastro: string, body: IAutoCadastro) => Observable<any>,
}

@Injectable()
export class AutocadastroService extends TemplateApiService<IAutoCadastro> implements IAutoCadastroService {

    public override setBaseUrl(idEquipe: string) {
        this.baseUrl = `equipes/${idEquipe}/autocadastros`;
    }

    public ativar(idAutocadastro: string, ativo: boolean) {
        const url = this.getBaseUrl() + `/${idAutocadastro}`;
        return this.apiService.patch(url, {ativo: ativo});
    }

    public receberLicenca(idAutocadastro: string, body: IAutoCadastro){
        const url = `autocadastro/${idAutocadastro}/receber-licenca`;
        return this.apiService.post(url, body);
    }
}
