import { Component, OnInit, ViewChild } from '@angular/core';
import { ILicenca, LicencasService } from 'src/app/shared/api/licencas/licencas.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { JogadaService } from 'src/app/shared/api/testes/jogada.service';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginatedResponse } from 'src/app/shared/api/pagination.model';
import { filtroPessoalLicencasResource } from './filtro-pessoal-licencas.resource';
import { TestesService } from 'src/app/shared/api/testes/testes-cliente.service';

@Component({
    selector: 'app-pessoal-licencas',
    templateUrl: './pessoal-licencas.component.html',
    styleUrls: ['./pessoal-licencas.component.css']
})
export class PessoalLicencasComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild('cardContent') cardContent!: HTMLDivElement;

    cardOpened = false;
    loading = true;

    licencas: ILicenca[] = [];
    paginacao: PaginatedResponse<any> = { 'total-paginas': 0, pagina: 0, resultados: [], total: 0 };

    filtroPessoalLicencasResource = filtroPessoalLicencasResource;
    filtro: any = {};

    constructor(
        public licencasService: LicencasService,
        private jogadaService: JogadaService,
        private testesService: TestesService,
        private dialog: DialogService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.customizarMatPaginator();

        this.listarTestes();
    }

    private listarTestes(params: any = { pagina: 1, tamanho: 12 }) {
        this.filtro.pagina = params.pagina;
        this.filtro.tamanho = params.tamanho;

        this.loading = true;
        this.paginacao.total = 0;
        this.licencas = [];
        
        this.licencasService.get(params).subscribe({
            next: (response) => {
                this.loading = false;
                this.paginacao = response;
                this.licencas = response.resultados;
            }
        });


        this.testesService.get().subscribe({
            next: (response) => {

            }
        })
    }

    iniciarTeste = (licenca: ILicenca) => {
        if(licenca.data_inicio){
            this.iniciarJogada(licenca);
            return;
        }

        this.dialog
            .confirm('Confirmar', 'Deseja realmente iniciar o jogo agora?')
            .subscribe((confirmado) => {
                if (confirmado) {
                    this.iniciarJogada(licenca);
                }
            });
    };

    iniciarJogada(licenca: ILicenca){
        this.jogadaService.getRodadaAtual(licenca.uuid).subscribe({
            next: (response) => {
                this.goToJogada(licenca.uuid);
            },
            error: () => {
                this.jogadaService.iniciarJogada(licenca.uuid).subscribe({
                    next: (response) => {
                        this.goToJogada(licenca.uuid);
                    }
                });
            }
        });
    }

    changeFiltro(event: any){
        const filtro: any = {
            pagina: this.filtro.pagina || 1,
            tamanho: this.filtro.tamanho || 12,
        }

        if(this.filtro.concluido && this.filtro.concluido != 3) filtro['concluido'] = this.filtro.concluido.id;
        if(this.filtro.teste) filtro['teste'] = this.filtro.teste;

        this.listarTestes(filtro);
    }

    goToJogada(id: string) {
        this.router.navigate([`/play/${id}`]);
    }

    visualizarRelatorio = (licenca: any) => {
        const url = 'relatorios/' + licenca.uuid;
        this.router.navigate([url]);
    };

    public alterarPagina(pageEvent: PageEvent) {
        const tamanho = pageEvent.pageSize;
        const pagina = pageEvent.pageIndex + 1;
        this.listarTestes({ tamanho, pagina });
    }

    private customizarMatPaginator() {
        this.paginator._intl.itemsPerPageLabel = 'Itens por página';

        this.paginator._intl.nextPageLabel = 'Próxima';

        this.paginator._intl.previousPageLabel = 'Anterior';

        this.paginator._intl.firstPageLabel = 'Primeira página';
        this.paginator._intl.lastPageLabel = 'Última página';

        this.paginator._intl.getRangeLabel = this.getRangeLabel;
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 de ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        const label = `${startIndex + 1} - ${endIndex} de ${length}`;
        return label;
    };
}
