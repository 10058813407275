import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardBotoesComponent } from './card-botoes.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
    declarations: [
        CardBotoesComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        CardBotoesComponent
    ]
})
export class CardBotoesModule { }
