import { IColorPicker } from "../model/color.model";

export const hexadecimalToColorPicker = (hex: string): IColorPicker => {
    return {
        hex: hex.replace('#', ''),
        ...hexadecimalToRGBASeparated(hex),
        rgba: hexadecimalToRGBAUnited(hex),
        roundA: 1
    }
}

export interface IRGBA {
    r: number,
    g: number,
    b: number,
    a: number
}

export const hexadecimalToRGBASeparated = (hex: string): IRGBA => {
    hex = hex.replace('#', '');

    // Converte o código hexadecimal em valores separados de vermelho, verde e azul
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Retorna o valor RGB como um objeto
    return { r, g, b, a: 255 };
}

export const hexadecimalToRGBAUnited = (hex: string): string => {
    const rgb = hexadecimalToRGBASeparated(hex);
    return `${rgb.r},${rgb.g},${rgb.b},${rgb.a}`;
}

export const displayColorHexadeciamlToHTMLCircle = (hex: string, width = 20, height = 20) => {
    return `
        <span style="background-color: ${hex}; width: ${width}px; height: ${height}px" class="circle"></span>
    `;
}

export const displayColorHexadeciamlToHTMLCircleWithText = (hex: string, text: string, width?: number, height?: number) => {
    return displayColorHexadeciamlToHTMLCircle(hex, width, height) + `
        <span>${text}</span>
    `
}