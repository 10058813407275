import { displayColorHexadeciamlToHTMLCircle } from 'src/app/shared/functions/color-functions';
import { ColumnDataType, Resource } from '../../shared/model/resource';
import {
    dateToEnglishDateString,
    unformatedStringDateHourToPortugueseDateHourString
} from 'src/app/shared/functions/data-transform';

export const autoCadastroResource: Resource = {
    disableDelete: true,
    disableEdit: true,
    disableNew: true,
    nomeEntidade: {
        singular: 'Acesso Pessoal',
        plural: 'Acessos Pessoais'
    },
    fieldPk: 'uuid',
    route: {
        url: 'acesso-pessoal'
    },
    colunas: [
        {
            field: 'disponivel_de',
            label: 'Disponível de',
            type: ColumnDataType.Date,
            getValueBeforSave: (data) => dateToEnglishDateString(new Date(data)),
            calculateDisplayValue: unformatedStringDateHourToPortugueseDateHourString
        },
        {
            field: 'disponivel_ate',
            label: 'Disponível até',
            type: ColumnDataType.Date,
            getValueBeforSave: (data) => dateToEnglishDateString(new Date(data)),
            calculateDisplayValue: unformatedStringDateHourToPortugueseDateHourString
        },
        {
            field: 'teste.nome',
            label: 'Teste',
            type: ColumnDataType.Lookup,
            sizeColumn: 'col-12',
            required: true,
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'quantidade',
            label: 'Quantidade',
            type: ColumnDataType.Number,
            numberConfiguration: {
                minValue: 0
            },
            required: true,
        },
        {
            field: 'ativo',
            label: 'Ativo',
            type: ColumnDataType.Checkbox,
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
            calculateDisplayValue: (ativo: boolean) => {
                if (ativo) return displayColorHexadeciamlToHTMLCircle('#28a745', 15, 15);
                return displayColorHexadeciamlToHTMLCircle('#dc3545', 15, 15);
            },
            calculateDisplayTooltip: (value) => (value ? 'Ativo' : 'Inativo')
        }
    ],
    actions: [],
    availableReferences: []
};
