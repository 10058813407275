<div class="small-shadow border" style="overflow-x: auto">
    <table
        mat-table
        [dataSource]="entidades"
        class="w-100"
        [ngStyle]="{minWidth: minWidth}"
    >

        <ng-container *ngFor="let column of colunasListadas">
            <ng-container [matColumnDef]="column.field">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="celula-cabecalho"
                    [ngStyle]="{ width: column.sizeCellTable + '%' }"
                >
                    {{ column.label || column.field }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    matTooltipClass="tooltip-celula-body-tabela"
                    [matTooltip]="calculateDisplayTooltip(element, column)"
                    [matTooltipShowDelay]="1000"
                >
                    <div [innerHTML]="calculateDisplayValue(element, column)"></div>
                </td>
            </ng-container>
        </ng-container>

        <ng-container [matColumnDef]="'actions'">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="celula-cabecalho text-center"
            >
                Ações
            </th>

            <td mat-cell *matCellDef="let row" class="text-center">
                <div class="d-flex align-items-center justify-content-center">
                    <ng-container *ngFor="let action of actions">
                        <button
                            *ngIf="isVisibleAction(action, row)"
                            (click)="executeAction(action, row)"
                            mat-mini-fab
                            [color]="action.type"
                            [matTooltip]="action.hint"
                            [matTooltipShowDelay]="1000"
                            class="mr-1 btn-listagem-tabela"
                        >
                            <mat-icon>{{ action.icon }}</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="listaColunasExibir"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: listaColunasExibir"
            class="linha-tabela"
        ></tr>
    </table>
</div>
