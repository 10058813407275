<h2 matDialogTitle>{{ data.titulo }}</h2>

<mat-dialog-content>
    {{data.texto}}
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end">
    <button mat-button [mat-dialog-close]="false" class="me-2">
        {{data.labelCancel || 'Cancelar'}}
    </button>

    <ng-container *ngIf="data.tipoBtn == 'danger'">
        <button mat-raised-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>
            {{data.labelOk || 'Confirmar'}}
        </button>
    </ng-container>

    <ng-container *ngIf="data.tipoBtn == 'primary'">
        <primary-button [mat-dialog-close]="true" cdkFocusInitial>
            {{data.labelOk || 'Confirmar'}}
        </primary-button>
    </ng-container>

</mat-dialog-actions>
