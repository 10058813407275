import { Component, OnInit } from '@angular/core';
import { TestesService } from 'src/app/shared/api/testes/testes.service';
import { testeResource } from './teste.resource';

@Component({
    selector: 'app-testes',
    templateUrl: './testes.component.html',
    styleUrls: ['./testes.component.css']
})
export class TestesComponent implements OnInit {

    testesResource = testeResource;

    constructor(public testesService: TestesService) { }

    ngOnInit(): void {
    }

}
