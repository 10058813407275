import { Component, OnInit } from '@angular/core';
import { termoDeUsoResource } from './termo.resource';
import { AdmTermosDeUsoService } from 'src/app/shared/api/adm-termos-de-uso.service';

@Component({
    selector: 'app-termo-de-uso',
    templateUrl: './termo-de-uso.component.html',
    styleUrls: ['./termo-de-uso.component.css']
})
export class TermoDeUsoComponent implements OnInit {

    termoDeUsoResource = termoDeUsoResource;

    constructor(public termosDeUsoService: AdmTermosDeUsoService){}

    ngOnInit(): void {}
}
