import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    ColumnDataType,
    ConfigurationColumn,
    ConfigurationRows,
    Resource
} from '../../model/resource';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionToolbar } from '../toolbar/toolbar.component';
import { Observable } from 'rxjs';
import { FormularioDinamicoComponent } from '../formulario-dinamico/formulario-dinamico.component';
import { NotificationService } from '../../services/notificationService';
import { TemplateApiService } from '../../api/template-api.service';
import { englishDateStringToDate } from '../../functions/data-transform';
import { DialogService } from '../../services/dialog.service';
import { Location } from '@angular/common';

@Component({
    selector: 'edit-component',
    templateUrl: './edit-component.component.html',
    styleUrls: ['./edit-component.component.css']
})
export class EditComponentComponent implements OnInit, OnChanges {
    @ViewChild('formulario', { static: false })
    formulario!: FormularioDinamicoComponent;

    @Input() rota!: string;
    @Input() valueExpr: string = 'uuid';
    @Input() resource!: Resource;
    @Input() id: string | null = null;
    @Input() entidade: any = {};
    @Input() entidadeOriginal: any = {};
    @Input() service!: TemplateApiService<any>;
    @Input() actionsIncrement: ActionToolbar[] = [];
    // mude o fieldId para outro quando a sua rota possuir mais de um :id
    @Input() fieldId = 'id';

    @Output() idChange = new EventEmitter<string | null>();
    @Output() entidadeChange = new EventEmitter<any>();
    @Output() entidadeOriginalChange = new EventEmitter<any>();

    colunas: ConfigurationColumn[] = [];
    colunasVisiveis: ConfigurationColumn[] = [];
    linhasVisiveis: ConfigurationRows[] = [];
    actions: ActionToolbar[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private notify: NotificationService,
        private dialog: DialogService
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get(this.fieldId);
        this.idChange.emit(this.id);

        this.configurarColunas();
        this.configureActions();
        if (this.id) this.getEntidade();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.configureActions();
    }

    configurarColunas() {
        this.colunas = this.resource.colunas;

        if (this.id) {
            this.colunasVisiveis = this.colunas.filter(
                (coluna) => !coluna.unAvailableOnEdit
            );

            this.linhasVisiveis = this.resource.linhas?.map(linha => {
                let colunasVisiveis = linha.colunas.filter(coluna => !coluna.unAvailableOnEdit);
                return {
                    colunas: colunasVisiveis,
                    sizeColumn: linha.sizeColumn
                }
            }) || [];

        } else {
            this.colunasVisiveis = this.colunas.filter(
                (coluna) => !coluna.unAvailableOnCreate
            );

            this.linhasVisiveis = this.resource.linhas?.map(linha => {
                let colunasVisiveis = linha.colunas.filter(coluna => !coluna.unAvailableOnCreate);
                return {
                    colunas: colunasVisiveis,
                    sizeColumn: linha.sizeColumn
                }
            }) || [];
        }
    }

    configureActions() {
        this.actions = [];

        this.actionsIncrement.forEach((action) => {
            this.actions.push(action);
        });

        this.actions.push({
            type: 'primary',
            icon: 'save',
            text: 'Salvar',
            onClick: this.salvar()
        });

        if (!this.resource.disableDelete && this.isNotEmptyObj(this.entidade)) {
            this.actions.push({
                icon: 'delete',
                text: 'Excluir',
                type: 'warn',
                onClick: this.excluir()
            });
        }
    }

    public reload() {
        this.getEntidade();
    }

    private getEntidade() {
        if (this.id) {
            this.service.detail(this.id).subscribe({
                next: (response: any) => {
                    this.entidade = this.tratarEntidade(response);
                    this.entidadeOriginal = response;
                    this.entidadeChange.emit(this.entidade);
                    this.entidadeOriginalChange.emit(this.entidadeOriginal);
                    this.configureActions();
                }
            });
        }
    }

    private excluir() {
        return new Observable<Object>((observer) => {
            this.dialog
                .confirm(
                    'Confirmar Exclusão',
                    'Tem certeza que deseja excluir o item atual?'
                )
                .subscribe({
                    next: (result: boolean) => {
                        if (result) {
                            this.service.delete(this.id!).subscribe(
                                (response: any) => {
                                    observer.next();
                                    if (!this.rota)
                                        throw new Error(
                                            'Defina a variável rota'
                                        );
                                    else this.goBack();
                                },
                                (error) => {
                                    this.notify.error(error.error.erro);
                                    observer.error();
                                }
                            );
                        }
                    }
                });
        });
    }

    private tratarEntidade(entidade: any) {
        const entidadeTratada: any = {};
        Object.keys(entidade).forEach((key) => {
            let value = entidade[key];
            const columnResource: ConfigurationColumn | undefined =
                this.colunas.find((coluna) => coluna.field == key);
            if (columnResource) {
                if (columnResource.getValueBeforEntity)
                    value = columnResource.getValueBeforEntity(entidade[key]);

                if (columnResource.type == ColumnDataType.Date)
                    value = englishDateStringToDate(value);

                entidadeTratada[key] = value;
            }
        });
        return entidadeTratada;
    }

    private salvar() {
        return new Observable<Object>((observer) => {
            if (!this.formulario.isValid()) {
                observer.error('Formulário inválido');
                return;
            }

            let routeObservable;
            const dadosFormulario = this.formulario.getDadosFormularioSalvar();
            if (!this.id) {
                routeObservable = this.service.create(dadosFormulario);
            } else {
                routeObservable = this.service.update(this.id, dadosFormulario);
            }

            routeObservable.subscribe({
                next: (response: any) => {
                    observer.next(response);

                    if (!this.id) {
                        this.navigateEdit(response[this.valueExpr] as string);
                    } else {
                        this.entidade = this.tratarEntidade(response);
                        this.entidadeOriginal = response;
                        this.entidadeChange.emit(this.entidade);
                        this.entidadeOriginalChange.emit(this.entidadeOriginal);
                    }
                    this.notify.success('Formulario salvo com sucesso');
                },
                error: (e) => {
                    this.formulario.tratarErroRequisicao(e);
                    this.notify.error(e.error.erro);
                    observer.error(e);
                }
            });
        });
    }

    protected navigateEdit(id: string): void {
        if (!this.rota) throw new Error('Variável rota não definida');
        this.router.navigate([this.rota + '/' + id], { replaceUrl: true });
    }

    private goBack() {
        this.location.back();
    }

    private isNotEmptyObj(obj: any) {
        return obj && Object.keys(obj).length !== 0;
    }
}
