import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditComponentComponent } from './edit-component.component';
import { CardModule } from '../card/card.module';
import { FormularioDinamicoModule } from '../formulario-dinamico/formulario-dinamico.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { ContainerPadraoModule } from "../container-padrao/container-padrao.module";



@NgModule({
    declarations: [
        EditComponentComponent
    ],
    imports: [
    CommonModule,
    CardModule,
    FormularioDinamicoModule,
    ToolbarModule,
    ContainerPadraoModule
],
    exports: [
        EditComponentComponent
    ]
})
export class EditComponentModule { }
