import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableListComponent } from './table-list.component';
import { TabelaListagemModule } from '../tabela-listagem/tabela-listagem.module';
import { ButtonModule } from '../button/button.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { ContainerPadraoModule } from '../container-padrao/container-padrao.module';
import { CardModule } from '../card/card.module';

@NgModule({
    declarations: [TableListComponent],
    exports: [TableListComponent],
    imports: [
        CommonModule,
        TabelaListagemModule,
        ButtonModule,
        ToolbarModule,
        ContainerPadraoModule,
        CardModule
    ]
})
export class TableListModule {}
