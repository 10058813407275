
<container-padrao>

    <h2>Selecionar Acesso:</h2>
    <hr>

    <loader-page [show]="showLoaderAcessos"></loader-page>

    <div class="row mt-5 w-100" *ngIf="!showLoaderAcessos">
        <div class="col-lg-3 col-md-4 col-sm-6 mt-3" *ngFor="let acesso of listaAcessos">
            <div class="card card-acesso" (click)="selecionarCard(acesso)">
                <div class="card-body">
                    <h2 class="card-title titulo">{{acesso.tipo}}</h2>
                    <p class="card-text">
                        Criado em:
                        {{convertData(acesso.data_criacao)}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</container-padrao>
