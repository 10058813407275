import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css'],
    animations: [
        trigger('cardAnimation', [
            state('collapsed', style({
                height: '0',
                paddingTop: '0',
                paddingBottom: '0',
                opacity: '0',
                overflow: 'hidden'
            })),
            state('expanded', style({
                height: '*',
                paddingTop: '1em',
                paddingBottom: '2em',
                opacity: '1',
                overflow: 'visible'
            })),
            transition('collapsed <=> expanded', animate('300ms ease-out'))
        ]),
    ]
})
export class CardComponent implements OnInit {

    @ViewChild('cardContent') cardContent!: HTMLDivElement;

    @Input() title?: string | null;
    @Input() cardOpened = true;
    @Input() class: string = '';

    constructor() { }

    ngOnInit(): void {
    }

    toggleCard() {
        this.cardOpened = !this.cardOpened;
    }

}
