<edit-component
    [(entidadeOriginal)]="entidade"
    [resource]="equipeResource"
    [service]="equipeService"
    (entidadeOriginalChange)="changeEntidadeOriginal($event)"
    rota="equipes"
></edit-component>

<liberar-licenca-equipe *ngIf="entidade" [idEquipe]="entidade.uuid"></liberar-licenca-equipe>

<autocadastro
    *ngIf="entidade && showAutocadastro"
    [autocadastroService]="autocadastroService"
    [licencasService]="licencasDisponiveisService"
    [modo]="'cliente'"
></autocadastro>
