import { Injectable } from "@angular/core";
import { TemplateApiService } from "./template-api.service";
import { IAutoCadastro, IAutoCadastroService } from "./autocadastro.service";
import { Observable } from "rxjs";



@Injectable()
export class AdmAutocadastroService extends TemplateApiService<IAutoCadastro> implements IAutoCadastroService {

    private idEquipe!: string;

    public override setBaseUrl(idEquipe: string) {
        this.baseUrl = `admin/autocadastros`;
        this.idEquipe = idEquipe;
    }

    override create(body: any): Observable<IAutoCadastro> {
        body['equipe'] = this.idEquipe;
        return super.create(body);
    }

    override update(id: string, body: any): Observable<IAutoCadastro> {
        body['equipe'] = this.idEquipe;
        return super.update(id, body);
    }

    public ativar(idAutocadastro: string, ativo: boolean) {
        const url = this.getBaseUrl() + `/${idAutocadastro}`;
        return this.apiService.patch(url, {ativo: ativo});
    }

    public receberLicenca(idAutocadastro: string, body: IAutoCadastro){
        const url = `autocadastro/${idAutocadastro}/receber-licenca`;
        return this.apiService.post(url, body);
    }
}
