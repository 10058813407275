import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [CheckboxComponent],
    imports: [CommonModule, FormsModule, MatCheckboxModule],
    exports: [CheckboxComponent]
})
export class CheckboxModule {}
