import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfigurationColumn } from '../../model/resource';
import { IDialogData, ModalComponent } from '../modal/modal.component';
import { TemplateApiService } from '../../api/template-api.service';
import { TabelaListagemComponent } from '../tabela-listagem/tabela-listagem.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'lookup',
    templateUrl: './lookup.component.html',
    styleUrls: [
        './lookup.component.css',
        './../formulario-dinamico/formulario-dinamico.component.css'
    ]
})
export class LookupComponent implements OnInit, OnChanges {

    @ViewChild('modal') modal!: ModalComponent;
    @ViewChild('tabela') tabela!: TabelaListagemComponent;

    @Input() parentFormGroup!: FormGroup;
    @Input() coluna!: ConfigurationColumn;
    @Input() value: any;
    @Input() service!: TemplateApiService<any>;
    @Input() entidade: any;

    @Output() valueChange = new EventEmitter();

    modalData!: IDialogData;
    
    textoInput = '';

    constructor(private injector: Injector) {
    }

    ngOnInit(): void {
        const titulo = this.coluna?.label || this.coluna?.field;
        this.modalData = {
            titulo: `Selecionar ${titulo}`,
            labelOk: 'Selecionar'
        }

        if(!this.service)
            this.service = this.injector.get(this.coluna.lookupConfiguration!.service);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['value']){
            this.setSelected(changes['value'].currentValue);
        }
    }

    finalizouListagemTabela = () => {
        if(this.value) {
            this.tabela.setLinhasSelecionadas([this.value]);
        }
    }

    openModal() {
        this.tabela.listarEntidades();
        this.modal.open();
    }

    saveLookupComponent = () => {
        return new Observable(observer => {
            const linhasSelecionadas = this.tabela.getLinhasSelecionadas();
            this.setSelected(linhasSelecionadas.length > 0 ? linhasSelecionadas[0] : null);
            observer.next();
        })
    }

    private setSelected(value: any){
        if(value){
            const texto = value[this.coluna.lookupConfiguration!.fieldName];
            this.value = value;
            this.textoInput = texto;
            this.valueChange.emit(this.value);
        } else {
            this.value = null;
            this.textoInput = '';
            this.valueChange.emit(null);
        }
    }
}
