<div class="col-sm-6 esquerda p-0">
    <slider-midia *ngIf="!showLoader" [midias]="midias" class="area-midias"></slider-midia>

    <div *ngIf="showLoader" class="d-grid align-items-center justify-content-center">
        <loader-page [show]="true"></loader-page>
    </div>
</div>

<div class="col-sm-6 direita p-0" *ngIf="entity">
    <progresso *ngIf="progresso > 0" [valor]="progresso"></progresso>

    <div class="d-grid align-items-center ps-4 pe-3 pb-2">
        <ng-container *ngIf="!disableBefore">
            <button (click)="backFunction()" mat-mini-fab color="basic" class="back-button">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
        </ng-container>

        <h1 
            class="titulo w-100"
            [ngClass]="{'titulo-abaixo-do-btn-back': !disableBefore}"
        >{{entity.nome}}</h1>
    
        <div class="descricao" [innerHTML]="entity.descricao | safeHtmlJogada"></div>
    
        <btn-continuar (click)="onClickContinuar()" class="mt-4"></btn-continuar>
    </div>
    
    <div *ngIf="!entity" class="d-grid align-items-center justify-content-center">
        <loader-page [show]="true"></loader-page>
    </div>
</div>