<form class="create-account-form">

    <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input [required]="true" [(ngModel)]="formData.username" name="username" [placeholder]="'E-mail'" type="email" matInput>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Nome completo</mat-label>
        <input [(ngModel)]="formData.nome" name="nome" matInput>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Data de nascimento</mat-label>
        <input [(ngModel)]="formData.data_de_nascimento" name="data_de_nascimento" matInput [matDatepicker]="picker">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <mat-label>CPF</mat-label>
        <input [required]="true" [(ngModel)]="formData.cpf" name="cpf" mask="000.000.000-00" matInput>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Telefone</mat-label>
        <input [(ngModel)]="formData.telefone" name="telefone" type="tel" mask="(00) 00000-0000" matInput>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Sexo</mat-label>
        <mat-select [(ngModel)]="formData.sexo" name="sexo">
            <mat-option value="M">Masculino</mat-option>
            <mat-option value="F">Feminino</mat-option>
            <mat-option value="O">Outro</mat-option>
            <mat-option value="N">Não responder</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Empregabilidade</mat-label>
        <mat-select [(ngModel)]="formData.empregabilidade" name="empregabilidade">
            <mat-option value="true">Sim</mat-option>
            <mat-option value="false">Não</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Escolaridade</mat-label>
        <mat-select [(ngModel)]="formData.escolaridade" name="escolaridade">
            <mat-option *ngFor="let escolaridade of escolaridades"
                [value]="escolaridade.value">{{escolaridade.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label >Senha</mat-label>

        <input required="true" [(ngModel)]="formData.senha" name="senha" matInput [type]="hide ? 'password' : 'text'">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Confirmar Senha</mat-label>

        <input required="true" [(ngModel)]="formData.confirmar_senha" name="confirmar_senha" matInput [type]="hide ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>

    <button [disabled]="loading" (click)="onSubmit($event)" color="primary" mat-raised-button type="submit" style="width: 100%;">
        <div *ngIf="!loading">
            Criar conta
        </div>

        <div *ngIf="loading" style="width: 100%; display: flex; justify-content: center; padding: 0.3em">
            <mat-spinner [diameter]="30" color="accent"></mat-spinner>
        </div>
    </button>

    <div class="mt-5">
        Possui uma conta? <a routerLink="/login">Login</a>
    </div>

</form>
