import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerguntasComponent } from './perguntas.component';
import { ButtonModule } from "../../shared/components/button/button.module";
import { CardBotoesModule } from "../../shared/components/card-botoes/card-botoes.module";
import { MatTabsModule } from '@angular/material/tabs';
import { MidiasModule } from "../midias/midias.module";
import { PerguntaService } from 'src/app/shared/api/testes/perguntas.service';
import { ModalModule } from "../../shared/components/modal/modal.module";
import { FormularioDinamicoModule } from "../../shared/components/formulario-dinamico/formulario-dinamico.module";
import { AlternativasModule } from "../alternativas/alternativas.module";



@NgModule({
    declarations: [
        PerguntasComponent
    ],
    exports: [
        PerguntasComponent
    ],
    providers: [
        PerguntaService
    ],
    imports: [
        CommonModule,
        ButtonModule,
        CardBotoesModule,
        MatTabsModule,
        MidiasModule,
        ModalModule,
        FormularioDinamicoModule,
        AlternativasModule
    ]
})
export class PerguntasModule { }
