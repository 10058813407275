import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientesComponent } from './clientes.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { ClienteFormComponent } from './cliente-form/cliente-form.component';
import { FormsEquipesClienteComponent } from './forms-equipes-cliente/forms-equipes-cliente.component';

const routes: Routes = [
    {
        path: 'clientes',
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: ClientesComponent,
            },
            {
                path: 'novo',
                component: ClienteFormComponent,
            },
            {
                path: ':id',
                component: ClienteFormComponent,
            },
            {
                path: ':id/equipes',
                children: [
                    {
                        path: 'novo',
                        component: FormsEquipesClienteComponent
                    },
                    {
                        path: ':idEquipe',
                        component: FormsEquipesClienteComponent
                    },
                ]
            }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientesRoutingModule { }
