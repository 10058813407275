import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {
    constructor(private snackBar: MatSnackBar) {}

    error(message: any, btnMessage: string = '') {
        if (this.validarString(message)) {
            this.snackBar.open(message, btnMessage, {
                panelClass: ['error-snackbar'],
                duration: 4000
            });
        }
    }

    success(message: string, btnMessage: string = '') {
        if (this.validarString(message)) {
            this.snackBar.open(message, btnMessage, {
                panelClass: ['success-snackbar'],
                duration: 4000
            });
        }
    }

    private validarString(text: any) {
        return text != undefined && text != null && typeof text == 'string';
    }
}
