import { Component, Input, ViewChild } from '@angular/core';
import { ActionsTabela, TabelaListagemComponent } from '../tabela-listagem/tabela-listagem.component';
import { TemplateApiService } from '../../api/template-api.service';
import { Resource } from '../../model/resource';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notificationService';
import { DialogService } from '../../services/dialog.service';

@Component({
    selector: 'table-list',
    templateUrl: './table-list.component.html',
    styleUrls: ['./table-list.component.css']
})
export class TableListComponent {
    @ViewChild('tabela') tabela!: TabelaListagemComponent;

    @Input() service!: TemplateApiService<any>;
    @Input() resource!: Resource;
    @Input() fieldPk: string = 'uuid';
    @Input() rotaNovo: string = 'novo';
    @Input() rotaEdit: string = '';

    actions: ActionsTabela[] = [];
    actionsTabela: ActionsTabela[] = [];
    apiSubscription: any;

    // protected id: string | null = null;
    // protected entity: string | null = null;
    // protected targetEntity: string | null = null;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected notify: NotificationService,
        private dialog: DialogService
    ) {
    }

    ngOnInit(): void {
        this.listarEntidades();
    }

    ngOnDestroy(): void {
        if (this.apiSubscription) this.apiSubscription.unsubscribe();
    }

    public listarEntidades() {
        this.criarActions();

        ///this.id = this.route.snapshot.params['id'];
        ///this.entity = this.route.snapshot.params['entity'];
        ///this.targetEntity = this.route.snapshot.params['targetEntity'];
    }

    protected criarActions() {
        this.actions = [];

        if (!this.resource.disableNew) {
            this.actions.push({
                type: 'primary',
                icon: 'plus',
                mode: 'batch',
                text: '',
                hint: 'Adicionar',
                onClick: this.onClickNovo
            });
        }

        if (!this.resource.disableEdit) {
            this.actions.push({
                mode: 'single',
                text: '',
                hint: 'Editar ' + this.resource.nomeEntidade.singular,
                icon: 'edit',
                type: 'primary',
                onClick: this.editar
            })
        }

        if (!this.resource.disableDelete) {
            this.actions.push({
                mode: 'single',
                text: '',
                hint: 'Excluir ' + this.resource.nomeEntidade.singular,
                icon: 'delete',
                type: 'warn',
                onClick: this.excluir
            });
        }

        // this.resource.actions?.forEach(action => {
        //     this.actions.push({
        //         ...action,
        //         onClick: ($event: any) => action.onClick(($event || null), this.service, this.router, this.route)
        //     })
        // })

        this.resource.actions?.forEach(action => {
            this.actions.push(action);
        })

        this.configureActions();
    }

    private configureActions() {
        this.actionsTabela = this.actions.filter(action => action.mode == 'single');
    }

    public editar = (data: any) => {
        this.navigateEdit(data);
    }

    public onClickNovo = () => {
        this.navigateNew();
    }

    public excluir = (data: any) => {
        const entidade = data;
        this.dialog.confirm('Confirmar exclusão', 'Tem certeza que deseja excluir? Não será possível desfazer a alteração.').subscribe({
            next: (confirm: boolean) => {
                if(confirm){
                    const idEntidade = entidade[this.resource!.fieldPk];
                    this.apiSubscription = this.service.delete(idEntidade).subscribe({
                        next: () => {
                            this.tabela.listarEntidades();
                        },
                        error: e => {
                            this.notify.error(e.error.erro);
                        }
                    });
                }
            }
        })
    }


    // protected getUrlOperacoes(): string {
    //     if (this.resource!.route.targetUrl) return this.resource!.route.targetUrl + '/' + this.id + '/' + this.resource!.route.url;
    //     return this.resource!.route.url;
    // }

    protected navigateEdit(data: any): void {
        const idEntidade = data[this.resource.fieldPk];
        //const route = this.resource.route.url + '/' + idEntidade;
        const route = this.rotaEdit + idEntidade;
        this.router.navigate([route], { relativeTo: this.route });
    }

    protected navigateNew(): void {
        ///const route = this.resource.route.url + '/novo';
        this.router.navigate([this.rotaNovo], { relativeTo: this.route });
    }
}
