import { ColumnDataType, ConfigurationColumn } from "src/app/shared/model/resource";


export const autocadastroFormColumns: ConfigurationColumn[] = [
    {
        field: 'email',
        label: 'E-mail',
        type: ColumnDataType.Text,
        validation: [
            {type: "email", message: "E-mail inválido"}
        ],
        sizeColumn: 'col-12',
        required: true,
    },
    {
        field: 'nome',
        label: 'Nome',
        type: ColumnDataType.Text,
        sizeColumn: 'col-12',
        required: true,
    },
    {
        field: 'telefone',
        label: 'Telefone',
        type: ColumnDataType.Text,
        sizeColumn: 'col-12',
        required: true,
    },
]