import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'loader-page',
    templateUrl: './loader-page.component.html',
    styleUrls: ['./loader-page.component.css']
})
export class LoaderPageComponent implements OnInit {

    @Input() show = false;

    constructor() { }

    ngOnInit(): void {
    }

}
