<div class="d-flex mt-2 justify-content-end">
	<primary-button (click)="abrirModalCriarAlternativa()"
		icon="add"
		text="Adicionar Alternativa"></primary-button>
</div>

<div class="mt-2">
	<tabela-listagem #tabelaAlternativas [actions]="actions"
		[colunas]="alternativasResource.colunas"
		[service]="alternativaService"
		></tabela-listagem>
</div>

<modal #modal
	[data]="dataModal"
	[onSave]="onSaveAlternativa()">
	<formulario-dinamico #formularioAlternativa
		*ngIf="showFormModal"
		[colunas]="colunasFormulario"
		[(entidade)]="entidadeAlternativa"></formulario-dinamico>
</modal>
