<div class="card-material" [ngClass]="{'d-none': !imageLoaded}">

    <img (load)="loadImage()" class="background-sp" [src]="urlCapa" [ngStyle]="{
        'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
    }">

    <div class="card-title" [ngClass]="{'card-content-bloqueado': !disponivel}">{{ material.nome }}</div>

    <img *ngIf="!disponivel" class="cadeado" src="assets/img/cadeado.svg">

    <div class="card-actions">
        <div class="row">
            <div>
                <button mat-mini-fab color="primary" (click)="iniciar()" [disabled]="!disponivel">
                    <mat-icon>play_arrow</mat-icon>
                </button>
                <span class="ms-3">Iniciar</span>
            </div>
        </div>
    </div>
</div>


<div class="skeleton-card" *ngIf="!imageLoaded">
    <div class="skeleton-image" [ngStyle]="{
            'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
        }"></div>
    <div class="skeleton-text">
        <div class="skeleton-line short"></div>
        <div class="skeleton-line"></div>
        <div class="skeleton-line"></div>
    </div>
</div>