import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnDataType, ConfigurationColumn } from 'src/app/shared/model/resource';
import { ClientesService, ICliente } from 'src/app/shared/api/clientes.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AcessosPessoaisClientesService } from 'src/app/shared/api/acessos-pessoais-clientes.service';
import { ActionToolbar } from 'src/app/shared/components/toolbar/toolbar.component';
import { NotificationService } from 'src/app/shared/services/notificationService';
import { Observable, Subscriber } from 'rxjs';
import { AdmLicencasService } from 'src/app/shared/api/licencas/adm-licencas.service';
import { TestesAtivosService } from 'src/app/shared/api/testes/testes.service';
import { LicencasDisponiveisAdmService } from 'src/app/shared/api/licencas/licencas-disponiveis-adm.service';
import { AdmEquipeService } from 'src/app/shared/api/adm-equipe.service';
import { acessoPessoalFormResource } from './acesso-pessoal-form.resource';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { TabelaListagemComponent } from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { ILicenca } from 'src/app/shared/api/licencas/licencas.service';
import { FormularioDinamicoComponent } from 'src/app/shared/components/formulario-dinamico/formulario-dinamico.component';
import { dateToEnglishDateString } from 'src/app/shared/functions/data-transform';

interface IAcessoPessoalForm {
    username: string,
    cpf: string,
    nome: string,
    uuid?: string
}

@Component({
    selector: 'app-adm-licencas-form',
    templateUrl: './adm-licencas-form.component.html',
    styleUrls: [
        './adm-licencas-form.component.css',
        '../../../shared/components/formulario-dinamico/formulario-dinamico.component.css'
    ]
})
export class AdmLicencasFormComponent implements OnInit {
    @ViewChild('modalSelecionarAcessos') modalSelecionarAcessos!: ModalComponent;
    @ViewChild('modalAdicionarAcessoPessoal') modalAdicionarAcessoPessoal!: ModalComponent;
    @ViewChild('listaAcessosPessoais') listaAcessosPessoais!: TabelaListagemComponent;
    @ViewChild('formularioAdicionarAcessoPessoal') formularioAdicionarAcessoPessoal!: FormularioDinamicoComponent;

    grupoTabSelecionada = 0;
    readonly grupoTabAssociarCliente = 0;
    readonly grupoTabAssociarAcessoPessoal = 1;

    quantidadeMaximaLicencas: any = null;

    colunaAcessosClientes!: ConfigurationColumn;
    colunaTestesDisponiveis!: ConfigurationColumn;
    colunaQuantidadeLicencas!: ConfigurationColumn;
    colunaLicencasDisponiveis!: ConfigurationColumn;
    colunaEquipesCliente!: ConfigurationColumn;
    colunaDisponivelDe!: ConfigurationColumn;
    colunaDisponivelAte!: ConfigurationColumn;

    formulario: any;
    cliente: any;
    teste: any;
    licenca: any;
    equipe: any;
    quantidadeLicencas: any;
    disponivelDe: any;
    disponivelAte: any;

    actionsToolbar: ActionToolbar[] = [];
    acessoPessoalFormResource = acessoPessoalFormResource;
    acessoPessoalEntidadeForm: IAcessoPessoalForm = { cpf: '', nome: '', username: '' };

    acessosPessoaisSelecionados: IAcessoPessoalForm[] = [];

    modalSelecionarAcessosData: IDialogData = {
        titulo: 'Selecionar acessos'
    };

    modalAdicionarAcessoPessoalData: IDialogData = {
        titulo: 'Adicionar Acesso Pessoal'
    };

    constructor(
        private notify: NotificationService,
        private admLicencasService: AdmLicencasService,
        public acessosPessoaisClienteService: AcessosPessoaisClientesService,
        public licencasDisponiveisAdmService: LicencasDisponiveisAdmService,
        public admEquipeService: AdmEquipeService,
        public TestesAdmService: TestesAtivosService
    ) {}

    ngOnInit(): void {
        this.TestesAdmService.setBaseUrl({ ativo: true });

        this.actionsToolbar.push({
            icon: 'save',
            text: 'Liberar licença',
            type: 'primary',
            onClick: this.liberarLicenca()
        });

        this.colunaAcessosClientes = {
            field: 'cliente',
            label: 'Cliente',
            type: ColumnDataType.Lookup,
            lookupConfiguration: {
                colunas: [
                    {
                        field: 'razao_social',
                        label: 'Razão Social',
                        required: true
                    },
                    {
                        field: 'nome_fantasia',
                        label: 'Nome Fantasia',
                        required: true
                    },
                    {
                        field: 'cnpj',
                        label: 'CNPJ',
                        required: true
                    }
                ],
                fieldName: 'razao_social',
                fieldPk: 'uuid',
                service: ClientesService
            }
        };

        this.colunaTestesDisponiveis = {
            field: 'teste',
            label: 'Teste',
            type: ColumnDataType.Lookup,
            lookupConfiguration: {
                colunas: [
                    {
                        field: 'nome',
                        label: 'Nome',
                        required: true
                    },
                    {
                        field: 'descricao',
                        label: 'Descrição',
                        required: true
                    }
                ],
                fieldName: 'nome',
                fieldPk: 'uuid',
                service: null
            }
        };

        this.colunaQuantidadeLicencas = {
            field: 'quantidade',
            label: 'Quantidade de licenças',
            type: ColumnDataType.Number,
            numberConfiguration: {
                minValue: 0
            }
        };

        this.colunaLicencasDisponiveis = {
            field: 'licencas',
            label: 'Licença disponível',
            type: ColumnDataType.Lookup,
            lookupConfiguration: {
                colunas: [
                    {
                        field: 'teste',
                        label: 'Teste'
                    },
                    {
                        field: 'disponiveis',
                        label: 'Qtd Disponíveis'
                    }
                ],
                fieldName: 'teste',
                fieldPk: 'uuid',
                service: null
            }
        };

        this.colunaEquipesCliente = {
            field: 'equipe',
            label: 'Equipe (Opcional)',
            type: ColumnDataType.Lookup,
            lookupConfiguration: {
                colunas: [
                    {
                        field: 'nome',
                        label: 'Equipe'
                    }
                ],
                fieldName: 'nome',
                fieldPk: 'uuid',
                service: null
            }
        };

        this.colunaDisponivelDe = {
            field: 'disponivel_de',
            label: 'Disponível de',
            type: ColumnDataType.Date,
        }

        this.colunaDisponivelAte = {
            field: 'disponivel_ate',
            label: 'Disponível até',
            type: ColumnDataType.Date,            
        }

        this.configureFormGroup();
    }

    private configureFormGroup() {
        const grupo: any = {};

        grupo[this.colunaAcessosClientes.field] = new FormControl('');
        grupo[this.colunaTestesDisponiveis.field] = new FormControl('');
        grupo[this.colunaLicencasDisponiveis.field] = new FormControl('');
        grupo[this.colunaEquipesCliente.field] = new FormControl('');
        grupo[this.colunaDisponivelDe.field] = new FormControl('');
        grupo[this.colunaDisponivelAte.field] = new FormControl('');

        const validatorsQuantidadeLicencas: any = [];
        if (this.colunaQuantidadeLicencas.numberConfiguration?.minValue)
            validatorsQuantidadeLicencas.push(
                Validators.min(this.colunaQuantidadeLicencas.numberConfiguration?.minValue!)
            );
        grupo[this.colunaQuantidadeLicencas.field] = new FormControl(
            '',
            validatorsQuantidadeLicencas
        );

        this.formulario = new FormGroup(grupo);
    }

    private camposAcessoClienteValidate() {
        let isOk = true;
        if (!this.cliente) {
            isOk = false;
            this.notify.error('Selecione um cliente');
        } else if (!this.teste) {
            isOk = false;
            this.notify.error('Selecione um teste');
        } else if (!this.quantidadeLicencas) {
            isOk = false;
            this.notify.error('Digite uma quantidade de licenças');
        }

        return isOk;
    }

    private camposTabAcessoPessoalValidate() {
        let isOk = true;
        if (!this.cliente) {
            isOk = false;
            this.notify.error('Selecione um cliente');
        } else if (this.acessosPessoaisSelecionados.length == 0) {
            isOk = false;
            this.notify.error('Selecione um acesso');
        } else if (!this.licenca) {
            isOk = false;
            this.notify.error('Selecione uma licença!');
        }

        return isOk;
    }

    liberarLicencaAcessoPessoal(observer: Subscriber<unknown>) {
        if (!this.camposTabAcessoPessoalValidate()) {
            observer.error();
            return;
        }

        this.admLicencasService
            .vincularLicencasAcessoPessoal({
                acessos_pessoais: this.acessosPessoaisSelecionados,
                dono: this.cliente.uuid,
                teste: this.licenca.uuid,
                equipe: this.equipe?.uuid,
                disponivel_de: this.disponivelDe ? dateToEnglishDateString(this.disponivelDe) : null,
                disponivel_ate: this.disponivelAte ? dateToEnglishDateString(this.disponivelAte) : null
            })
            .subscribe({
                next: (response) => {
                    this.notify.success('Licença liberada com sucesso!');
                    this.cliente = null;
                    this.licenca = null;
                    this.acessosPessoaisSelecionados = [];
                    observer.next();
                },
                error: () => {
                    observer.error();
                }
            });
    }

    liberarLicencaAcessoCliente(observer: Subscriber<unknown>) {
        if (!this.camposAcessoClienteValidate()) {
            observer.error();
            return;
        }

        this.admLicencasService
            .vincularLicencasAcessoClientes({
                quantidade: this.quantidadeLicencas,
                dono: this.cliente.uuid,
                teste: this.teste.uuid,
                equipe: this.equipe?.uuid
            })
            .subscribe({
                next: (response) => {
                    this.notify.success('Licenças liberadas com sucesso!');
                    observer.next();
                },
                error: (error) => {
                    observer.error(error);
                }
            });
    }

    liberarLicenca = () => {
        return new Observable((observer) => {
            if (this.grupoTabSelecionada == this.grupoTabAssociarAcessoPessoal) {
                this.liberarLicencaAcessoPessoal(observer);
            } else if (this.grupoTabSelecionada == this.grupoTabAssociarCliente) {
                this.liberarLicencaAcessoCliente(observer);
            }
        });
    };

    changeCliente(cliente: ICliente | null) {
        if (cliente) {
            this.acessosPessoaisClienteService.setBaseUrl(this.cliente?.uuid);
            this.licencasDisponiveisAdmService.setBaseUrl(this.cliente?.uuid, { equipe: null });
            this.admEquipeService.setBaseUrl(this.cliente?.uuid);
            this.licenca = null;
            this.quantidadeMaximaLicencas = null;
        }
    }

    changeSelectEquipe(equipe: any) {
        if (equipe)
            this.licencasDisponiveisAdmService.setBaseUrl(this.cliente.uuid, {
                equipe: this.equipe.uuid
            });
    }

    changeLicencasDisponiveis(licenca: ILicenca | null) {
        if (licenca) this.quantidadeMaximaLicencas = this.licenca.total;
    }

    openModalCadastrarAcesso(){
        this.modalAdicionarAcessoPessoal.open({width: '100%', maxWidth: '25em'});
    }

    openModalSelecionarAcessos() {
        this.modalSelecionarAcessos.open();
        this.listaAcessosPessoais.listarEntidades();
    }
    
    onSaveModalSelecionarAcessos = new Observable((observer) => {
        const selecionados = this.listaAcessosPessoais.getLinhasSelecionadas();
        this.setSelecionados(selecionados);
        observer.next();
        observer.complete();
    });

    onSaveModalAdicionarAcessoPessoal = new Observable((observer) => {
        if(this.formularioAdicionarAcessoPessoal.isValid()){
            const selecionado = this.acessoPessoalEntidadeForm;
            this.setSelecionados([selecionado]);
            observer.next();
        } else {
            observer.error('Formulário inválido');
        }
        observer.complete();
    });

    private setSelecionados(lista: IAcessoPessoalForm[]) {
        const selecionados = this.acessosPessoaisSelecionados.slice();

        lista.forEach((acesso) => {
            const find = selecionados.find((a) => a?.cpf == acesso?.cpf && a.username == acesso.username);
            if (!find) {
                selecionados.push(acesso);
            }
        });
        this.acessosPessoaisSelecionados = selecionados;
    }
}
