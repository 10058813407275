import { Component, OnInit, NgModule, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NavigationMenu, navigation } from 'src/app/app-navigation';
import { ScreenService } from '../../services/screen.service';
import { HeaderModule } from '../header/header.module';
import { SideNavigationMenuComponent, SideNavigationMenuModule } from '../side-navigation-menu/side-navigation-menu.component';

@Component({
    selector: 'app-side-nav-outer-toolbar',
    templateUrl: './side-nav-outer-toolbar.component.html',
    styleUrls: ['./side-nav-outer-toolbar.component.scss']
})
export class SideNavOuterToolbarComponent implements OnInit {

    @ViewChild('menuLateral', { static: false }) menuLateral!: SideNavigationMenuComponent;

    @Input()
    title!: string;

    toggleMenu() {
        this.menuLateral.toggleMenu();
    }

    selectedRoute = '';

    menuOpened!: boolean;
    temporaryMenuOpened = false;

    menuMode = 'shrink';
    menuRevealMode = 'expand';
    minMenuSize = 0;
    shaderEnabled = false;

    constructor(private screen: ScreenService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.menuOpened = this.screen.sizes['screen-large'];

        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                // persistir menu selecionado apos alterar rota para rotas filhas
                // this.selectedRoute = '/' + this.route.snapshot.children[0].url[0].path;
                this.selectedRoute = this.getUrlSelecionarMenuLateral(val.urlAfterRedirects.split('?')[0]);
            }
        });
    }


    get hideMenuAfterNavigation() {
        return this.menuMode === 'overlap' || this.temporaryMenuOpened;
    }

    get showMenuAfterClick() {
        return !this.menuOpened;
    }

    navigationChanged(event: any) {
        const path = (event.itemData as any).path;
        const pointerEvent = event.event;

        if (path && this.menuOpened) {
            if (event.node?.selected) {
                pointerEvent?.preventDefault();
            } else {
                this.router.navigate([path]);
                // this.scrollView.instance.scrollTo(0);
            }

            if (this.hideMenuAfterNavigation) {
                this.temporaryMenuOpened = false;
                this.menuOpened = false;
                pointerEvent?.stopPropagation();
            }
        } else {
            pointerEvent?.preventDefault();
        }
    }

    navigationClick() {
        if (this.showMenuAfterClick) {
            this.temporaryMenuOpened = true;
            this.menuOpened = true;
        }
    }

    getUrlSelecionarMenuLateral(url: string): string {
        if (url == '' || url == null) return '';
        const rotasDisponiveis = this.getArrayPathsFromMenuLateral(navigation);
        if (rotasDisponiveis.includes(url)) return url;
        else {
            let arrayUrl = url.split('/');
            arrayUrl.pop(); //remover ultimo elemento
            return this.getUrlSelecionarMenuLateral(arrayUrl.join('/')); // 'atividades/ensino/novo' -> 'atividades/ensino'
        }
    }

    getArrayPathsFromMenuLateral(navigation: NavigationMenu[]) {
        let paths: string[] = [];

        for (const item of navigation) {
            if (item.path) {
                paths.push(item.path);
            }

            if (item.items) {
                paths = paths.concat(this.getArrayPathsFromMenuLateral(item.items));
            }
        }

        return paths;
    }
}

@NgModule({
    imports: [CommonModule, HeaderModule, SideNavigationMenuModule],
    exports: [SideNavOuterToolbarComponent],
    declarations: [SideNavOuterToolbarComponent]
})
export class SideNavOuterToolbarModule { }
