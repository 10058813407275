import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfigurationColumn } from '../../model/resource';
import { ActionsTabela } from '../tabela-listagem/tabela-listagem.component';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateApiService } from '../../api/template-api.service';
import { IResponse } from '../../api/response.model';

@Component({
    selector: 'tabela-estatica',
    templateUrl: './tabela-estatica.component.html',
    styleUrls: ['../tabela-listagem/tabela-listagem.component.css']
})
export class TabelaEstaticaComponent implements OnInit {
    @Input() colunas!: ConfigurationColumn[];
    @Input() valueExpr!: string;
    @Input() actions: ActionsTabela[] = [];
    @Input() entidades: any[] = [];
    @Input() minWidth = '60em';

    colunasListadas: ConfigurationColumn[] = [];
    listaColunasExibir: string[] = [];

    constructor(
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.setup();
    }

    private setup(){
        this.configurarColunas();
    }

    private configurarColunas() {
        this.colunasListadas = this.colunas
            .filter((coluna) => !coluna.unAvailableOnList)
            .map((coluna) => coluna);
        this.listaColunasExibir = this.colunasListadas.map((col) => col.field);

        if (this.actions.length > 0) this.listaColunasExibir.push('actions');
    }

    executeAction(action: ActionsTabela, row: any) {
        action.onClick(row, this.apiService as unknown as TemplateApiService<IResponse>, this.router, this.route);
    }

    calculateDisplayValue(element: any, column: ConfigurationColumn) {
        const objeto = this.getValueFromObjectPath(element, column.field);

        if (column.calculateDisplayValue) {
            return column.calculateDisplayValue(objeto) || '';
        }
        return objeto || '';
    }

    calculateDisplayTooltip(element: any, column: ConfigurationColumn) {
        const objeto = this.getValueFromObjectPath(element, column.field);

        if (column.calculateDisplayTooltip) {
            return column.calculateDisplayTooltip(objeto) || '';
        }
        return objeto || '';
    }

    getValueFromObjectPath(object: any, path: string) {
        let value = object;
        const props = path.split('.');
        for (const prop of props) {
            if (!value[prop]) return null;
            value = value[prop];
        }
        return value;
    }

    isVisibleAction(action: ActionsTabela, row: any){
        if(action.isVisible){
            return action.isVisible(row);
        }
        return true;
    }
}
