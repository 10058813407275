<div class="small-shadow border mt-2">

	<div *ngIf="title"
		class="card-title">
		<div class="d-flex justify-content-between">
			<div class="d-flex wrap-text">
				<button (click)="toggleCard()"
					mat-mini-fab
					color="basic"
					class="btn-expand">
					<mat-icon *ngIf="cardOpened">keyboard_arrow_up</mat-icon>
					<mat-icon *ngIf="!cardOpened">keyboard_arrow_down</mat-icon>
				</button>

				<p class="mr-2">{{title}}</p>
			</div>

			<div class="actions-card">
				<ng-container *ngFor="let action of actions">
					<button (click)="executeAction(action)" mat-mini-fab [color]="action.type" class="ms-1">
						<mat-icon>{{action.icon}}</mat-icon>
					</button>
				</ng-container>
			</div>
		</div>
	</div>

	<div #cardContent
		[@cardAnimation]="cardOpened ? 'expanded' : 'collapsed'"
		[ngClass]="{'card-content-opened': cardOpened, 'card-content-closed': !cardOpened}"
		class="card-content">
		<ng-content></ng-content>
	</div>
</div>
