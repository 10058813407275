<container-padrao>
    <toolbar titulo="Importar arquivo de licenças" [actions]="actions"></toolbar>
</container-padrao>

<container-padrao>
    <card>
        <div class="instrucoes">
            <h3 class="titulo">Instruções para realizar importação:</h3>

            <ol class="lista-de-instrucoes">
                <li>Baixe o arquivo Modelo de Importação;</li>
                <li>Abra o arquivo e preencha as colunas respectivas corretamente;</li>
                <li>Adicione esse arquivo preenchido no campo "Adicionar Arquivo";</li>
                <li>Clique em Realizar importação</li>
            </ol>
        </div>
    </card>
</container-padrao>

<container-padrao>
    <card title="Adicionar Arquivo">
        <input-file-area [(file)]="arquivoImportar" accept=".csv"></input-file-area>
    </card>
</container-padrao>
