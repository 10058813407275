<form [formGroup]="formulario">
    <div class="row">
        <div
            class="row"
            style="padding-right: 0px"
            [ngClass]="linha.sizeColumn || 'col-12'"
            *ngFor="let linha of linhas"
        >
            <div
                [ngClass]="coluna.sizeColumn || 'col-md-6 col-xl-4'"
                *ngFor="let coluna of linha.colunas"
            >
                <!-- INPUT -->
                <mat-form-field
                    *ngIf="coluna.type == columnEnum.Text || !coluna.type"
                    appearance="fill"
                >
                    <mat-label>{{ coluna.label || coluna.field }}</mat-label>
                    <input
                        [formControlName]="coluna.field"
                        matInput
                        [(ngModel)]="entidade[coluna.field]"
                        [name]="coluna.field"
                    />

                    <mat-error *ngIf="formulario.get(coluna.field)?.hasError('apiError')">
                        {{ formulario.get(coluna.field)?.getError('apiError') }}
                    </mat-error>
                </mat-form-field>

                <!-- NUMBER -->
                <mat-form-field *ngIf="coluna.type == columnEnum.Number" appearance="fill">
                    <mat-label>{{ coluna.label || coluna.field }}</mat-label>
                    <input
                        type="number"
                        [formControlName]="coluna.field"
                        matInput
                        [(ngModel)]="entidade[coluna.field]"
                        [max]="coluna.numberConfiguration?.maxValue || null"
                        [min]="coluna.numberConfiguration?.minValue || null"
                        [name]="coluna.field"
                    />
                </mat-form-field>

                <!-- TEXTAREA -->
                <mat-form-field
                    class="form-textarea"
                    *ngIf="coluna.type == columnEnum.TextArea"
                    appearance="fill"
                >
                    <mat-label>{{ coluna.label || coluna.field }}</mat-label>
                    <textarea
                        [rows]="
                            coluna?.textareaConfiguration?.rows
                                ? coluna?.textareaConfiguration?.rows
                                : null
                        "
                        [formControlName]="coluna.field"
                        matInput
                        [(ngModel)]="entidade[coluna.field]"
                        [name]="coluna.field"
                    ></textarea>
                </mat-form-field>

                <!-- SELECT -->
                <!-- <mat-form-field
                    *ngIf="coluna.type == columnEnum.Select"
                    appearance="fill"
                >
                    <mat-label>{{ coluna.label || coluna.field }}</mat-label>
                    <mat-select
                        [(value)]="entidade[coluna.field]"
                        [formControlName]="coluna.field"
                    >
                        <mat-option
                            *ngFor="
                                let option of coluna.selectConfiguration
                                    ?.defaultData
                            "
                            [value]="
                                option[coluna.selectConfiguration!.valueExpr]
                            "
                        >
                            {{
                                option[coluna.selectConfiguration!.displayExpr]
                            }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <!-- SELECT -->
                <ng-container *ngIf="coluna.type == columnEnum.Select" appearance="fill">
                    <select-formulario
                        [(value)]="entidade[coluna.field]"
                        [formControlName]="coluna.field"
                        [name]="coluna.field"
                        [label]="coluna.label || coluna.field"
                        [parentFormGroup]="formulario"
                        [selectConfiguration]="coluna.selectConfiguration!"
                    ></select-formulario>
                </ng-container>

                <!-- DATE -->
                <mat-form-field *ngIf="coluna.type == columnEnum.Date" appearance="fill">
                    <mat-label>{{ coluna.label || coluna.field }}</mat-label>
                    <input
                        matInput
                        [formControlName]="coluna.field"
                        [name]="coluna.field"
                        [(ngModel)]="entidade[coluna.field]"
                        (dateChange)="changeDate($event)"
                        [matDatepicker]="picker"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <!-- PASSWORD -->
                <mat-form-field *ngIf="coluna.type == columnEnum.Password" appearance="fill">
                    <mat-label>{{ coluna.label || coluna.field }}</mat-label>
                    <input
                        #inputPassword
                        type="password"
                        [formControlName]="coluna.field"
                        matInput
                        [(ngModel)]="entidade[coluna.field]"
                        [name]="coluna.field"
                    />
                    <button
                        mat-icon-button
                        matSuffix
                        tabindex="-1"
                        (click)="toggleVisibilityPass(inputPassword)"
                        [attr.aria-label]="'Hide password'"
                    >
                        <mat-icon>{{
                            inputPassword.type == 'password' ? 'visibility_off' : 'visibility'
                        }}</mat-icon>
                    </button>
                </mat-form-field>

                <!-- COLOR -->
                <ng-container *ngIf="coluna.type == columnEnum.Color">
                    <color-picker
                        [(value)]="entidade[coluna.field]"
                        [coluna]="coluna"
                        [parentFormGroup]="formulario"
                    ></color-picker>
                </ng-container>

                <!-- CHECKBOX -->
                <ng-container *ngIf="coluna.type == columnEnum.Checkbox">
                    <mat-checkbox
                        [formControlName]="coluna.field"
                        matInput
                        [(ngModel)]="entidade[coluna.field]"
                        [name]="coluna.field"
                        [color]="'primary'"
                    >
                        {{ coluna.label || coluna.field }}
                    </mat-checkbox>
                </ng-container>

                <!-- LOOKUP -->
                <ng-container *ngIf="coluna.type == columnEnum.Lookup">
                    <lookup
                        [parentFormGroup]="formulario"
                        [coluna]="coluna"
                        [service]="coluna.lookupConfiguration?.instanciaService"
                        [entidade]="entidade"
                        [(value)]="entidade[coluna.field]"
                    ></lookup>
                </ng-container>

                <!-- MIDIA -->
                <ng-container *ngIf="coluna.type == columnEnum.Midia">
                    <card-midia-formulario
                        [content_type]="coluna.cardMidiaConfiguration!.content_type"
                        [obj_referencia]="coluna.cardMidiaConfiguration!.obj_referencia"
                    ></card-midia-formulario>
                </ng-container>
            </div>
        </div>
    </div>
</form>
