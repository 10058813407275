import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IModulo, ModuloService } from 'src/app/shared/api/testes/modulos.service';
import { moduloResource } from './modulo.resource';
import { ActivatedRoute } from '@angular/router';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { Observable, Subscription } from 'rxjs';
import { FormularioDinamicoComponent } from 'src/app/shared/components/formulario-dinamico/formulario-dinamico.component';
import { NotificationService } from 'src/app/shared/services/notificationService';
import { ConfigurationColumn } from 'src/app/shared/model/resource';
import { IActionsCardbotoes } from 'src/app/shared/components/card-botoes/card-botoes.component';
import { rodadaResource } from '../rodadas/rodada.resource';
import { DialogService } from 'src/app/shared/services/dialog.service';

export class DynamicFlatNode {
    constructor(
        public item: any,
        public children: DynamicFlatNode[] = [],
        public level = 1,
        public expandable = false,
        public isLoading = false,
    ) { }
}


@Component({
    selector: 'modulos-testes',
    templateUrl: './modulos.component.html',
    styleUrls: ['./modulos.component.css', './../../shared/components/tabela-listagem/tabela-listagem.component.css']
})
export class ModulosComponent implements OnInit, OnDestroy {
    @ViewChild('modal') modal!: ModalComponent;
    @ViewChild('formularioModulo') formularioModulo!: FormularioDinamicoComponent;

    moduloResource = moduloResource;
    rodadaResource = rodadaResource;
    colunasFormularioModulo: ConfigurationColumn[] = [];
    actions: IActionsCardbotoes[] = [];
    entidadeModulo: any = {};
    listaModulos: IModulo[] = [];
    id: any;
    showFormModal = false;
    dataModal: IDialogData = { titulo: '' };
    selectedTab = 0;
    showLoaderModulos = false;


    readonly ADICIONAR_MODULO = 'Adicionar módulo';
    readonly EDITAR_MODULO = 'Editar módulo';
    readonly EXCLUIR_MODULO = 'Excluir módulo';


    private _subscriberModal: Subscription | null = null;
    private _subscriberCreateModulo: Subscription | null = null;
    private _subscriberListagemModulos: Subscription | null = null;

    constructor(
        public moduloService: ModuloService,
        private route: ActivatedRoute,
        private notify: NotificationService,
        private dialog: DialogService
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit(): void {
        this.setarIdsNaCamadaDeServico();

        this.actions.push({
            hint: this.EDITAR_MODULO,
            icon: 'edit',
            text: this.EDITAR_MODULO,
            type: 'primary',
            onClick: this.abrirModalEditarModulo
        })

        this.actions.push({
            hint: this.EXCLUIR_MODULO,
            icon: 'delete',
            text: this.EXCLUIR_MODULO,
            type: 'warn',
            onClick: this.excluirModulo
        })

        this.listarModulos();
    }

    private setarIdsNaCamadaDeServico(){
        // é importante setar esses valores a cada vez que o serviço for chamado
        // pois esse componente pode estar sendo chamado mais de uma vez na mesma tela com id diferente
        this.moduloService.setBaseUrl(this.id);
    }


    ngOnDestroy(): void {
        if (this._subscriberModal) this._subscriberModal.unsubscribe();
        if (this._subscriberCreateModulo) this._subscriberCreateModulo.unsubscribe();
        if (this._subscriberListagemModulos) this._subscriberListagemModulos.unsubscribe();
    }

    public listarModulos() {
        this.showLoaderModulos = true;
        this.setarIdsNaCamadaDeServico();
        this._subscriberListagemModulos = this.moduloService.get({tamanho: 999}).subscribe(response => {
            this.listaModulos = response.resultados;
            this.showLoaderModulos = false;
        })
    }

    showModalFormularioModulo() {
        this.showFormModal = true;
        this._subscriberModal = this.modal.open({ width: '40em' }).afterClosed().subscribe({
            next: () => {
                this.showFormModal = false;
            }
        });
    }

    abrirModalCriarNovoModulo = () => {
        this.entidadeModulo = {};
        this.dataModal.titulo = this.ADICIONAR_MODULO;
        this.colunasFormularioModulo = this.moduloResource.colunas.filter(c => !c.unAvailableOnCreate);
        this.showModalFormularioModulo();
    }

    abrirModalEditarModulo = (entity: any) => {
        this.entidadeModulo = entity;
        this.dataModal.titulo = this.EDITAR_MODULO;
        this.colunasFormularioModulo = this.moduloResource.colunas.map(c => c);
        this.showModalFormularioModulo();
    }

    excluirModulo = (entity: IModulo) => {
        this.dialog.confirm('Tem certeza que deseja excluir o módulo selecionado?', entity.nome).subscribe({
            next: (confirmed) => {
                if(confirmed){
                    this.setarIdsNaCamadaDeServico();
                    this.moduloService.delete(entity.uuid).subscribe({
                        next: (response) => {
                            this.listarModulos();
                        }
                    })
                }
            }
        })
    }

    tabChanged($event: any){
        this.selectedTab = $event.index;
    }

    onSaveModulo = () => {
        return new Observable(observer => {
            if (!this.formularioModulo.isValid()) {
                observer.error();
                return;
            }

            this.setarIdsNaCamadaDeServico();
            const modulo:IModulo = this.formularioModulo.getDadosFormularioSalvar();

            const moduloSalvar: IModulo = {
                descricao: modulo.descricao,
                nome: modulo.nome,
                posicao: modulo.posicao,
                teste: modulo.teste,
                uuid: modulo?.uuid,
            }

            if(modulo.categoria) moduloSalvar.categoria = modulo.categoria;

            let createOrSaveRequest = null;
            if (this.entidadeModulo?.uuid) {
                createOrSaveRequest = this.moduloService.update(this.entidadeModulo.uuid, moduloSalvar);
            } else {
                createOrSaveRequest = this.moduloService.create(moduloSalvar)
            }

            this._subscriberCreateModulo = createOrSaveRequest.subscribe({
                next: () => {
                    this.listarModulos();
                    observer.next();
                },
                error: (e: any) => {
                    this.formularioModulo.tratarErroRequisicao(e);
                    this.notify.error(e.error.erro);
                    observer.error(e);
                }
            })
        })
    }
}
