import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlternativasComponent } from './alternativas.component';
import { AlternativaService } from 'src/app/shared/api/testes/alternativa.service';
import { TabelaListagemModule } from "../../shared/components/tabela-listagem/tabela-listagem.module";
import { ButtonModule } from "../../shared/components/button/button.module";
import { ModalModule } from "../../shared/components/modal/modal.module";
import { FormularioDinamicoModule } from "../../shared/components/formulario-dinamico/formulario-dinamico.module";
import { NotificationService } from 'src/app/shared/services/notificationService';



@NgModule({
    declarations: [
        AlternativasComponent
    ],
    exports: [
        AlternativasComponent
    ],
    providers: [
        AlternativaService,
        NotificationService
    ],
    imports: [
        CommonModule,
        TabelaListagemModule,
        ButtonModule,
        ModalModule,
        FormularioDinamicoModule
    ]
})
export class AlternativasModule { }
