import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { TemplateApiService } from '../../api/template-api.service';
import { IResponse } from '../../api/response.model';
import { ActivatedRoute, Router } from '@angular/router';


export interface IActionsCardbotoes {
    text: string,
    icon: string,
    type: 'primary' | 'warn' | 'accent' | 'basic',
    hint: string,
    onClick: Function,
    isVisible?: (entity: any) => boolean
}

@Component({
    selector: 'card-botoes',
    templateUrl: './card-botoes.component.html',
    styleUrls: ['./card-botoes.component.css'],
    animations: [
        trigger('cardAnimation', [
            state('collapsed', style({
                height: '0',
                paddingTop: '0',
                paddingBottom: '0',
                opacity: '0',
                overflow: 'hidden'
            })),
            state('expanded', style({
                height: '*',
                paddingTop: '1em',
                paddingBottom: '2em',
                opacity: '1',
                overflow: 'visible'
            })),
            transition('collapsed <=> expanded', animate('300ms ease-out'))
        ]),

    ]
})
export class CardBotoesComponent implements OnInit {

    @ViewChild('cardContent') cardContent!: HTMLDivElement;

    @Input() title?: string | null;

    @Input() cardOpened = true;
    @Output() cardOpenedChange = new EventEmitter<any>();

    @Input() actions: IActionsCardbotoes[] = [];

    @Input() service!: TemplateApiService<IResponse>;

    @Input() entity!: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute
    ) { }

    ngOnInit(): void {
    }

    toggleCard() {
        this.cardOpened = !this.cardOpened;
        this.cardOpenedChange.emit(this.cardOpened);
    }

    executeAction(action: IActionsCardbotoes) {
        action.onClick(this.entity);
    }
}
