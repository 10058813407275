<div class="col-sm-6 esquerda p-0">
    
    <div *ngIf="!showLoaderMidia" class="area-midias">
        <div class="d-flex justify-content-center">
            <img [src]="capaUrl" class="w-100 img-capa">
        </div>
    </div>

    <div *ngIf="showLoaderMidia" class="d-grid align-items-center justify-content-center">
        <loader-page [show]="true"></loader-page>
    </div>
</div>
<div class="col-sm-6 direita ps-4 pe-3 pb-4">
    <div class="d-grid align-items-center">
        <div class="w-100">
            <h1 class="titulo" style="font-size: 1.5em;">Teste finalizado!</h1>
            <h2 class="titulo">{{teste?.nome}}</h2>
        </div>

        <div class="p-4 mt-4 descricao">
            <div [innerHTML]="teste?.descricao | safeHtmlJogada"></div>
        </div>

        <btn-continuar
            *ngIf="teste"
            texto="Visualizar Relatório"
            (click)="onClickContinuar()"
            class="mt-4"
        ></btn-continuar>
    </div>
</div>
