<ng-container *ngIf="ocultarMenuEHeader(); else mostrarMenuEHeader">
    <router-outlet></router-outlet>
</ng-container>


<ng-template #mostrarMenuEHeader>
    <ng-container *ngIf="isPerfilSelected(); else perfilNotSelected">
        <app-side-nav-outer-toolbar [title]="appInfo.title">
            <router-outlet></router-outlet>
        </app-side-nav-outer-toolbar>
    </ng-container>
</ng-template>



<ng-template #perfilNotSelected>
    <ng-container *ngIf="isAuthenticated(); else unauthenticated">
        <app-header [title]="appInfo.title"
            [showBtnMenu]="false"></app-header>
        <router-outlet></router-outlet>
    </ng-container>



    <ng-template #unauthenticated>
        <app-unauthenticated-content></app-unauthenticated-content>
    </ng-template>
</ng-template>
