import { Component, Input, OnInit } from '@angular/core';
import { IMAGE_FORMATS, VIDEO_FORMATS } from '../card-midia/image-video-formats.resource';

@Component({
    selector: 'carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

    @Input() midias!: {url: string, formato: string}[];
    @Input() indicators = true;
    @Input() controls = true;

    selectedIndex: number = 0;

    imageFormats = IMAGE_FORMATS;
    videoFormats = VIDEO_FORMATS;

    constructor() { }

    ngOnInit(): void {
    }

    selectImage(index: number) {
        this.selectedIndex = index;
    }

    onPrevClick() {
        if (this.selectedIndex === 0) {
            this.selectedIndex = this.midias.length - 1;
        } else {
            this.selectedIndex--;
        }
    }

    onNextClick() {
        if (this.selectedIndex === this.midias.length -1) {
            this.selectedIndex = 0;
        } else {
            this.selectedIndex++;
        }
    }
}
