import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILicenca } from 'src/app/shared/api/licencas/licencas.service';
import { IMidia } from 'src/app/shared/api/testes/adm-midias.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { ConstantsWidthHeightMidia } from 'src/app/shared/components/card-midia/card-midia-constants';
import {
    IMAGE_FORMATS,
    VIDEO_FORMATS
} from 'src/app/shared/components/card-midia/image-video-formats.resource';
import { unformatedStringDateToPortugueseDateString } from 'src/app/shared/functions/data-transform';

@Component({
    selector: 'card-licenca',
    templateUrl: './card-licenca.component.html',
    styleUrls: ['./card-licenca.component.scss']
})
export class CardLicencaComponent implements OnInit {
    @Input() licenca!: ILicenca;

    @Output() onIniciarClick = new EventEmitter();
    @Output() onRelatorioClick = new EventEmitter();

    imageLoaded = false;
    midiaTeste?: IMidia;

    constantsWidthHeightMidia = ConstantsWidthHeightMidia;
    imageFormats = IMAGE_FORMATS;
    videoFormats = VIDEO_FORMATS;

    constructor(private midiasService: MidiasService) {}

    ngOnInit(): void {
        // this.getCapaTeste();
    }

    converterData(data: string) {
        return unformatedStringDateToPortugueseDateString(data);
    }

    loadImage() {
        this.imageLoaded = true;
    }

    iniciar() {
        this.onIniciarClick.emit(this.licenca);
    }

    relatorio() {
        this.onRelatorioClick.emit(this.licenca);
    }

    getFileFormatFromUrl(url: string) {
        // Remover parâmetros da URL (se houver)
        const cleanUrl = url.split('?')[0];

        // Extrair a extensão do arquivo
        const extension = cleanUrl.split('.').pop();

        return extension?.toLowerCase();
    }

    isDataAtualOuAnterior(dataString1: string) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Zera horas, minutos, segundos e milissegundos para comparação apenas da data

        const inputDate = new Date(dataString1);
        inputDate.setHours(0, 0, 0, 0);

        return inputDate <= today; // Retorna true se for hoje ou uma data anterior
    }

    podeIniciarLicenca() {
        return (
            this.licenca.data_fim == null &&
            this.licenca.liberado &&
            (this.licenca.disponivel_de == null ||
                this.isDataAtualOuAnterior(this.licenca.disponivel_de))
        );
    }

    exibirCadeado(){
        return (
            !this.licenca.liberado ||
            !(this.licenca.disponivel_de == null ||
                this.isDataAtualOuAnterior(this.licenca.disponivel_de))
        )
    }

    // getCapaTeste(){
    //     const sub = this.midiasService.getMidiaCapa(this.licenca.teste.uuid).subscribe({
    //         next: (response) => {
    //             sub.unsubscribe();
    //             this.midiaTeste = response.resultados[0];
    //         }
    //     })
    // }
}
