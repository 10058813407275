export const IMAGE_FORMATS = [
    'png', 
    'jpg', 
    'jpeg',
    'webp'
];

export const VIDEO_FORMATS = [
    'mp4',
    'mov',
    'mpeg-1',
    'mpeg-2',
    'mpeg-4',
    'mpg',
    'avi',
    'wmv'
]