<container-padrao>

    <h2 class="text-center">Apps disponíveis:</h2>
    <hr>

    <div class="row w-100 justify-content-center">
        <div *ngFor="let acesso of apps_disponiveis"
            class="col-lg-3 col-md-4 col-sm-6 mt-3">
            <div (click)="selecionarCard(acesso)"
                class="card card-acesso">
                <div class="card-body">
                    <div class="w-100 d-grid justify-content-center">
                        <h2 class="card-title titulo">{{acesso.text}}</h2>
                        <mat-icon class="m-auto icon-app">{{acesso.icon}}</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</container-padrao>
