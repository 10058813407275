import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { EquipesComponent } from './equipes.component';
import { FormEquipeComponent } from './form-equipe/form-equipe.component';

const routes: Routes = [
    {
        path: 'equipes',
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: EquipesComponent
            },
            {
                path: 'novo',
                component: FormEquipeComponent
            },
            {
                path: ':id',
                component: FormEquipeComponent
            },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EquipesRoutingModule { }
