<button
    (click)="buttonClick()"
    mat-raised-button
    [color]="color"
    [disabled]="loading"
    [matTooltip]="tooltip"
>
    <ng-container *ngIf="icon">
        <mat-icon>{{ icon }}</mat-icon>
    </ng-container>
    <ng-container *ngIf="text && !loading">
        {{ text }}
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="area-loading">
            <mat-spinner [diameter]="28" color="primary"></mat-spinner>
        </div>
    </ng-container>
    <ng-content></ng-content>
</button>
