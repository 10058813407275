<form (submit)="onSubmit($event)"
    class="reset-password-form">


    <mat-form-field>
        <mat-label>Login</mat-label>
        <input [(ngModel)]="formData.username"
            [placeholder]="'Login'"
            name="username"
            matInput>
    </mat-form-field>


    <!-- BOTAO ENVIAR EMAIL TOKEN -->
    <button [disabled]="loading"
        (click)="onSubmit($event)"
        color="primary"
        mat-raised-button
        type="submit"
        style="width: 100%;">
        <ng-container *ngIf="!loading">
            <mat-icon>send</mat-icon>
            Enviar Email
        </ng-container>



        <div *ngIf="loading"
            style="width: 100%; display: flex; justify-content: center; padding: 0.3em">
            <mat-icon>send</mat-icon>
            <mat-spinner [diameter]="30"
                color="accent"></mat-spinner>
        </div>
    </button>



    <div class="login-link mt-3">
        <a routerLink="/login">Voltar para Login</a>
    </div>


</form>
