<container-padrao>
    <card title="Capa da Trilha e Capa dos materiais" *ngIf="idTrilha">

        <div class="midias">
            <div class="card-midia-content">
                <h3>Capa da Trilha</h3>
                <card-midia 
                    [content_type]="content_type" 
                    [obj_referencia]="idTrilha" 
                    [file]="midiasTrilha[0]"
                    [mensagemExcluirMidia]="'Ao excluir essa mídia, a capa dos materiais entrará no lugar da capa da trilha.'"
                    (changeMidia)="changeMidia()"></card-midia>
            </div>

            <div class="card-midia-content" *ngIf="midiasTrilha[0]?.uuid">
                <h3>Capa dos Materiais</h3>
                <card-midia [content_type]="content_type" [obj_referencia]="idTrilha" [file]="midiasTrilha[1]"
                    (changeMidia)="changeMidia()"></card-midia>
            </div>
        </div>
    </card>

    <div *ngIf="!idTrilha" class="card-nenhuma-trilha-criada">
        <strong><p>Nenhum arquivo de Trilha encontrada!</p></strong>
        
        <p>Caso esse curso seja o primeiro da sequência, clique em Criar mídias da trilha para torná-lo o curso principal e habilitar a opção de inserir as mídias principais da trilha.</p>
    
        <primary-button
            [onClick]="criarNovaTrilha()"
            icon="add"
            text="Criar mídias da trilha"
            class="m-auto mt-2"
        ></primary-button>
    </div>

</container-padrao>