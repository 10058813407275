import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFormComponent } from './pages/autenticacao/login-form/login-form.component';
import { ResetPasswordFormComponent } from './pages/autenticacao/reset-password-form/reset-password-form.component';
import { CreateAccountFormComponent } from './pages/autenticacao/create-account-form/create-account-form.component';
import { ChangePasswordFormComponent } from './pages/autenticacao/change-password-form/change-password-form.component';
import { AuthGuardService, AuthGuardServiceAcesso, AuthGuardServiceLoginForm } from './shared/services/auth.service';

const routes: Routes = [
    {
        path: 'login',
        component: LoginFormComponent,
        canActivate: [AuthGuardServiceLoginForm]
    },
    {
        path: 'reset-password',
        component: ResetPasswordFormComponent,
        canActivate: [AuthGuardServiceLoginForm]
    },
    {
        path: 'create-account',
        component: CreateAccountFormComponent,
        canActivate: [AuthGuardServiceLoginForm]
    },
    {
        path: 'alterar-senha/:token',
        component: ChangePasswordFormComponent,
        canActivate: [AuthGuardServiceLoginForm]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        AuthGuardServiceLoginForm,
        AuthGuardServiceAcesso,
        AuthGuardService
    ]
})
export class AppRoutingModule { }
