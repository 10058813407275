import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { ScreenService } from './shared/services/screen.service';
import { AppInfoService } from './shared/services/app-info.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    @HostBinding('class') get getClass() {
        return Object.keys(this.screen.sizes)
            .filter((cl) => this.screen.sizes[cl])
            .join(' ');
    }

    @HostBinding('class.disable-tooltip') disableTooltip: boolean = false;

    constructor(
        private authService: AuthService,
        private screen: ScreenService,
        public appInfo: AppInfoService
    ) {}

    ngOnInit(): void {
        this.checkScreenSize();
    }

    isAuthenticated() {
        return this.authService.loggedIn;
    }

    isPerfilSelected() {
        return this.isAuthenticated() && this.authService.perfilSelecionado;
    }

    ocultarMenuEHeader() {
        if (this.authService.isTelaAutocadastro) return true;
        else return this.isPerfilSelected() && this.authService.isTelaDeJogada;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.checkScreenSize();
    }

    private checkScreenSize() {
        this.disableTooltip = window.innerWidth < 768;
    }
}
