import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { testeResource } from '../../teste.resource';
import { ActionToolbar } from 'src/app/shared/components/toolbar/toolbar.component';
import { ITeste, TestesAdmService } from 'src/app/shared/api/testes/testes.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColumnDataType, ConfigurationColumn, Resource } from 'src/app/shared/model/resource';
import { versoesTesteResource } from '../../versoes-teste.resource';
import {
    ActionsTabela,
    TabelaListagemComponent
} from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { VersoesTestesAdmService } from 'src/app/shared/api/testes/versoes-testes.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationService } from 'src/app/shared/services/notificationService';
import { clonarObjeto } from 'src/app/shared/functions/objects-functions';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TrilhasService } from 'src/app/shared/api/testes/trilhas.service';
import { IMidia } from 'src/app/shared/api/testes/adm-midias.service';

@Component({
    selector: 'formulario-teste',
    templateUrl: './formulario-teste.component.html',
    styleUrls: [
        './formulario-teste.component.css',
        '../../../../shared/components/formulario-dinamico/formulario-dinamico.component.css'
    ],
})
export class FormularioTesteComponent implements OnInit {
    @ViewChild('modalVersoesTeste') modalVersoesTeste!: ModalComponent;
    @ViewChild('tabelaVersoesTestes') tabelaVersoesTestes!: TabelaListagemComponent;

    @Input() id!: string;

    @Output() onNovaVersaoGerada = new EventEmitter();

    constructor(
        public testesAdmService: TestesAdmService,
        public testeService: TestesAdmService,
        public versoesTesteService: VersoesTestesAdmService,
        private dialog: DialogService,
        private notify: NotificationService,
        private router: Router,
        private location: Location
    ) {}

    midiaCapa: any;
    cardMidiaConfiguration?: { content_type: string; obj_referencia: string };

    // MIDIAS DE CAPA PRINCIPAL E CAPA MATERIAIS
    capaPrincipal: any;

    rota = 'testes';

    entidade: ITeste = {
        ativo: false,
        descricao: '',
        nome: '',
        uuid: '',
        antecessor: undefined
    };
    entidadeOriginal: any;

    resource = clonarObjeto(testeResource);

    formulario?: FormGroup;
    testeAnteriorConfiguration?: ConfigurationColumn;

    dataModalVersoesTeste: IDialogData = {
        titulo: 'Versões do teste',
        labelCancel: 'Fechar'
    };
    resourceLoaded = false;
    actions: ActionToolbar[] = [];

    colunasVersoesTestes = versoesTesteResource;
    actionsVersoesTestes: ActionsTabela[] = [];

    ngOnInit(): void {
        this.configurarCampoTesteAnterior();
        this.configurarCapa();

        this.actionsVersoesTestes.push({
            text: 'Reativar versão',
            hint: 'Reativar versão',
            icon: 'flash_on',
            type: 'primary',
            onClick: this.reativarVersao
        });

        this.formulario = new FormGroup({
            descricao: new FormControl(''),
            nome: new FormControl(''),
            antecessor: new FormControl('')
        });

        this.formulario.get('nome')?.setValidators([Validators.required]);
        this.formulario.get('descricao')?.setValidators([Validators.required]);

        this.getEntidade();
    }

    public reload() {
        this.getEntidade();
    }

    private getEntidade() {
        if (this.id) {
            this.testeService.detail(this.id).subscribe({
                next: (response: any) => {
                    this.entidade = clonarObjeto(response);
                    this.entidadeOriginal = clonarObjeto(response);
                    this.configureActions();
                }
            });
        } else {
            this.configureActions();
        }
    }

    private configureActions() {
        this.actions = [];

        // this.actionsIncrement.forEach((action) => {
        //     this.actions.push(action);
        // });

        this.actions.push({
            type: 'primary',
            icon: 'save',
            text: 'Salvar',
            onClick: this.salvar()
        });

        if (!this.resource.disableDelete && this.isNotEmptyObj(this.entidade)) {
            this.actions.push({
                icon: 'delete',
                text: 'Excluir',
                type: 'warn',
                onClick: this.excluir()
            });
        }

        if (!this.entidade.ativo && this.id) {
            this.actions.push({
                icon: 'flash_on',
                text: 'Ativar',
                type: 'accent',
                onClick: this.ativarTeste()
            });
        }

        if (this.id && this.entidade.ativo) {
            this.actions.push({
                icon: 'flash_off',
                text: 'Inativar',
                type: 'warn',
                onClick: this.inativarTeste()
            });
        }
        // if (this.entidadeOriginal) {
        //     this.actions.push({
        //         icon: 'manage_history',
        //         type: 'basic',
        //         text: 'Gerar nova versão',
        //         onClick: this.gerarNovaVersaoTeste()
        //     });
        //     this.actions.push({
        //         icon: 'timeline',
        //         type: 'basic',
        //         text: 'Visualizar versões do teste',
        //         onClick: this.visualizarVersoesTeste()
        //     });

        //     this.versoesTesteService.setBaseUrl(this.entidadeOriginal.uuid);
        // }
    }

    configurarCampoTesteAnterior() {
        this.testeAnteriorConfiguration = {
            field: 'antecessor',
            label: 'Teste Antecessor',
            sizeColumn: 'col-12',
            type: ColumnDataType.Lookup,
            getValueBeforSave(value) {
                return value?.uuid || undefined;
            },
            lookupConfiguration: {
                fieldName: 'nome',
                fieldPk: 'uuid',
                filterResultData: (testes: ITeste[]) => {
                    if (this.id) {
                        return testes.filter((teste) => teste.uuid != this.id);
                    }
                    return testes;
                },
                colunas: [
                    {
                        field: 'nome',
                        label: 'Nome'
                    },
                    {
                        field: 'descricao',
                        label: 'Descrição'
                    }
                ]
            }
        };
    }

    configurarCapa() {
        this.cardMidiaConfiguration = {
            content_type: 'teste',
            obj_referencia: this.id
        };
    }

    private validarFormulario() {
        return this.formulario?.valid;
    }

    private obterEntidadeSalvar() {
        return {
            antecessor: this.entidade.antecessor ? this.entidade.antecessor.uuid : undefined,
            nome: this.entidade.nome,
            descricao: this.entidade.descricao
        };
    }

    private salvar() {
        return new Observable<Object>((observer) => {
            if (!this.validarFormulario()) {
                observer.error('Formulário inválido');
                return;
            }

            let routeObservable;
            const dadosFormulario = this.obterEntidadeSalvar();

            if (!this.id) {
                routeObservable = this.testeService.create(dadosFormulario);
            } else {
                routeObservable = this.testeService.update(this.id, dadosFormulario);
            }

            routeObservable.subscribe({
                next: (response: any) => {
                    observer.next(response);

                    if (!this.id) {
                        this.navigateEdit(response.uuid as string);
                    } else {
                        this.entidade = response;
                        this.entidadeOriginal = response;
                        this.configureActions();
                    }
                    this.notify.success('Formulario salvo com sucesso');
                },
                error: (e) => {
                    this.tratarErroRequisicao(e);
                    this.notify.error(e.error.erro);
                    observer.error(e);
                }
            });
        });
    }

    private tratarErroRequisicao(erro: any) {
        if (erro.error.erro) {
            this.notify.error(erro.error.erro);
        } else if (erro.error) {
            const erroApi = erro.error;

            Object.keys(erroApi).forEach((key) => {
                erroApi[key].forEach((mensagemErro: string) => {
                    this.setErroFormControl(key, mensagemErro);
                });
            });
        }
    }

    private navigateEdit(id: string): void {
        if (!this.rota) throw new Error('Variável rota não definida');
        this.router.navigate([this.rota + '/' + id], { replaceUrl: true });
    }

    private setErroFormControl(field: string, mensagemErro: string) {
        const formControl = this.formulario!.get(field);
        formControl?.setErrors({ apiError: mensagemErro });
    }

    ativarTeste = () => {
        return new Observable((observer) => {
            this.dialog
                .confirm('Confirmar ativação', 'Deseja realmente ativar esse teste?')
                .subscribe({
                    next: (response: boolean) => {
                        if (response) {
                            this.testeService.ativarTeste(this.entidadeOriginal.uuid).subscribe({
                                next: () => {
                                    this.notify.success('Teste ativo com sucesso!');
                                    this.getEntidade();
                                    observer.next();
                                    observer.complete();
                                },
                                error: (error: any) => {
                                    this.notify.error(error.error.erro);
                                    observer.error();
                                    observer.complete();
                                }
                            });
                        } else {
                            observer.next();
                            observer.complete();
                        }
                    }
                });
        });
    };

    inativarTeste = () => {
        return new Observable((observer) => {
            this.dialog
                .confirm('Confirmar inativação', 'Deseja realmente inativar esse teste?')
                .subscribe({
                    next: (response: boolean) => {
                        if (response) {
                            this.testeService.inativarTeste(this.entidadeOriginal.uuid).subscribe({
                                next: () => {
                                    this.notify.success('Teste inativado com sucesso!');
                                    this.getEntidade();
                                    observer.next();
                                    observer.complete();
                                },
                                error: (error: any) => {
                                    this.notify.error(error.error.erro);
                                    observer.error();
                                    observer.complete();
                                }
                            });
                        } else {
                            observer.next();
                            observer.complete();
                        }
                    }
                });
        });
    };

    gerarNovaVersaoTeste = () => {
        return new Observable((observer) => {
            this.dialog
                .confirm(
                    'Confirmar nova versão',
                    'Deseja realmente gerar uma nova versão desse teste?'
                )
                .subscribe({
                    next: (response: boolean) => {
                        if (response) {
                            this.versoesTesteService
                                .gerarNovaVersaoTeste(this.entidadeOriginal.uuid)
                                .subscribe({
                                    next: (response) => {
                                        this.onNovaVersaoGerada.emit();
                                        observer.next();
                                        observer.complete();
                                    },
                                    error: (err: any) => {
                                        observer.next();
                                        observer.complete();
                                    }
                                });
                        } else {
                            observer.next();
                            observer.complete();
                        }
                    }
                });
        });
    };

    visualizarVersoesTeste = () => {
        return new Observable((observer) => {
            this.showModalVisualizarVersoesTeste();
            observer.next();
            observer.complete();
        });
    };

    reativarVersao = (data: any) => {
        const idVersao = data.uuid;

        this.dialog
            .confirm('Reativar versão', 'Deseja realmente reativar a versão do teste selecionado?')
            .subscribe({
                next: (response: boolean) => {
                    if (response) {
                        this.versoesTesteService.restaurarVersao(idVersao).subscribe({
                            next: (response) => {
                                this.reload();
                                this.closeModalVisualizarVersoesTeste();
                            }
                        });
                    }
                }
            });
    };

    private excluir() {
        return new Observable<Object>((observer) => {
            this.dialog
                .confirm('Confirmar Exclusão', 'Tem certeza que deseja excluir o item atual?')
                .subscribe({
                    next: (result: boolean) => {
                        if (result) {
                            this.testeService.delete(this.id!).subscribe(
                                (response: any) => {
                                    observer.next();
                                    if (!this.rota) throw new Error('Defina a variável rota');
                                    else this.goBack();
                                },
                                (error) => {
                                    this.notify.error(error.error.erro);
                                    observer.error();
                                }
                            );
                        }
                    }
                });
        });
    }

    private goBack() {
        this.location.back();
    }

    showModalVisualizarVersoesTeste = () => {
        this.modalVersoesTeste.open();
        this.tabelaVersoesTestes.listarEntidades();
    };

    closeModalVisualizarVersoesTeste = () => {
        this.modalVersoesTeste.close(true);
    };

    private isNotEmptyObj(obj: any) {
        return obj && Object.keys(obj).length !== 0;
    }
}
