<form [formGroup]="parentFormGroup">
    <mat-form-field style="width: 100%;">
        <mat-label>{{ label }}</mat-label>

        <mat-select
            [id]="name"
            [value]="value[selectConfiguration.valueExpr]"
            [formControlName]="formControlName"
            (valueChange)="onSelectChangeValue($event)"
        >
            <mat-option
                *ngFor="let option of optionsSelect"
                [value]="option[selectConfiguration.valueExpr]"
            >
                {{ calculateDisplayValue(option) }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>
