import { ColumnDataType, ConfigurationColumn } from "src/app/shared/model/resource";

export const acessoPessoalFormResource: ConfigurationColumn[] = [
    {
        field: 'username',
        label: 'Email',
        type: ColumnDataType.Text,
        required: true,
        sizeColumn: 'col-12'
    },
    {
        field: 'cpf',
        label: 'CPF',
        type: ColumnDataType.Number,
        required: true,
        sizeColumn: 'col-12'
    },
    {
        field: 'nome',
        label: 'Nome',
        type: ColumnDataType.Text,
        required: true,
        sizeColumn: 'col-12'
    },
]