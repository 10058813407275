import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PessoalLicencasComponent } from './pessoal-licencas.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';

const routes: Routes = [
    {
        path: 'licencas',
        component: PessoalLicencasComponent,
        canActivate: [AuthGuardService],
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PessoalLicencasRoutingModule { }
