import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdmMidiasService } from 'src/app/shared/api/testes/adm-midias.service';
import {
    IMAGE_FORMATS,
    VIDEO_FORMATS
} from 'src/app/shared/components/card-midia/image-video-formats.resource';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IMidiaMaterial } from '../materiais-curso/materiais-curso.component';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'card-midia-material',
    templateUrl: './card-midia-material.component.html',
    styleUrls: ['./card-midia-material.component.css']
})
export class CardMidiaMaterialComponent implements OnInit {
    @Input() midia!: IMidiaMaterial;
    @Input() obj_referencia!: string;
    @Input() tipoMidia!: number;

    arquivoBlob: any = null;
    linkMidia: string = '';

    content_type: string = 'material';

    @Output() midiaChange = new EventEmitter<any>();

    imageFormats = IMAGE_FORMATS;
    videoFormats = VIDEO_FORMATS;

    constructor(
        private midiaService: AdmMidiasService,
        private dialog: DialogService
    ) {}

    ngOnInit(): void {}

    changeFile = (evt: any) => {
        const file = evt.target.files[0];
        if (file) {
            this.arquivoBlob = file;
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.midia.url = e.target.result;
                this.midia.formato = this.ajustarNomeTipoArquivo(file.type);
                this.midia.descricao = file.name;
            };

            reader.readAsDataURL(file);
        }
    };

    private ajustarNomeTipoArquivo(type: string) {
        return type.replace('image/', '').replace('video/', '');
    }

    salvarMidia(obj_referencia: string) {
        if (this.arquivoBlob || this.linkMidia) {

            const formData = new FormData();
            formData.append('obj_referencia', obj_referencia);
            formData.append('content_type', this.content_type);

            if (this.linkMidia) formData.append('link', this.linkMidia);
            else formData.append('midia', this.arquivoBlob);

            return this.midiaService.create(formData).pipe(
                tap({
                    next: (response) => {
                        this.arquivoBlob = null;
                    }
                })
            );
        } else {
            return new Observable((observer) => {
                observer.next();
                observer.complete();
            });
        }
    }

    excluirMidia = () => {
        this.dialog
            .confirm('Confirmar Exclusão', 'Tem certeza que deseja excluir a mídia atual?')
            .subscribe({
                next: (result: boolean) => {
                    if (result) {
                        if (this.midia.uuid) {
                            this.midiaService
                                .delete(this.midia.uuid!, {
                                    obj_referencia: this.obj_referencia,
                                    content_type: this.content_type
                                })
                                .subscribe((response) => {
                                    this.limparFile();
                                    this.midiaChange.emit(null);
                                });
                        } else {
                            this.limparFile();
                        }
                    }
                }
            });
    };

    public limparFile() {
        this.midia.descricao = '';
        this.midia.formato = '';
        this.midia.url = '';
        this.midia.uuid = undefined;
        this.arquivoBlob = null;
        this.linkMidia = '';
    }

    carregarMaterial = (obj_referencia: string) => {
        this.midiaService
            .get({ obj_referencia: obj_referencia, content_type: this.content_type })
            .subscribe({
                next: (response) => {
                    const midia = response.resultados[0];
                    if (midia) {
                        this.midia.uuid = midia.uuid;
                        this.midia.descricao = 'Arquivo ' + midia.formato;
                        this.midia.formato = midia.formato;
                        this.midia.url = midia.url;
                        this.midiaChange.emit(this.midia);

                        if (midia.formato == 'link') {
                            this.linkMidia = midia.url!;
                        }
                    }
                }
            });
    };

    baixarArquivo = () => {
        if (this.midia.url) {
            const link = document.createElement('a');
            link.href = this.midia.url;
            link.download = 'download';
            link.click();
        }
    };

    changeInputLink($event: any) {
        this.linkMidia = $event.target.value;
    }
}
