import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonModule } from '../button/button.module';



@NgModule({
    declarations: [
        ModalComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        ButtonModule
    ],
    exports: [
        ModalComponent
    ],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
    ]
})
export class ModalModule { }
