import { unformatedStringDateToPortugueseDateString } from "src/app/shared/functions/data-transform";
import {ColumnDataType, Resource} from "../../shared/model/resource";

export const licencasResource: Resource = {
    disableDelete: true,
    disableEdit: true,
    nomeEntidade: {
        singular: 'Licença',
        plural: 'Licenças'
    },
    fieldPk: 'uuid',
    route: {
        url: 'licencas',
    },
    colunas: [
        {
            field: 'disponivel_de',
            label: 'Disponivel de',
            type: ColumnDataType.Date,
            calculateDisplayValue: unformatedStringDateToPortugueseDateString
        },
        {
            field: 'disponivel_ate',
            label: 'Até',
            type: ColumnDataType.Date,
            calculateDisplayValue: unformatedStringDateToPortugueseDateString
        },
        {
            field: 'teste.nome',
            label: 'Teste',
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'equipe.nome',
            label: 'Equipe',
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
        }
    ],
    actions: [],
    availableReferences: []
}
