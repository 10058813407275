import { Component, Input, OnInit } from '@angular/core';
import { ILicenca } from 'src/app/shared/api/licencas/licencas.service';
import { IMaterial } from 'src/app/shared/api/testes/adm-materiais.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { ConstantsWidthHeightMidia } from 'src/app/shared/components/card-midia/card-midia-constants';

@Component({
    selector: 'card-material',
    templateUrl: './card-material.component.html',
    styleUrls: ['./card-material.component.scss']
})
export class CardMaterialComponent implements OnInit {
    @Input() material!: IMaterial;
    @Input() licenca!: ILicenca;
    @Input() urlCapa!: string;

    disponivel = false;
    imageLoaded = false;
    constantsWidthHeightMidia = ConstantsWidthHeightMidia;

    constructor(private midiaService: MidiasService) {}

    ngOnInit(): void {
        this.setarDisponibilidade();
    }

    private setarDisponibilidade(){
        this.disponivel = this.licenca.data_fim != null;
    }

    iniciar() {
        const sub = this.midiaService.getMidiasMateriais(this.material.uuid).subscribe({
            next: (response) => {
                sub.unsubscribe();
                const midia = response.resultados[0];

                if (midia.formato == 'link') {
                    const fullUrl = midia.url!.startsWith('http')
                        ? midia.url
                        : `https://${midia.url}`;
                    window.open(fullUrl, '_blank');
                } else {
                    const link = document.createElement('a');
                    link.href = midia.url!;
                    link.download = 'download';
                    link.click();
                }
            }
        });
    }

    loadImage() {
        this.imageLoaded = true;
    }
}
