import { unformatedStringDateHourToPortugueseDateHourString } from "src/app/shared/functions/data-transform";
import {ColumnDataType, Resource} from "../../shared/model/resource";

export const versoesTesteResource: Resource = {
    disableDelete: true,
    nomeEntidade: {
        singular: 'Teste',
        plural: 'Testes'
    },
    fieldPk: 'uuid',
    route: {
        url: 'testes/versoes',
    },
    colunas: [
        {
            field: 'data_criacao',
            label: 'Data de criação',
            type: ColumnDataType.Date,
            calculateDisplayValue: unformatedStringDateHourToPortugueseDateHourString
        },
        {
            field: 'teste.nome',
            label: 'Teste',
            type: ColumnDataType.Text,
        },
        {
            field: 'ativo',
            label: 'Ativo',
            type: ColumnDataType.Checkbox,
            calculateDisplayValue: (ativo: boolean) => ativo ? 'Sim' : 'Não'
        },
    ],
    actions: [],
    availableReferences: []
}
