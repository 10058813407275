import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabelaEstaticaComponent } from './tabela-estatica.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [TabelaEstaticaComponent],
    imports: [CommonModule, MatTableModule, MatIconModule, MatButtonModule, MatTooltipModule],
    exports: [TabelaEstaticaComponent]
})
export class TabelaEstaticaModule {}
