import { Injectable } from "@angular/core";
import { ApiService } from "../services/api.service";
import { PaginatedResponse } from "./pagination.model";
import { IResponse } from "./response.model";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable()
export abstract class TemplateApiService<T extends IResponse>{
    protected baseUrl: string = '';

    constructor(protected apiService: ApiService, protected authService: AuthService) {
        this.setBaseUrl();
    }

    public abstract setBaseUrl(...props:any): void;

    protected getBaseUrl(){
        return this.baseUrl;
    }

    get(params: any = {}): Observable<PaginatedResponse<T>> {
        return this.apiService.get(this.getBaseUrl(), params) as Observable<PaginatedResponse<T>>;
    }

    create(body: any): Observable<T> {
        return this.apiService.post(this.getBaseUrl(), body);
    }

    update(id: string, body: any): Observable<T> {
        const route = this.getBaseUrl() + '/' + id;
        return this.apiService.patch(route, body);
    }

    detail(id: string): Observable<T> {
        return this.apiService.detail(this.getBaseUrl(), id) as Observable<T>;
    }

    delete(id: string, params = {}): Observable<void> {
        return this.apiService.delete(this.getBaseUrl(), id, params);
    }
}
