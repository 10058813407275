import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponentComponent } from './filter-component.component';
import { FormularioDinamicoModule } from "../formulario-dinamico/formulario-dinamico.module";

@NgModule({
    declarations: [FilterComponentComponent],
    imports: [CommonModule, FormularioDinamicoModule],
    exports: [FilterComponentComponent]
})
export class FilterComponentModule {}
