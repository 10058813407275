<div class="container-form">
    <form class="login-form">


        <mat-form-field>
            <mat-label>Login</mat-label>
            <input [(ngModel)]="formData.username"
                [placeholder]="'Login'"
                name="username"
                matInput>
        </mat-form-field>


        <mat-form-field>
            <mat-label>Senha</mat-label>


            <input [(ngModel)]="formData.senha"
                [type]="hide ? 'password' : 'text'"
                name="senha"
                matInput
            >
            <button 
                type="button"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
                tabindex="-1"
                (click)="hide = !hide"
                mat-icon-button
                matSuffix>
                <mat-icon>
                    {{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </button>
        </mat-form-field>


        <!-- BOTAO LOGIN -->
        <button [disabled]="loading"
            (click)="onSubmit($event)"
            color="primary"
            mat-raised-button
            type="submit"
            style="width: 100%;">
            <ng-container *ngIf="!loading">
                <mat-icon>login</mat-icon>
                Login
            </ng-container>


            <div *ngIf="loading"
                style="width: 100%; display: flex; justify-content: center; padding: 0.3em">
                <mat-icon>login</mat-icon>
                <mat-spinner [diameter]="30"
                    color="accent"></mat-spinner>
            </div>
        </button>



        <div class="mt-5 botoes-lado-lado">
            <!-- ESQUECEU A SENHA -->
            <button mat-stroked-button
                color="warn"
                routerLink="/reset-password">
                Esqueceu a senha?
            </button>


            <!-- CRIAR CONTA -->
            <!-- <button color="accent" routerLink="/create-account" mat-raised-button>Criar conta</button> -->
        </div>


    </form>
</div>
