import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdmMidiasService, IMidia } from 'src/app/shared/api/testes/adm-midias.service';

@Component({
    selector: 'midias',
    templateUrl: './midias.component.html',
    styleUrls: ['./midias.component.css']
})
export class MidiasComponent implements OnInit, OnDestroy {

    private _subscribeMidiasService: Subscription | null = null;

    @Input() content_type!: string;
    @Input() obj_referencia!: string;

    midias: IMidia[] = [];

    constructor(private midiaService: AdmMidiasService) { }

    ngOnInit(): void {
        this.listarMidias();
    }

    ngOnDestroy(): void {
        if (this._subscribeMidiasService) this._subscribeMidiasService.unsubscribe();
    }

    listarMidias = () => {
        this._subscribeMidiasService = this.midiaService
            .get({ content_type: this.content_type, obj_referencia: this.obj_referencia })
            .subscribe(response => {
                this.midias = response.resultados;
            });
    }

    onChangeMidia = () => {
        this.listarMidias();
    }
}
