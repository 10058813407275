import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardServiceAceiteTermos } from 'src/app/shared/services/auth.service';
import { AceiteDeTermosComponent } from './aceite-de-termos.component';

const routes: Routes = [
    {
        path: 'aceite-de-termos',
        canActivate: [AuthGuardServiceAceiteTermos],
        component: AceiteDeTermosComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AceiteDeTermosRoutingModule { }
