import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import {
    ColumnDataType,
    ConfigurationColumn,
    ConfigurationRows,
    NumberConfiguration
} from '../../model/resource';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notificationService';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'formulario-dinamico',
    templateUrl: './formulario-dinamico.component.html',
    styleUrls: ['./formulario-dinamico.component.css']
})
export class FormularioDinamicoComponent implements OnInit, OnDestroy {
    formulario!: FormGroup;

    @Input() entidade: any = {};
    @Input() linhas!: ConfigurationRows[];
    @Input() colunas!: ConfigurationColumn[];

    columnEnum = ColumnDataType;

    @Output() entidadeChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private notify: NotificationService) {}

    ngOnInit(): void {
        this.configureLinhas();
        this.setup();
    }

    ngOnDestroy(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['resource']) this.setup();
    }

    configureLinhas(){
        if(!this.linhas || this.linhas.length == 0){
            this.linhas = [];
            this.linhas[0] = {colunas: this.colunas};
        }
    }

    setup = () => {
        this.configureFormGroup();
    };

    private configureFormGroup() {
        const grupo: any = {};

        this.colunas
            .filter((coluna) => coluna.type != ColumnDataType.Midia)
            .forEach((coluna) => {
                const validators: any = this.createValidators(coluna);
                const control = new FormControl('', validators);
                this.createValueChangeEmmiter(control, coluna);
                grupo[coluna.field] = control;

            });

        this.formulario = new FormGroup(grupo);
    }

    private createValueChangeEmmiter(control: FormControl, coluna: ConfigurationColumn){
        if(coluna.type == ColumnDataType.Text || !coluna.type){
            control.valueChanges.pipe(debounceTime(2000)).subscribe(value => {
                this.entidadeChange.emit(this.entidade);
            })
        } else {
            control.valueChanges.subscribe(value => {
                this.entidadeChange.emit(this.entidade);
            })
        }
    }

    private createValidators(coluna: ConfigurationColumn) {
        const validators: any = [];

        if (coluna.required) validators.push(Validators.required);

        coluna.validation?.forEach((validation) => {
            if (validation.type == 'email') {
                validators.push(Validators.email);
            }
        });

        if (coluna.type == ColumnDataType.Number && coluna.numberConfiguration)
            this.inserirValidatorsNumber(
                coluna.numberConfiguration,
                validators
            );

        return validators;
    }

    isValid() {
        this.formulario.markAllAsTouched();
        return this.formulario.valid;
    }

    getDadosFormularioSalvar() {
        const formData: any = {};

        Object.entries(this.entidade).forEach(([key, value]) => {
            let valueSave = value;

            const columnResource: ConfigurationColumn | undefined =
                this.colunas.find((coluna) => coluna.field == key);
            if (columnResource && columnResource.getValueBeforSave) {
                valueSave = columnResource.getValueBeforSave(value);
            }

            formData[key] = valueSave;
        });

        return formData;
    }

    tratarErroRequisicao(erro: any){
        if(erro.error.erro){
            this.notify.error(erro.error.erro);
        }
        else if(erro.error){
            const erroApi = erro.error;

            Object.keys(erroApi).forEach(key => {
                erroApi[key].forEach((mensagemErro: string) => {
                    this.setErroFormControl(key, mensagemErro);
                })
            })
        }
    }

    private setErroFormControl(field: string, mensagemErro: string){
        const formControl = this.formulario.get(field);
        formControl?.setErrors({apiError: mensagemErro});
    }

    toggleVisibilityPass(inputPassword: HTMLInputElement) {
        if (inputPassword.type == 'text') inputPassword.type = 'password';
        else inputPassword.type = 'text';
    }

    private inserirValidatorsNumber(
        config: NumberConfiguration,
        validators: Validators[]
    ) {
        if (this.isNotNullOrUndefined(config.maxValue)) {
            validators.push(Validators.max(config.maxValue!));
        }
        if (this.isNotNullOrUndefined(config.minValue)) {
            validators.push(Validators.min(config.minValue!));
        }
    }

    private isNotNullOrUndefined(value: any) {
        return !(value == null || value == undefined);
    }
}
