<div class="content-pagina">
    <div class="card-jogo">
        <div class="row w-100 h-100 m-0">
            <capa
                *ngIf="capaVisible && idTeste"
                [idTeste]="idTeste"
                class="h-100 modulo-rodada-perguntas p-0"
                (onContinuarClick)="irParaModulo()"
            ></capa>

            <modulo-rodada
                (onClick)="irParaRodada()"
                [disableBefore]="true"
                [showLoader]="showLoaderModuloRodada"
                [entity]="modulo"
                [midias]="midias_modulo"
                [progresso]="progresso"
                *ngIf="moduloVisible"
                class="h-100 modulo-rodada-perguntas p-0"
            ></modulo-rodada>
            
            <modulo-rodada
                (onClick)="irParaPerguntas()"
                (onBtnBack)="irParaModulo()"
                [showLoader]="showLoaderModuloRodada"
                [entity]="rodada"
                [midias]="midias_rodada"
                [progresso]="progresso"
                *ngIf="rodadaVisible"
                class="h-100 modulo-rodada-perguntas p-0"
            ></modulo-rodada>

            <perguntas-alternativas
                [showLoaderPerguntas]="showLoaderPerguntas"
                (onConfirmarAlternativa)="responderPergunta($event)"
                (onBtnBack)="voltarParaRodada()"
                [pergunta]="pergunta"
                [alternativas]="alternativas"
                [progresso]="progresso"
                [midias]="midias_alternativas"
                *ngIf="perguntasVisible"
                class="h-100 modulo-rodada-perguntas p-0"
            ></perguntas-alternativas>

            <feedback
                [texto]="feedbackAtual || ''"
                *ngIf="feedbackVisible"
                class="h-100 modulo-rodada-perguntas p-0"
                (onContinuarProximaPergunta)="continuarProximaPergunta()"
            ></feedback>

            <finalizado
                *ngIf="finalizadoVisible"
                [idTeste]="idTeste"
                [idLicenca]="idLicenca"
                class="h-100 modulo-rodada-perguntas p-0"
            ></finalizado>
        </div>
    </div>
</div>
