import { IResponse } from './response.model';
import { TemplateApiService } from './template-api.service';
import { Injectable } from '@angular/core';

export interface ITermosUso extends IResponse {
    texto: string;
    dono: string;
    ativo?: boolean;
}

@Injectable()
export class AdmTermosDeUsoService extends TemplateApiService<ITermosUso> {
    public override setBaseUrl(...props: any) {
        this.baseUrl = 'admin/termos';
    }

    public ativarTermo(idTermo: string) {
        return this.apiService.patch(this.getBaseUrl() + `/${idTermo}`, { ativo: true });
    }

    public desativarTermo(idTermo: string) {
        return this.apiService.patch(this.getBaseUrl() + `/${idTermo}`, { ativo: false });
    }
}
