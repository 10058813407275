<div class="d-flex mt-2 justify-content-end">
	<primary-button (click)="abrirModalCriarPergunta()"
		icon="add"
		text="Adicionar Pergunta"></primary-button>
</div>

<div class="mt-2">
	<card-botoes #card
		*ngFor="let pergunta of listaPerguntas"
		[title]="pergunta.descricao"
		[cardOpened]="false"
		[entity]="pergunta"
		[actions]="actions">

		<mat-tab-group #grupoTab (selectedTabChange)="grupoTab.selectedIndex = $event.index">
			<mat-tab label="Mídias">
				<midias *ngIf="card.cardOpened"
					[obj_referencia]="pergunta.uuid"
					content_type="pergunta"></midias>
			</mat-tab>
			<mat-tab label="Alternativas">
				<alternativas 
					*ngIf="grupoTab.selectedIndex == 1"
					[idModulo]="idModulo"
					[idTeste]="idTeste"
					[idRodada]="idRodada"
					[idPergunta]="pergunta.uuid"></alternativas>
			</mat-tab>
		</mat-tab-group>
	</card-botoes>
</div>

<modal #modal
	[data]="dataModal"
	[onSave]="onSavePergunta()">
	<formulario-dinamico #formularioPergunta
		*ngIf="showFormModal"
		[colunas]="colunasFormulario"
		[(entidade)]="entidadePergunta"></formulario-dinamico>
</modal>
