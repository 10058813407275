import { Component, Input } from '@angular/core';
import { TemplateButtonComponent } from '../template-button/template-button.component';

@Component({
    selector: 'secondary-button',
    templateUrl: './../template-button/template-button.component.html',
    styleUrls: ['./../template-button/template-button.component.css']
})
export class SecondaryButtonComponent extends TemplateButtonComponent {

    @Input() tooltip: string = '';

    override ngOnInit(): void {
        this.color = 'accent';
    }
    
}
