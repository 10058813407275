import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdmMidiasService, IMidia } from '../../api/testes/adm-midias.service';

@Component({
    selector: 'card-midia-formulario',
    templateUrl: './card-midia-formulario.component.html',
    styleUrls: ['./card-midia-formulario.component.css']
})
export class CardMidiaFormularioComponent implements OnInit, OnDestroy {
    @Input() content_type!: string;
    @Input() obj_referencia!: string;

    midia!: IMidia;
    private _subscribeMidiasService: Subscription | null = null;

    constructor(private midiaService: AdmMidiasService) { }

    ngOnInit(): void {
        this.listarMidias();
    }

    ngOnDestroy(): void {
        this._subscribeMidiasService?.unsubscribe();
    }

    listarMidias = () => {
        this._subscribeMidiasService = this.midiaService
            .get({ content_type: this.content_type, obj_referencia: this.obj_referencia })
            .subscribe(response => {
                this.midia = response.resultados[0];
            });
    }

    onChangeMidia = () => {
        this.listarMidias();
    }
}
