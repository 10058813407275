import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SkinsRoutingModule } from './skins-routing.module';
import { SkinsComponent } from './skins.component';
import { TableListModule } from "../../shared/components/table-list/table-list.module";
import { FormSkinComponent } from './form-skin/form-skin.component';
import { EditComponentModule } from "../../shared/components/edit-component/edit-component.module";
import { SkinService } from 'src/app/shared/api/skins.service';


@NgModule({
    declarations: [
        SkinsComponent,
        FormSkinComponent
    ],
    imports: [
        CommonModule,
        SkinsRoutingModule,
        TableListModule,
        EditComponentModule
    ],
    providers: [
        SkinService
    ]
})
export class SkinsModule { }
