import { Observable } from 'rxjs';
import { IResponse } from '../response.model';
import { TemplateApiService } from '../template-api.service';
import { Injectable } from '@angular/core';
import { PaginatedResponse } from '../pagination.model';

export interface ITeste extends IResponse {
    nome: string;
    descricao: string;
    ativo: boolean;
    trilha?: string;
    antecessor?: ITeste
}


@Injectable()
export class TestesAdmService extends TemplateApiService<ITeste> {

    public override setBaseUrl() {
        this.baseUrl = 'admin/testes';
    }

    public ativarTeste(idTeste: string) {
        return this.update(idTeste, { ativo: true });
    }

    public inativarTeste(idTeste: string) {
        return this.update(idTeste, { ativo: false });
    }

    public detalharTestePerfilPessoal(idTeste: string) {
        return this.apiService.detail('testes', idTeste) as Observable<ITeste>;
    }

    public importarTestesComIa(file: any){
        const rota = 'admin/importar-teste-com-ia';
        const formData = new FormData();
        formData.append('arquivo', file);

        return this.apiService.postFile(rota, formData);
    }

    override get(params?: any): Observable<PaginatedResponse<ITeste>> {
        return this.apiService.get(this.getBaseUrl(), params) as Observable<PaginatedResponse<ITeste>>;
    }
}


@Injectable()
export class TestesAtivosService extends TemplateApiService<ITeste> {
    private params:any = {};

    public override setBaseUrl(params = {}) {
        this.params = params;
        this.baseUrl = 'admin/testes';
    }

    public ativarTeste(idTeste: string) {
        return this.update(idTeste, { ativo: true });
    }

    public detalharTestePerfilPessoal(idTeste: string) {
        return this.apiService.detail('testes', idTeste) as Observable<ITeste>;
    }

    override get(params?: any): Observable<PaginatedResponse<ITeste>> {
        this.params.pagina = params?.pagina;
        this.params.tamanho = params?.tamanho;
        return this.apiService.get(this.getBaseUrl(), this.params) as Observable<PaginatedResponse<ITeste>>;
    }
}
