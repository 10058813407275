import { truncateText } from "src/app/shared/functions/format-functions";
import {ColumnDataType, Resource} from "../../shared/model/resource";

export const termoDeUsoResource: Resource = {
    nomeEntidade: {
        singular: 'Termo de Uso',
        plural: 'Termos de Uso'
    },
    fieldPk: 'uuid',
    route: {
        url: 'adm/termos',
    },
    colunas: [
        {
            field: 'texto',
            type: ColumnDataType.TextArea,
            sizeColumn: 'col-12',
            sizeCellTable: 30,
            textareaConfiguration: {
                rows: 20
            },
            calculateDisplayValue: (texto: string) => truncateText(texto, 200)
        },
        {
            field: 'dono.razao_social',
            label: 'Razão social',
            type: ColumnDataType.Text,
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'dono.nome_fantasia',
            label: 'Nome Fantasia',
            type: ColumnDataType.Text,
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'ativo',
            type: ColumnDataType.Checkbox,
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
            calculateDisplayValue: (ativo: boolean) => ativo ? 'Sim' : 'Não'
        },
    ],
    actions: [],
    availableReferences: []
}
