<div class="card-comparativo">

    <img class="background-relatorio" src="assets/img/capa-relatorio-comparativo.jpeg" [ngStyle]="{
        'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
    }">

    <div class="card-title" [ngClass]="{'card-content-bloqueado': !disponivel}">
        Relatório de Comparação
    </div>

    <img *ngIf="!disponivel" class="cadeado" src="assets/img/cadeado.svg">


    <div class="card-actions">
        <div class="row">
            <div>
                <button [disabled]="!disponivel" mat-mini-fab color="primary" (click)="iniciar()">
                    <mat-icon>assignment</mat-icon>
                </button>
                <span class="ms-3">Ver relatório</span>
            </div>
        </div>
    </div>
</div>