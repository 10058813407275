import { Component, OnInit } from '@angular/core';
import { equipeResource } from './equipe.resource';
import { EquipeService } from 'src/app/shared/api/equipe.service';

@Component({
    selector: 'app-equipes',
    templateUrl: './equipes.component.html',
    styleUrls: ['./equipes.component.css']
})
export class EquipesComponent implements OnInit {

    equipeResource = equipeResource;

    constructor(public equipeService: EquipeService) { }

    ngOnInit(): void {
    }

}
