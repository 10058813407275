import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";


export interface IRodada extends IResponse {
    modulo: string,
    nome: string,
    descricao: string,
    posicao: number,
}

@Injectable()
export class RodadaService extends TemplateApiService<IRodada> {

    public override setBaseUrl(idTeste: any, idModulo: any){
        this.baseUrl = `admin/testes/${idTeste}/modulos/${idModulo}/rodadas`;
    }
}
