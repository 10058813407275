import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { autoCadastroResource } from './autocadastro.resource';
import { AutocadastroService } from 'src/app/shared/api/autocadastro.service';
import { ColumnDataType, ConfigurationColumn, LookupConfiguration } from 'src/app/shared/model/resource';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { Observable } from 'rxjs';
import { LicencasDisponiveisService } from 'src/app/shared/api/licencas/licencas-disponiveis.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormularioDinamicoComponent } from 'src/app/shared/components/formulario-dinamico/formulario-dinamico.component';
import {
    ActionsTabela,
    TabelaListagemComponent
} from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { NotificationService } from 'src/app/shared/services/notificationService';
import { environment } from 'src/environments/environment';
import { clonarObjeto } from 'src/app/shared/functions/objects-functions';
import { AdmAutocadastroService } from 'src/app/shared/api/adm-autocadastro.service';
import { TestesAtivosService } from 'src/app/shared/api/testes/testes.service';

@Component({
    selector: 'autocadastro',
    templateUrl: './autocadastro.component.html',
    styleUrls: ['./autocadastro.component.css']
})
export class AutocadastroComponent implements OnInit {
    @ViewChild('listaAutocadastro') listaAutocadastro!: TabelaListagemComponent;
    @ViewChild('modalAutocadastro') modalAutocadastro!: ModalComponent;
    @ViewChild('formularioAutocadastro') formularioAutocadastro!: FormularioDinamicoComponent;

    // recebendo services pelo input pois eles são usados pelo admin e pelo cliente
    @Input() autocadastroService!: AutocadastroService | AdmAutocadastroService;
    @Input() licencasService!: LicencasDisponiveisService | TestesAtivosService;
    @Input() modo!: 'adm' | 'cliente';

    private readonly URL_SISTEMA = environment.URL_SISTEMA;

    modalRef!: MatDialogRef<ModalComponent>;
    colunas:ConfigurationColumn[] = clonarObjeto(autoCadastroResource.colunas);
    colunasListar: ConfigurationColumn[] = [];
    colunasModal: ConfigurationColumn[] = [];
    actionsTabelaAutocadastro: ActionsTabela[] = [];
    modalAutocadastroData: IDialogData = {
        titulo: 'Autocadastro'
    };
    autocadastro: any = {};
    exibirModal = false;
    idAutocadastroSelecionado: string | null = null;
    urlautocadastroClipboard: string | null = null;

    constructor(
        private notify: NotificationService
    ) {}

    ngOnInit(): void {
        this.listarColunasAutocadastro();
        this.configurarActionsTabela();
    }

    onClickCriarAutocadastro = () => {
        this.abrirModalCadastrar();
    };

    onClickEditarAutocadastro = (autocadastro: any) => {
        this.urlautocadastroClipboard = this.criarUrlAutocadastro(autocadastro.uuid);
        this.abrirModalEditar({ ...autocadastro });
    };

    onClickAtivarDesativarAutocadastro = (autocadastro: any) => {
        this.ativarAutocadastro(autocadastro.uuid, !autocadastro.ativo);
    };

    onClickCopiarUrlAutocadastro = (autocadastro: any) => {
        const texto = this.criarUrlAutocadastro(autocadastro.uuid);
        this.copiarParaClipboard(texto);
    };

    private configurarActionsTabela() {
        this.actionsTabelaAutocadastro = [
            {
                hint: 'Editar',
                icon: 'edit',
                text: 'Editar',
                type: 'primary',
                onClick: this.onClickEditarAutocadastro
            },
            {
                hint: 'Ativar',
                icon: 'flash_on',
                text: 'Ativar',
                type: 'accent',
                isVisible(entity) {
                    return !entity.ativo;
                },
                onClick: this.onClickAtivarDesativarAutocadastro
            },
            {
                hint: 'Desativar',
                icon: 'flash_off',
                text: 'Desativar',
                type: 'basic',
                isVisible(entity) {
                    return entity.ativo;
                },
                onClick: this.onClickAtivarDesativarAutocadastro
            },
            {
                hint: 'Copiar url',
                icon: 'content_copy',
                text: 'Copiar url',
                type: 'basic',
                isVisible(entity) {
                    return entity.ativo;
                },
                onClick: this.onClickCopiarUrlAutocadastro
            }
        ];
    }

    private listarColunasAutocadastro() {
        this.colunas.push(this.createColunaTeste());
        this.colunasListar = this.colunas.filter((coluna) => !coluna.unAvailableOnList);
    }

    private abrirModalCadastrar() {
        this.colunasModal = [];
        this.colunasModal = this.colunas
            .filter((coluna) => !coluna.unAvailableOnCreate)
            .map((coluna) => this.editarSizeColunasFormulario(coluna));
        this.showModal();
    }

    private abrirModalEditar(autocadastro: any) {
        this.autocadastro = autocadastro;
        if(!autocadastro.ativo) this.urlautocadastroClipboard = null;
        // Precisa dessa atribuição porque no modal faz uma listagem de licenças (teste, quantidade, uuid) e na tabela de autocadastro traz o objeto de teste (nome, uuid)
        this.autocadastro.teste.teste = this.autocadastro.teste.nome;
        this.idAutocadastroSelecionado = autocadastro.uuid;
        this.colunasModal = [];
        this.colunasModal = this.colunas
            .filter((coluna) => !coluna.unAvailableOnEdit)
            .map((coluna) => this.editarSizeColunasFormulario(coluna));
        this.showModal();
    }

    private ativarAutocadastro(idAutocadastro: string, ativo: boolean) {
        this.autocadastroService.ativar(idAutocadastro, ativo).subscribe({
            next: (response) => {
                this.listarAutocadastros();
            }
        });
    }

    private listarAutocadastros() {
        this.listaAutocadastro.listarEntidades();
    }

    private createColunaTeste(): ConfigurationColumn {
        return {
            field: 'teste',
            label: 'Curso',
            type: ColumnDataType.Lookup,
            sizeColumn: 'col-12',
            getValueBeforSave: (teste) => teste.uuid,
            lookupConfiguration:  this.getLookupColunaTestesOuLicencasDisponiveis(),
            required: true,
            unAvailableOnList: true
        };
    }

    private getLookupColunaTestesOuLicencasDisponiveis(): LookupConfiguration{
        if(this.modo == 'adm'){
            return {
                colunas: [
                    {
                        field: 'nome',
                        label: 'Nome',
                        required: true
                    },
                    {
                        field: 'descricao',
                        label: 'Descrição',
                        required: true
                    }
                ],
                fieldName: 'nome',
                fieldPk: 'uuid',
                instanciaService: this.licencasService
            }
            
        } else if(this.modo == 'cliente'){
            return {
                colunas: [
                    {
                        field: 'teste',
                        label: 'Curso'
                    },
                    {
                        field: 'disponiveis',
                        label: 'Qtd Disponíveis'
                    }
                ],
                fieldName: 'teste',
                fieldPk: 'uuid',
                instanciaService: this.licencasService
            }
        }

        throw new Error('Defina a variável modo');
    }

    private editarSizeColunasFormulario(coluna: ConfigurationColumn): ConfigurationColumn {
        return {
            ...coluna,
            sizeColumn: 'col-12'
        };
    }

    private showModal() {
        this.exibirModal = true;
        this.modalRef = this.modalAutocadastro.open({ maxWidth: '40em', width: '100%' });
        this.modalRef.beforeClosed().subscribe({
            next: (response: any) => {
                this.exibirModal = false;
                this.autocadastro = {};
                this.urlautocadastroClipboard = null;
            }
        });
    }

    private closeModal() {
        this.modalAutocadastro.close(true);
    }

    onSalvarAutocadastro = new Observable((observer) => {
        if (!this.formularioAutocadastro.isValid()) {
            observer.error();
            return;
        }

        let request = this.autocadastroService.create(this.getAutocadastroSalvar());
        if (this.idAutocadastroSelecionado)
            request = this.autocadastroService.update(
                this.idAutocadastroSelecionado,
                this.getAutocadastroSalvar()
            );

        request.subscribe({
            next: (response) => {
                this.closeModal();
                this.listarAutocadastros();
            },
            error: (erro) => {
                this.formularioAutocadastro.tratarErroRequisicao(erro);
                this.notify.error(erro);
            }
        });
    });

    private getAutocadastroSalvar() {
        return this.formularioAutocadastro.getDadosFormularioSalvar();
    }

    private criarUrlAutocadastro(idAutocadastro: any) {
        return `${this.URL_SISTEMA}/autocadastro/${idAutocadastro}`;
    }

    public copiarParaClipboard(texto: string) {
        navigator.clipboard
            .writeText(texto)
            .then(() => {
                this.notify.success('Url copiada para área de transferência');
            })
            .catch((err) => {
                this.notify.error('Erro ao copiar texto: ' + texto);
            });
    }
}
