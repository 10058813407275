import { Component, OnInit, ViewChild } from '@angular/core';
import { TestesAdmService } from 'src/app/shared/api/testes/testes.service';
import { testeResource } from './teste.resource';
import { ActionsTabela } from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { ActivatedRoute, Router } from '@angular/router';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/shared/services/notificationService';

@Component({
    selector: 'app-testes',
    templateUrl: './testes.component.html',
    styleUrls: ['./testes.component.css']
})
export class TestesComponent implements OnInit {
    @ViewChild('modal') modalImportarCursoComIa!: ModalComponent;

    testesResource = testeResource;
    actionsTabela: ActionsTabela[] = [];
    arquivoImportar!: File;

    importarCursoComIaModalData: IDialogData = {
        titulo: 'Importar curso com IA',
    }

    constructor(
        public testesAdmService: TestesAdmService,
        public router: Router,
        public route: ActivatedRoute,
        private notify: NotificationService
    ) { }

    ngOnInit(): void {
        this.actionsTabela.push({
            hint: 'Importar Curso com IA',
            icon: 'upload',
            text: 'Importar Curso com IA',
            type: 'accent',
            mode: 'batch',
            onClick: this.importarCursoComIa
        })
    }

    private importarCursoComIa = () => {
        this.modalImportarCursoComIa.open({width: '60em'});
    }

    onSaveModalImportarCurso = new Observable(observer => {
        if(!this.arquivoImportar){
            this.notify.error('Selecione um arquivo para importar');
            observer.error();
            observer.complete();
            return;
        }

        this.testesAdmService.importarTestesComIa(this.arquivoImportar).subscribe({
            next: () => {
                observer.next();
                observer.complete();
            },
            error: (err) => {
                this.notify.error(err.error.erro);
                observer.error();
                observer.complete();
            }
        })
    })
}
