import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoaderPageModule } from '../../shared/components/loader-page/loader-page.module';
import { MatIconModule } from '@angular/material/icon';
import { ContainerPadraoModule } from "../../shared/components/container-padrao/container-padrao.module";

@NgModule({
    declarations: [DashboardComponent],
    providers: [AuthService],
    imports: [CommonModule, DashboardRoutingModule, MatIconModule, ContainerPadraoModule]
})
export class DashboardModule {}
