import { Component, OnInit } from '@angular/core';
import { EquipeService } from 'src/app/shared/api/equipe.service';
import { equipeResource } from '../equipe.resource';
import { AutocadastroService } from 'src/app/shared/api/autocadastro.service';
import { LicencasDisponiveisService } from 'src/app/shared/api/licencas/licencas-disponiveis.service';

@Component({
    selector: 'app-form-equipe',
    templateUrl: './form-equipe.component.html',
    styleUrls: ['./form-equipe.component.css']
})
export class FormEquipeComponent implements OnInit {
    equipeResource = equipeResource;
    entidade!: any;
    showAutocadastro = false;
    

    constructor(
        public equipeService: EquipeService,
        public autocadastroService: AutocadastroService,
        public licencasDisponiveisService: LicencasDisponiveisService,
    ) {}

    ngOnInit(): void {}

    changeEntidadeOriginal(equipe: any) {
        this.autocadastroService.setBaseUrl(equipe[equipeResource.fieldPk]);
        this.licencasDisponiveisService.setBaseUrl({ equipe: null });
        this.showAutocadastro = true;
    }
}
