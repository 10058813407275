import { Injectable } from "@angular/core";
import { IResponse } from "./response.model";
import { TemplateApiService } from "./template-api.service";


export interface ISkin extends IResponse {
    nome: string,
    cor_primaria: string,
    cor_secundaria: string
}


@Injectable()
export class SkinService extends TemplateApiService<ISkin> {

    public override setBaseUrl(...props: any) {
        this.baseUrl = 'skins';
    }
}
