import { Component, OnInit } from '@angular/core';
import { clienteResource } from './cliente.resource';
import { ClientesService } from 'src/app/shared/api/clientes.service';
import { ActionsTabela } from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';

@Component({
    selector: 'app-clientes',
    templateUrl: './clientes.component.html',
    styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

    clienteResource = clienteResource;

    actionsCliente: ActionsTabela[] = clienteResource.actions;
    colunasCliente = clienteResource.colunas;



    constructor(
        public clienteService: ClientesService
    ) { }

    ngOnInit(): void {
    }

}
