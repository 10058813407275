<mat-card class="card-licenca" [ngStyle]="{
        display: imageLoaded ? 'block' : 'none'
    }" appearance="outlined">

    <div class="image-content">
        <img (load)="loadImage()" mat-card-image class="card-image"
            *ngIf="imageFormats.includes(getFileFormatFromUrl(licenca.teste.capa)!.toLocaleLowerCase())" [ngStyle]="{
            'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
        }" [src]="licenca.teste.capa" height="100%" />

        <video (canplay)="loadImage()" mat-card-image class="card-image"
            *ngIf="videoFormats.includes(getFileFormatFromUrl(licenca.teste.capa)!.toLocaleLowerCase())" [ngStyle]="{
            'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
        }" controls>
            <source [src]="licenca.teste.capa" />
        </video>

        <img *ngIf="exibirCadeado()" class="cadeado" src="assets/img/cadeado.svg">
    </div>


    <div class="card-content" *ngIf="imageLoaded">
        <mat-card-content class="card-content-text">
            <!-- verificar cardOpeded no titulo para corrigir glitch visual -->
            <div class="card-title">{{ licenca.teste.nome }}</div>

            <div class="row ms-4 informacoes-licenca">
                <!-- quando não iniciado e não possui data de quando ficara disponivel -->
                <div class="col" *ngIf="!licenca.data_inicio && !licenca.disponivel_de">
                    <div class="row">Não iniciado</div>
                </div>

                <!-- quando não iniciado ainda e possui data de disponibilidade  -->
                <div class="col"
                    *ngIf="licenca.disponivel_de && !licenca.data_inicio && !isDataAtualOuAnterior(licenca.disponivel_de)">
                    <div class="row">Ficará disponível em</div>
                    <div class="row">
                        {{ converterData(licenca.disponivel_de) }}
                    </div>
                </div>

                <div class="col" *ngIf="licenca.data_inicio">
                    <div class="row">Início</div>
                    <div class="row">
                        {{ converterData(licenca.data_inicio) }}
                    </div>
                </div>

                <div class="col" *ngIf="licenca.data_fim">
                    <div class="row">Concluído</div>
                    <div class="row">
                        {{ converterData(licenca.data_fim) }}
                    </div>
                </div>
            </div>
        </mat-card-content>

        <mat-card-actions class="card-actions">
            <div class="row">
                <div class="col-6">
                    <div *ngIf="podeIniciarLicenca()">
                        <button mat-mini-fab color="primary" (click)="iniciar()">
                            <mat-icon>play_arrow</mat-icon>
                        </button>
                        <span class="ms-3" *ngIf="!licenca.data_inicio">Iniciar</span>
                        <span class="ms-3" *ngIf="licenca.data_inicio">Continuar</span>
                    </div>

                    <div *ngIf="licenca.data_fim != null">
                        <button mat-mini-fab color="primary" (click)="relatorio()">
                            <mat-icon>assignment</mat-icon>
                        </button>
                        <span class="ms-3">Relatório</span>
                    </div>
                </div>

                <div class="col expiration" *ngIf="licenca.data_fim == null && licenca.disponivel_ate">
                    Expira em
                    {{ converterData(licenca.disponivel_ate) }}
                </div>
            </div>
        </mat-card-actions>
    </div>
</mat-card>
<mat-progress-bar *ngIf="licenca.data_inicio && imageLoaded" class="mt-2" mode="determinate"
    [value]="licenca.progresso"></mat-progress-bar>

<div class="skeleton-card" *ngIf="!imageLoaded">
    <div class="skeleton-image" [ngStyle]="{
            'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
        }"></div>
    <div class="skeleton-text">
        <div class="skeleton-line short"></div>
        <div class="skeleton-line"></div>
        <div class="skeleton-line"></div>
    </div>
</div>