<mat-toolbar class="header">
    <button
        *ngIf="showBtnMenu"
        (click)="toggleMenuLateral()"
        mat-icon-button
        class="example-icon"
        aria-label="Example icon-button with menu icon"
    >
        <mat-icon>menu</mat-icon>
    </button>

    <!-- <span class="ml-2">{{title}}</span> -->

    <span class="example-spacer"></span>

    <select-padrao
        class="select-perfis d-none d-md-block"
        [selectConfiguration]="configurationSelectPerfis"
        [(value)]="acessoSelecionado"
    ></select-padrao>

    <button
        (click)="logout()"
        mat-icon-button
        class="example-icon favorite-icon me-3"
        aria-label=""
    >
        <mat-icon>logout</mat-icon>
        Sair
    </button>
</mat-toolbar>
